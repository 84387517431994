
import datalanguage from './language.json';
const Translate = (type, value) => {
    var data = datalanguage.filter(i => i.key.toLowerCase() === value.toLowerCase());
    var valueType = data[0].value.split(';');
    if (type === '' || type === 0 || type === undefined) {
        value = valueType[0]
    }
    if (type === 'vi') {
        value = valueType[0]
    }
    if (type === 'en') {
        value = valueType[1]
    }
    return value
}

export default Translate;
