import axios from "axios";
import Dropdown from "./Dropdown";
import { useState, useEffect, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Button } from "antd";
import swal from "sweetalert";
import Translate from "../../common/Lang/translate";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../actions/auth";
const MenuItems = ({ items, depthLevel, badge }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };
  const clickLogout = (e) => {
    dispatch(logout());
    swal('Logout success!', {
      icon: 'success',
      timer: 3000
    })
  }
  const clickMenu = (title, id, idMenu, url) => {
    if (title === 'Customized Tours') {

      window.open(`/customtour`, "_blank");
    }
    else {
      if (id === 0 && idMenu === 0) {
        if (url === 'logout') {
          clickLogout();
        }
        else {
          history.push(`/${url}`)
        }
      }
      else {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
          if (idMenu === 0) {
            history.push(`/tour/${title}`, id);
          }
          else {
            history.push(`/tour/${title}`, {
              idSub: id,
              idMenu: idMenu
            });
          }
        })
      }
    }
  }
  const clickServiceMenu = (title, idMenu, idSub) => {
    debugger;
    console.log(idSub)

    if (idSub === 1) {
      window.open(`/service/spa`);
    }
    if (idSub === 3) {
      window.open(`/service/vehicle`);
    }
    if (idSub === 4) {
      window.open(`/service/accommodation`);
    }
    if (idSub === 5) {
      window.open(`/service/bus`);
    }
    if (idSub === 6) {
      window.open(`/service/restaurant`);
    }
    // dựa vào IDSUb điều hướng đến component service tương ứng
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (idMenu === 0) {
        history.push(`/service`, idSub);
      }
      else {
        history.push(`/service/${idMenu}`);
        window.location.reload()
      }
    }
  }
  const clickService = (title, idCate, idSub, url) => {
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (url === 'service' && idCate === 0) {
        history.push(`/service`, idCate);
      }
      else {
        history.push(`/service`, idSub);
      }
    }

  }
  const clickSub = (title, idCate, idSub, idMenu, url) => {
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (idSub !== 0) {
        history.push(`/category/${title}`, {
          idSub: idSub,
          idMenu: idMenu
        });
      }
      else {
        history.push('/category', idCate)
      }
    }

  }
  return (
    <>
      <li
        className="menu-items"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={closeDropdown}>
        {items.url && items.submenu ? (
          <>
            {/* click Category */}
            <button
              type="button"
              aria-haspopup="menu"
              aria-expanded={dropdown ? "true" : "false"}
              onClick={() => items.url === 'service' ? clickService(items.title, items.idCate, items.idSub, items.url) : clickSub(items.title, items.idCate, items.idSub, items.idMenu, items.url)}>
              <Link onClick={(e) => items.url === 'service' ? clickService(items.title, items.idCate, items.idSub, items.url) : clickSub(items.title, items.idCate, items.idSub, items.idMenu, items.url)}>{items.title}</Link>
              {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </>
        ) : !items.url && items.submenu ? (
          <>
            <button
              type="button"
              aria-haspopup="menu"
              onClick={(e) => console.log('alo')}
              aria-expanded={dropdown ? "true" : "false"}>
              {items.title}
              {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
            </button>
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.submenu}
              dropdown={dropdown}
            />
          </>
        ) : (
          <Link onClick={(e) => items.url === 'service' ? clickServiceMenu(items.title, items.idMenu, items.idSub) : clickMenu(items.title, items.idSub, items.idMenu, items.url)}>{items.title}</Link>
          // click Menu
        )}
      </li>

    </>
  );
};

export default MenuItems;
