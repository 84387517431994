import { Card, Col, Divider, Row, List, Image, Button, Avatar, Carousel, Form, Input } from "antd";
import Translate from "../../common/Lang/translate";
import {
    TagOutlined, MonitorOutlined, CheckCircleOutlined, MoneyCollectOutlined, PhoneOutlined, DribbbleOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import ReactStars from 'react-stars'
import React, { useRef, Fragment, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";
const { Meta } = Card;
const TheMost = () => {
    const history = useHistory();
    const [dataAttaction, setDataAttaction] = useState([]);
    const LoadDataAttaction = () => {
        axios.post('https://dgss.vn:7001/api/Attactions/GetAttactionsAll', '').then(res => {
            setDataAttaction(res.data)
        })
    }
    useEffect(() =>{
        LoadDataAttaction()
    },[])
    const ClickAttaction = (id) => {
        history.push(`/attaction`, id);
    }
    const dataAt = dataAttaction.map(r => (
        {
            title: r.Title,
            src: r.ImageBanner,
            description: r.Slogan,
            id: r.ID
        }
    ))
    return (
        <Card id="formTopActivities"
            name="formTopActivities" headStyle={{ backgroundColor: '#F0F2F5', textAlign: 'center' }} title={<strong style={{ color: '#367AA7', fontSize: 25, fontWeight: 'bold' }}>THE MOST POPULAR ATTRACTIONS & ACTIVITIES</strong>} style={{ backgroundColor: 'white' }}>
            <List grid={{ gutter: 16, column: 4, xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2 }}
                pagination={{
                    position: "bottom",
                    pageSize: 100
                }}
                dataSource={dataAt}
                renderItem={(item) => (
                    <List.Item onClick={(e) => ClickAttaction(item.id)}>
                        <Card hoverable
                            style={{ minWidth: 300, minHeight: 370 }}
                            cover={
                                <img
                                    alt="example"
                                    src={item.src}
                                />
                            }
                        >
                            <Meta
                                style={{ minHeight: 120 }}
                                title={item.title}
                                description={item.description}
                            />
                        </Card>
                    </List.Item>
                )} />
        </Card>
    )
}
export default TheMost