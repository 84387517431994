import { Card, Col, Divider, Row, List, Image, Button, Checkbox, Carousel, Form, Input } from "antd";
import Translate from "../../common/Lang/translate";
import {
    TagOutlined, ArrowRightOutlined, ArrowLeftOutlined, LeftOutlined, RightOutlined, FontColorsOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import ReactStars from 'react-stars'
import React, { useRef, Fragment, useEffect } from "react";

import { useHistory } from "react-router-dom";
import SlideImage from "../SlideImage/slideimage";
import axios from "axios";
import swal from "sweetalert";
const { Meta } = Card;
const FeedBack = (language) => {
    
    const [form] = Form.useForm();
    const dataImage = [
        {
            slogan: 'FEEDBACK',
            src: 'https://hips.hearstapps.com/hmg-prod/images/question-mark-icon-on-white-puzzle-royalty-free-image-917901148-1558452934.jpg?crop=1xw:0.84415xh;center,top&resize=1200:*',

        }
    ]
    const onFinish = (values) => {
       
        axios.post('https://dgss.vn:7001/api/FeedBack/AddFeedBack', {
            "ID": 1,
            "Email": values.Email,
            "Phone": values.Phone,
            "Name": values.Name,
            "Content": values.Content,
            "Image": "",
            "CoverImage": "",
            "Status": true
        }).then(res => {
            if (res.status === 200) {
                swal('Send feedback success', {
                    icon: 'success',
                    timer: 3000
                })
                form.resetFields();
            }
        })
    }
    return (
        <div>
            {SlideImage(dataImage)}
            <Card headStyle={{ textAlign: 'center' }} title={<strong style={{ textShadow: "0px  1px  1px  gray", fontSize: 16 }}>{Translate(language, 'form_feedback')}</strong>}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label={Translate(language, 'Name')}
                        name="Name"
                        rules={[{ required: true, message: Translate(language, 'message_input_name') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={'Email'}
                        name="Email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "The input is not valid E-mail!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate(language, 'Phone')}
                        name="Phone"
                        rules={[
                            {
                                required: true,
                                message: Translate(language, 'message_input_phone'),
                                pattern: new RegExp(/^[0-9]+$/),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={Translate(language, 'Content')}
                        name="Content"
                        rules={[
                            {
                                required: true,
                                message: Translate(language, 'message_input_content'),
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Row>
                        <Col xs={24} xl={0} span={24}>
                            <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ span: 16 }}>
                                <Button size="large" style={{ borderRadius: 5, backgroundColor: '#F89810' }} type="primary" htmlType="submit">
                                    <strong>{Translate(language, 'submit_send_feedback')}</strong>
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={0} xl={24} span={24}>
                            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                                <Button size="large" style={{ borderRadius: 5, backgroundColor: '#F89810' }} type="primary" htmlType="submit">
                                    <strong>{Translate(language, 'submit_send_feedback')}</strong>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Card>
        </div>
    )
}
export default FeedBack