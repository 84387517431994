import { Row, Col, Image } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';

const Policies = (Language) => {
    
    const history = useHistory();
    const dataState = history.location.state;
    const [dataPolicies, setDataPolicies] = useState([]);
    const LoadDataPolicies = () => {
        axios.post('https://dgss.vn:7001/api/Policies/GetPoliciesAll', '').then(res => {
            if (res.status === 200) {
                setDataPolicies(res.data.filter(i => i.ID === 1))
            }
        })
    }
    useEffect(() => {
        LoadDataPolicies();
    }, [dataState])
    if (dataState === undefined || dataState === '' || dataState === 0) {
        return <Redirect to={'/'} />
    }
    return (
        <div style={{backgroundColor:'white'}}>
            {Number(dataState) === 1 ? <div>
                <Row>
                    <Col span={2}>

                    </Col>
                    <Col span={20}>
                        <div dangerouslySetInnerHTML={{ __html: dataPolicies.map(r => r.CancelationRefund) }}>

                        </div>
                    </Col>
                </Row>
            </div> : null}
            {Number(dataState) === 2 ? <div>
                <Row>
                    <Col span={2}>

                    </Col>
                    <Col span={20}>
                        <div dangerouslySetInnerHTML={{ __html: dataPolicies.map(r => r.Payment) }}>

                        </div>
                    </Col>
                </Row>
            </div> : null}
            {Number(dataState) === 3 ? <div>
                <Row>
                    <Col span={2}>

                    </Col>
                    <Col span={20}>
                        <div dangerouslySetInnerHTML={{ __html: dataPolicies.map(r => r.Children) }}>

                        </div>
                    </Col>
                </Row>
            </div> : null}
        </div>
    )
}
export default Policies;