
import axios from "axios";
import swal from "sweetalert";

const GET_USER = "/user";
const LOGIN = '/Accounts/Login';
const register = (username, email, password) => {
	return axios.post('' + "signup", {
		username,
		email,
		password,
	});
};

const login = (credentials) => {
	try {
		axios.post('https://dgss.vn:7001/api/Accounts/Login', credentials).then(res => {
			if ('token' in res.data && res.status === 200) {
				swal('Login success', {
					buttons: false,
					timer: 2000,
					icon: 'success'
				})
				localStorage.setItem('currentID', res.data['ID']);
				localStorage.setItem('Role', res.data['Role']);
				localStorage.setItem('username', res.data['UserName']);
				localStorage.setItem('user', res.data['FullName'])
				localStorage.setItem('password', res.data['Password']);
				localStorage.setItem('phone', res.data['Phone']);
				localStorage.setItem('email', res.data['Email']);
				localStorage.setItem('image', res.data['Image']);
				localStorage.setItem('active', res.data['Active'])
				localStorage.setItem('database', res.data['DatabaseName']);
				localStorage.setItem('securitycode', res.data['SecurityCode']);
				localStorage.setItem('expirationDate', res.data['ExpirationDate']);
				localStorage.setItem('sensorid', res.data['SensorID']);
				localStorage.setItem('title', res.data['Title']);
				//set token 
				localStorage.setItem('token', res.data['token']);
				// id chat support _id = "d4ce8e84e3104fcdac37d8afb909b11e"

				window.location.reload();
			}
		})
	}
	catch (error) {
		if (!error.response) {
			swal('Không có phản hồi từ server', error);
		}

	}
}
const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('currentID');
	localStorage.removeItem('Role');
	localStorage.removeItem('username');
	localStorage.removeItem('user')
	localStorage.removeItem('password');
	localStorage.removeItem('phone');
	localStorage.removeItem('email');
	localStorage.removeItem('image');
	localStorage.removeItem('active')
	localStorage.removeItem('database');
	localStorage.removeItem('securitycode');
	localStorage.removeItem('expirationDate');
	localStorage.removeItem('sensorid');
	localStorage.removeItem('title');
	localStorage.removeItem('tokenChat');
};

export default {
	register,
	login,
	logout
};
