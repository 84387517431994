import { Card, Col, Divider, Row, List, Image, Button, Avatar, Carousel, Form, Input } from "antd";
import Translate from "../../common/Lang/translate";
import {
    MoneyCollectOutlined, MonitorOutlined, DribbbleOutlined, CheckCircleOutlined, PhoneOutlined, TagOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import ReactStars from 'react-stars'
import React, { useRef, Fragment, useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import SlideImage from "../SlideImage/slideimage";
import axios from "axios";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import SearchBar from "../SearchBar/searchbar";
const CategoryTour = (Language) => {
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    const history = useHistory();
    const dataState = history.location.state;


    const [dataSubMenu, setDataSubMenu] = useState([]);
    const [titleCategory, setTitleCategory] = useState('');
    const [dataCateInit, setDataCateInit] = useState([]);
    let idSubmenu = 0;
    let idMenu = 0;
    if (dataState !== undefined && dataState !== null) {
        idSubmenu = dataState.idSub;
        idMenu = dataState.idMenu;
    }
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '').then(res => {
            setDataCateInit(res.data)
            const Title = res.data.filter(i => i.ID === dataState).map(r => r.Title)[0];
            setTitleCategory(Language === 'vi' ? String(`CHỌN MỘT TRONG ${Language === 'vi' ? String(Title).split(';')[0].toUpperCase() : String(Title).split(';')[1].toUpperCase()}`) : String(`CHOOSE ONE IN ${Language === 'vi' ? String(Title).split(';')[0].toUpperCase() : String(Title).split(';')[1].toUpperCase()}`))
        })
    }
    const [dataSaleBanner, setDataSaleBanner] = useState([]);
    const serviceCategory = [
        {
            title: Language === 'vi' ? 'Dịch vụ khác' : 'Other Services',
            src: 'https://topgotourist.vn/img/service/origin/full_topgotourist_dich-vu-tu-van-va-to-chuc-tour-du-lich-tron-goi-2021830141558.jpeg',
            id: 0
        }
    ]
    const Category = dataCateInit.slice(0, 4).map(r => (
        {
            title: Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID
        }
    ))
    const LoadDataSaleBanner = () => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/GetSaleBannerAll', '').then(res => {
            if (res.status === 200) {
                setDataSaleBanner(res.data);
            }
        })
    }
    const LoadDataSubMenu = () => {
        axios.post('https://dgss.vn:7001/api/SubMenu/GetSubMenuAll', '').then(res => {
            setDataSubMenu(res.data.filter(i => i.IDCategory === dataState).sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const [dataTourInit, setDataTourInit] = useState([]);
    const LoadDataTour = () => {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
            setDataTourInit(res.data);
        })
    }
    const clickCategory = (id, title) => {

        if (id === 0) {
            history.push('/service', id);
        }
        else {
            history.push(`/category`, id);
        }
    }
    const clickSub = (idSub, idMenu, title) => {
        if (dataTourInit.filter(i => i.IDMenu === idSub && i.IDSubMenu === 0).length >= 1) {
            history.push(`/tour/${title}`, idSub);
        }
        else {
            history.push(`/category/${title}`, {
                idSub: idSub,
                idMenu: idMenu
            });
        }
    }
    useEffect(() => {
        if (pathnames.includes('category')) {
            LoadDataSaleBanner()
            LoadDataTour();
            LoadDataSubMenu()
            LoadDataCategory();
        }

    }, [dataState, Language])
    const data = dataSubMenu.map(r => (
        {
            title: Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID,
        }
    ))

    return (
        <div>

            <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
                {SearchBar(dataSaleBanner, Language)}

                <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
                    <Row gutter={[16, 16]}>
                        <Col style={{ marginTop: 5 }} span={24}>

                        </Col>
                        <Col xs={24} xl={24} span={24}>
                            <Row>
                                <Col xs={24} xl={6} span={5}>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <MonitorOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                        </Col>
                                        <Col span={24}>
                                            {dataSaleBanner.map(r => (
                                                <h6 style={{ textShadow: "0px  0px  1px  black" }}>{Language === 'vi' ? String(r.Title1).split(';')[0] : String(r.Title1).split(';')[1]}</h6>
                                            ))}

                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={24} xl={6} span={5}>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <DribbbleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                        </Col>
                                        <Col span={24}>
                                            {dataSaleBanner.map(r => (
                                                <h6 style={{ textShadow: "0px  0px  1px  black" }}>{Language === 'vi' ? String(r.Title2).split(';')[0] : String(r.Title2).split(';')[1]}</h6>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} xl={4} span={5}>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <CheckCircleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                        </Col>
                                        <Col span={24}>
                                            {dataSaleBanner.map(r => (
                                                <h6 style={{ textShadow: "0px  0px  1px  black" }}>{Language === 'vi' ? String(r.Title3).split(';')[0] : String(r.Title3).split(';')[1]}</h6>
                                            ))}
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={24} xl={4} span={5}>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <PhoneOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                        </Col>
                                        <Col span={24}>
                                            {dataSaleBanner.map(r => (
                                                <h6 style={{ textShadow: "0px  0px  1px  black" }}>{Language === 'vi' ? String(r.Title4).split(';')[0] : String(r.Title4).split(';')[1]}</h6>
                                            ))}
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={24} xl={4} span={4}>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <MoneyCollectOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                        </Col>
                                        <Col span={24}>
                                            {dataSaleBanner.map(r => (
                                                <h6 style={{ textShadow: "0px  0px  1px  black" }}>{Language === 'vi' ? String(r.Title5).split(';')[0] : String(r.Title5).split(';')[1]}</h6>
                                            ))}
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ marginTop: 5 }} span={24}>

                        </Col>
                    </Row>
                </div>
                <Card bodyStyle={{ backgroundColor: 'white', minHeight: 300, position: 'relative' }}>
                    <Row gutter={[32, 8]}>
                        {Category.concat(serviceCategory).slice(0, 4).map(item => (
                            //xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2
                            <Col xs={24} xl={6} xxl={6} lg={6} sm={24} md={24} span={6}>
                                <Card onClick={(e) => clickCategory(item.id, item.title)} hoverable bodyStyle={{ backgroundImage: `url(${item.src})`, minWidth: 310, minHeight: 220, textAlign: 'center', backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                    <Row>
                                        <Col style={{ height: 120 }} span={24}>
                                            <h4 style={{ marginTop: 60, color: 'white', textShadow: "0px  0px  10px  black" }}>{item.title}</h4>
                                        </Col>
                                        <Col span={24}>
                                            <Button onClick={(e) => clickCategory(item.id, item.title)} type="text">  <h6 onClick={(e) => clickCategory(item.id, item.title)} style={{ color: 'white', marginBottom: 30, textShadow: "0px  0px  10px  black" }}>See More </h6></Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                </Card>
            </div>
            <Card headStyle={{ textAlign: 'center', color: '#367AA7', fontSize: 25, fontWeight: 'bold' }} title={titleCategory}>
                <div style={{ backgroundColor: 'white', minHeight: 350 }}>
                    <List
                        grid={{ gutter: 64, column: 4, xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2 }}
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item onClick={(e) => clickSub(item.id, item.idMenu, item.title)}>
                                <Card hoverable bodyStyle={{ backgroundImage: `url(${item.src})`, minWidth: 320, minHeight: 220, textAlign: 'center', backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                    <Row gutter={[16, 16]}>
                                        <Col style={{ height: 120 }} span={24}>
                                            <h4 style={{ marginTop: 60, color: 'white', textShadow: "0px  0px  10px  black" }}>{item.title}</h4>
                                        </Col>
                                        <Col span={24}>
                                            <Button onClick={(e) => clickSub(item.id, item.idMenu, item.title)} type="text">  <h6 style={{ color: 'white', marginBottom: 30, textShadow: "0px  0px  10px  black" }}>See More </h6></Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )} />
                </div>
                <div>
                    <br />
                </div>
            </Card>

        </div>
    )
}
export default CategoryTour