import { Card, Col, Divider, Row, List, Image, Button, Avatar, Carousel, Form, Input, DatePicker, Select, Upload } from "antd";
import Translate from "../../common/Lang/translate";
import React, { useRef, Fragment, useEffect, useState } from "react";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import countryMap from '../../common/countries/countries';
import SlideImage from "../SlideImage/slideimage";
import axios from "axios";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import SearchBar from "../SearchBar/searchbar";
import moment from "moment";
import swal from "sweetalert";
import TheMost from "./most";
const { Option } = Select
const bodyEmail = `<html>
<body>
<h2 style="text-align: center">Customize Tour Request</h2>
<p style="text-align: right;font-style: italic">Day:{today}</p>
<p style="font-size: 20px">You’have just recived an email of a customize sevices from. Please reply as soon as possible.</p>
<font size="3" face="Verdana" >
<h4 style="text-align: center">CUSTOMER INFORMATION</h4>
<table style="border: 1px solid black; border-collapse: collapse;">
  <tr style="border: 1px solid black; padding: 10px;">
	<th style="border: 1px solid black; padding: 10px;">FULLNAME</th>
    <th style="border: 1px solid black; padding: 10px;">NATIONALITY</th>
    <th style="border: 1px solid black; padding: 10px;">EMAIL</th>
    <th style="border: 1px solid black; padding: 10px;">PHONE/ WHAT’S APP</th>
  </tr>
  <tr style="border: 1px solid black; padding: 10px;">
     <td style="border: 1px solid black; padding: 10px;">{FULLNAME}</td>
    <td style="border: 1px solid black; padding: 10px;">{NATIONALITY}</td>
	 <td style="border: 1px solid black; padding: 10px;">{EMAIL}</td>
     <td style="border: 1px solid black; padding: 10px;">{PHONE}</td>
   
 
  
</table>

<br />
<h4 style="text-align: center">SERVICE INFORMATION</h4>
<strong>What Kind Of Services Would You Take?</strong>&ensp;<span style="font-size: 20px;color:#0066ff">{kindofSerivce}</span>
<hr />
<strong>Proposed Date Of Services?</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{ProposedDate}</span>
<hr />
<strong>What is Your Availabe Time?</strong>&ensp;<span style="font-size: 20px;color:#0066ff"> {AvailabeTime}</span>
<hr />
<strong>How many people in your group?</strong> &ensp;<span style="font-size: 20px;color:#0066ff">{manyPeople} </span>
<hr />
<strong>Transportation On Yourself?</strong> &ensp;<span style="font-size: 20px;color:#0066ff">{Transportation}</span>
<hr />
<strong>Pick up Address</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{Pickup}</span>
<hr />
<strong>Payment Method?</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{Paymen}</span>
<hr />
<strong>Special Require?</strong>&ensp;<span style="font-size: 20px;color:#0066ff"> {Special}</span>
<hr />

</font>
</body>
</html>`
const Spa = (Language) => {
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    const history = useHistory();
    const dataState = history.location.state;
    const [form] = Form.useForm()

    const onFinish = (values) => {
        if (values.confirmEMAIL === values.EMAIL) {
            const pluginEmail = String(bodyEmail).replace('{today}', moment().format('DD/MM/YYYY HH:mm')).replace('{FULLNAME}', values.FULLNAME).replace('{NATIONALITY}', values.NATIONALITY).replace('{EMAIL}', values.EMAIL).replace('{PHONE}', values.PHONE).replace('{kindofSerivce}', values.kindofSerivce).replace('{ProposedDate}', values.ProposedDate === undefined ? '' : moment(values.ProposedDate).format('DD/MM/YYYY')).replace('{AvailabeTime}', values.AvailabeTime === undefined ? '' : moment(values.AvailabeTime).format('HH:mm')).replace('{manyPeople}', values.manyPeople).replace('{Transportation}', values.Transportation).replace('{Pickup}', values.Pickup).replace('{Paymen}', values.Paymen).replace('{Special}', values.Special)
            axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                "ID": 1,
                "toEmail": values.EMAIL,
                "subject": 'From Nomadictravel Vn Team',
                "content": `<div>Thank you for your attention on our services! We've just received your email request for a customize service. Our experts are going to planning up and will send you the detail information as soon as  possible. Please keep updating your email!<br />
                For express response, please contact us via What'sApp:+84 981 76 76 77 <br /> <br />
                Best Regards, <br />
                NomadicTravel VN Team
                <div>`,
                "alert": ''
            })

            axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                "ID": 1,
                "toEmail": 'nomadictravelvn@gmail.com',
                "subject": 'Customize Tour Request',
                "content": pluginEmail,
                "alert": 'New Email Book Tour'
            })
            swal(`Thank you for your attention on our services! We’ve just received your email request for a customize trip. Our experts are going to planning up for your trip and will send you the intinerary as soon as  possible. Please keep updating your email!
            Best Regards`, {
                icon: 'success',
                timer: 10000
            });
            form.resetFields();

        }
        else {
            swal('Emails do not match. Please re-enter!', {
                icon: 'warning',
                timer: 3000
            })
        }

    }
    const clickTopActivity = () => {
        const body = document.querySelector('#TheMost');
        body.scrollIntoView({
            behavior: 'smooth',
            block: "end"
        }, 500);
    }
    return (
        <div style={{ backgroundColor: 'white' }}>
            <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
                {SearchBar(Language)}
            </div>
            <div>
                <br />
                <Row>
                    <Col span={2}>

                    </Col>
                    <Col xs={24} xl={20} span={20}>
                        <Row>
                            <Col style={{ textAlign: 'center' }} span={24}>
                                <strong style={{ fontSize: 18 }}>
                                    SPA & MASSAGE SERVICE
                                </strong>
                            </Col>
                            <Col span={24}>
                                <br />

                            </Col>
                            <Col span={24}>
                                As a local company in Dalat City, has been co-operated with our partnes for long time so we always get special offers from our partners and we want to take this advantage to improve our services better, make your perfect time during your stay in Dalat City.  Don’t be hesitated!
                                Please fill out the form below to get the best service as you wish. Get 10% discount apply for customers who has done one of tours with Nomadictravel VN.


                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={24}>
                                <Form
                                    style={{ border: '2px solid #003D67' }}
                                    id="form"
                                    form={form}
                                    onFinish={onFinish}
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 16,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    autoComplete="off"
                                >
                                    <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} title={<strong>
                                        CUSTOMER INFORMATION
                                    </strong>}>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Fullname!",
                                            },
                                        ]} label='FULLNAME' name={'FULLNAME'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Choose NATIONALITY!",
                                            },
                                        ]} label='NATIONALITY' name={'NATIONALITY'}>
                                            <Select showSearch >
                                                {countryMap.map(r => (
                                                    <Select.Option value={r.name}>{r.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Email!",
                                                type: "email",
                                            },
                                        ]} label='EMAIL' name={'EMAIL'}>
                                            <Input onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Email!",
                                                type: "email",
                                            },
                                        ]} label='CONFIRM EMAIL' name={'confirmEMAIL'}>
                                            <Input onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Phone!",
                                                pattern: new RegExp(/^[0-9]+$/),
                                            },
                                        ]} label='PHONE/ WHAT’S APP' name={'PHONE'}>
                                            <Input />
                                        </Form.Item>
                                    </Card>
                                    <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} title={<strong>
                                        SERVICE INFORMATION
                                    </strong>}>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter your Kind Of Services",
                                            },
                                        ]} label='What Kind Of Services Would You Take?' name={'kindofSerivce'}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please choose pickup time!",
                                            },
                                        ]} label='Proposed Date Of Services?' name={'ProposedDate'}>
                                            <DatePicker disabledDate={(current) => {
                                                let customDate = moment().format("DD-MM-YYYY");
                                                return current && current < moment(customDate, "DD-MM-YYYY");
                                            }} style={{ width: '100%' }} format={'DD-MM-YYYY HH:mm'} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please choose Availabe Time!",
                                            },
                                        ]} label='What is Your Availabe Time?' name={'AvailabeTime'}>
                                            <DatePicker showTime placeholder="Select Time" />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter  many days do you have!",
                                            },
                                        ]} label='How many people in your group?' name={'manyPeople'}>
                                            <Input type="number" />
                                        </Form.Item>

                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Transportation On Yourself!",
                                            },
                                        ]} label='Transportation On Yourself?' name={'Transportation'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Pick up Address!",
                                            },
                                        ]} label='Pick up Address' name={'Pickup'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Enter Pick up Address!",
                                            },
                                        ]} label='Payment Method?' name={'Paymen'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Enter Pick up Address!",
                                            },
                                        ]} label='Special Require?' name={'Special'}>
                                            <Input.TextArea />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Enter Pick up Address!",
                                            },
                                        ]} label='Prefered Massage/Spa Name?' name={'Prefered'}>
                                            <Input />
                                        </Form.Item>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <Button form="form" size="large" style={{ borderRadius: 5, backgroundColor: '#F89810' }} type="primary" htmlType="submit">
                                                <strong>SUBMIT</strong>
                                            </Button>
                                        </Col>
                                    </Card>
                                </Form>
                            </Col>
                            <Col style={{ textAlign: 'center' }} span={24}>
                                <i style={{ fontSize: 18 }}>
                                    You can refer to our list of destinations, activities, transportation and services <span  onClick={(e) => clickTopActivity(e)} style={{ color: 'blue' }}>here</span>
                                </i>
                            </Col>
                        </Row>

                    </Col>
                    <Col id="TheMost" span={24}>
                        <TheMost />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Spa