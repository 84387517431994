import { menuItemsData } from "../../menuItemsData";
import MenuItems from "./MenuItems";

const Navbar = (data, language, isLogin, badge,dataService) => {
  const depthLevel = 0;

  return (
    <nav className="desktop-nav">
      <ul className="menus">
        {menuItemsData(data, language, isLogin,dataService).map((menu, index) => {
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} badge={badge} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
