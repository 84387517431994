import { Button, Card, Col, Image, Row, Steps, Avatar, InputNumber, Divider, Input, List, Select, Form, DatePicker } from "antd";
import "antd/dist/antd.css";
import {
    TagOutlined, PlusOutlined, CloseSquareOutlined, LeftOutlined, RightOutlined, FontColorsOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import countryMap from '../../common/countries/countries';
import { formatShortDate } from "../../common/Format";
import swal from "sweetalert";
const { Step } = Steps;
const { Meta } = Card;
const { Option } = Select;
const tableTour = `
<html>
<body>
<h2 style="text-align: center"> BOOKING DETAIL</h2>
<p style="text-align: right;font-style: italic">TOUR IDCODE:{Code}</p>
<font size="3" face="Verdana" >
<table style="border: 1px solid black; border-collapse: collapse;">
  <tr style="border: 1px solid black; padding: 10px;">
	<th style="border: 1px solid black; padding: 10px;">Type of Tour</th>
    <th style="border: 1px solid black; padding: 10px;">Name of primary guest</th>
    <th style="border: 1px solid black; padding: 10px;">Number of guest</th>
    <th style="border: 1px solid black; padding: 10px;">Number of Chidren < 120cm </th>
    <th style="border: 1px solid black; padding: 10px;">Number of Children < 100 cm </th>
    <th style="border: 1px solid black; padding: 10px;">Date of departure</th>
    <th style="border: 1px solid black; padding: 10px;">Pick up address</th>
  </tr>
  <tr style="border: 1px solid black; padding: 10px;">
     <td style="border: 1px solid black; padding: 10px;">{TypeofTour}</td>
    <td style="border: 1px solid black; padding: 10px;">{nameCustomer}</td>
	 <td style="border: 1px solid black; padding: 10px;">{NumberofParticipants}</td>
     <td style="border: 1px solid black; padding: 10px;">{NumberofChildren}</td>
    <td style="border: 1px solid black; padding: 10px;">{Numberofchildrenunder}</td>
     <td style="border: 1px solid black; padding: 10px;">{StartDay}</td>
    <td style="border: 1px solid black; padding: 10px;">{PickUpAddress}</td>
 <tfoot>
    <tr style="border: 1px solid black; padding: 10px;">
    <th >Note</th>
    <td style="border: 1px solid black; padding: 10px;" colspan="8">{SpecialRequest}</td>
    
    </tr>
  </tfoot>
  <tfoot>
    <tr style="border: 1px solid black; padding: 10px;">
      <th>Total</th>
      <td style="border: 1px solid black; padding: 10px; text-align: right;" colspan="8">{TotalPrice} USD</td>
    </tr>
  </tfoot>
  <tfoot>
    <tr style="border: 1px solid black; padding: 10px;">
    <th >Payment</th>
    <td style="border: 1px solid black; padding: 10px; text-align: right;" colspan="8">{MethodOfPayment}</td>
    </tr>
  </tfoot>
</table>
</font>
</body>
</html>
`
const emailconfirm = `

<html>

<body>

<h2 style="text-align: center">TOUR BOOKING INFORMATION - {Tourdeparture}</h2>

<p >Tour information - IDCODE:{Code}</p>
<table style="border-collapse: collapse;
  width: 100%">
  <tr>
   <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Type of Tour</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Pick Up Address</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Tour departure</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Method Payment</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Special Request</th>
   
 
  </tr>
  <tr>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{TypeTour}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{PickUpAddress}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Tourdeparture1}</td>
   <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{MethodPayment}</td>
  <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{SpecialRequest}</td>
  </tr>
 
</table>	
<p>Customer information</p>
<table style="border-collapse: collapse;
  width: 100%">
  <tr>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Guest's name</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Age</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Gender</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Contact</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Email</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Nationality</th>
    
  </tr>
  <tr>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{FullName}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Age}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Gender}</td>
   <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{PhoneCus}</td>
  <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{EmailCus}</td>
  <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Nationality}</td>
  </tr>
 
</table>
<p >Invoice</p>
<table style="border-collapse: collapse;
  width: 100%">
  <tr>
   <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Number of Guest</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Number of Children < 120</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Number of Children < 100</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Total</th>
 
   
 
  </tr>
  <tr>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{NumberofGuest}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{NumberofChildren}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Numberofchildrenunder}</td>
   <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{price}</td>
  
  </tr>
 
</table>	

</body>
</html>
`
const CheckOut = (language) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dataTourID, setDataTourID] = useState([]);
    const history = useHistory();
    const dataState = history.location.state;
    const [dataService, setDataService] = useState([]);
    const [dataDetailTour, setDataDetailTour] = useState([]);
    const [dataBookTourByID, setDataBookTourByID] = useState([]);
    const [dataServiceDetail, setDataServiceDetail] = useState([]);
    const idBook = sessionStorage.getItem('idBook');
    const [dataEmail, setDataEmail] = useState([]);
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);

    const LoadDataEmail = () => {
        axios.post('https://dgss.vn:7001/api/Email/GetEmailAll', '').then(res => {
            if (res.status === 200) {
                setDataEmail(res.data);
            }
        })
    }
    const deleteCart = (e) => {
        form.resetFields();
        if (idBook !== null && idBook !== undefined) {
            axios.post(`https://dgss.vn:7001/api/BookTour/DeleteBookTour/${idBook}`, '').then(res => {

                sessionStorage.clear()
                localStorage.removeItem('price');
                localStorage.removeItem('age');
                localStorage.removeItem('badge');
                localStorage.removeItem('idtour')
                history.push('/', null);
            })
        }
        else {
            sessionStorage.clear()
            localStorage.removeItem('idtour');
            localStorage.removeItem('idBook');
            history.push('/cart')
        }
    }
    const service = dataServiceDetail.map(r => (
        {
            title: dataService.filter(d => d.ID === r.IDService).map(d => d.Title)[0],
            price: r.Price
        }
    ))

    const LoadServiceDetail = () => {
        axios.post('https://dgss.vn:7001/api/ServiceDetail/GetServiceDetailAll', '').then(res => {
            setDataServiceDetail(res.data.filter(i => i.IDBookTour === Number(idBook)));

        })
    }
    const LoadService = () => {
        axios.post('https://dgss.vn:7001/api/Services/GetServicesAll', '').then(res => {
            setDataService(res.data.filter(i => i.Status === true));
        })
    }
    const LoadBookTourbyID = () => {
        axios.post(`https://dgss.vn:7001/api/BookTour/GetBookTourByID/${idBook}`, '').then(res => {
            if (res.status === 200) {
                setDataBookTourByID(res.data);
                axios.post(`https://dgss.vn:7001/api/Tour/GetTourAll`, '').then(d => {
                    if (d.status === 200) {
                        setDataTourID(d.data.filter(i => i.ID === res.data.IDTour));
                    }
                })
            }
        })
    }
    const LoadDetailBookTour = () => {
        axios.post(`https://dgss.vn:7001/api/ServiceDetail/GetServiceDetailAll`, '').then(res => {
            setDataDetailTour(res.data.filter(i => i.IDBookTour === idBook));
        })
    }

    useEffect(() => {
        if (pathnames.includes('checkout')) {
            LoadDataEmail();
            LoadBookTourbyID();
            LoadServiceDetail()
            LoadService();
            LoadDetailBookTour();
        }

    }, [dataState])
    const onFinishSubmit = (values) => {
        setLoading(true)
        axios.post('https://dgss.vn:7001/api/Customers/AddCustomers', {
            "ID": 1,
            "FullName": values.FullName,
            "Birthday": moment(values.Birthday).format('DD/MM/YYYY'),
            "Nationality": values.Nationality,
            "Age": values.Age,
            "Gender": values.Gender,
            "Email": values.Email,
            "Phone": values.Phone,
            "Whatapp": "",
            "Address": values.Address

        }).then(res => {
            if (res.status === 200) {
                let idCus = 0;
                let email = '';
                let name = '';
                let phone = '';
                Array(res.data).forEach(({ ID, Email, Phone, FullName }) => {
                    idCus = ID;
                    email = Email;
                    name = FullName;
                    phone = Phone
                });
                axios.post(`https://dgss.vn:7001/api/BookTour/UpdateBookTour/${idBook}`, {
                    "ID": idBook,
                    "IDTour": Number(dataBookTourByID.IDTour),
                    "StartDay": dataBookTourByID.StartDay,
                    "TotalPrice": dataBookTourByID.TotalPrice + Number(dataBookTourByID.NumberofChildren) * Number(dataTourID.map(o => o.Price)) + sumArray(service.map(r => r.price)),
                    "NumberofParticipants": dataBookTourByID.NumberofParticipants,
                    "NumberofChildren": values.NumberofChildren === undefined ? 0 : values.NumberofChildren,
                    "Numberofchildrenunder": values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder,
                    "PickUpAddress": values.Address,
                    "IDServices": 0,
                    "IDCustomer": res.data['ID'],
                    "MethodOfPayment": values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment,
                    "SpecialRequest": values.SpecialRequest
                }).then(d => {
                    if (d.status === 200) {

                        let starday = '';
                        let totalPrice = 0;
                        Array(d.data).forEach(({ ID, StartDay, TotalPrice }) => {
                            idBook = ID;
                            starday = moment(StartDay).format('YYYY/MM/DD HH:mm');
                            totalPrice = TotalPrice
                        });
                        setLoading(false)
                        swal('Submit success. We will email you soon', {
                            icon: 'success',
                            timer: 3000
                        })
                        history.push('/', null);
                        sessionStorage.clear();
                        form.resetFields();

                        // --------------
                        const nameCustomer = name;
                        const price = dataBookTourByID.TotalPrice + Number(dataBookTourByID.NumberofChildren) * Number(dataTourID.map(o => o.Price)) + sumArray(service.map(r => r.price));
                        const dayBookTour = moment(values.Tourdeparture).format('YYYY/MM/DD HH:mm');
                        const contentReplace = String(dataEmail.filter(i => i.ID === 1).map(r => r.content)[0]).replace('{nameCustomer}', nameCustomer).replace('{price}', price).replace('{phone}', phone).replace('{dayBookTour}', dayBookTour);
                        const pluginEmail = String(tableTour).replace('{Code}',`${Number(dataBookTourByID.IDTour)}${idBook}`).replace('{TypeofTour}', dataTourID.map(r => String(r.Title).split(';')[0])).replace('{nameCustomer}', nameCustomer).replace('{NumberofParticipants}', values.NumberofGuest === undefined ? 1 : values.NumberofGuest).replace('{NumberofChildren}', values.NumberofChildren === undefined ? 0 : values.NumberofChildren).replace('{Numberofchildrenunder}', values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder).replace('{StartDay}', moment(values.Tourdeparture).format('YYYY/MM/DD')).replace('{PickUpAddress}', values.Address).replace('{TotalPrice}', price).replace('{MethodOfPayment}', values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment).replace('{SpecialRequest}', values.SpecialRequest === undefined ? '' : values.SpecialRequest)
                        const emailconfirmcontent = String(emailconfirm).replace('{TypeofTour}', dataTourID.map(r => String(r.Title).split(';')[0])).replace('{TypeTour}', values.TypeTour).replace('{NumberofGuest}', values.NumberofGuest === undefined ? 1 : values.NumberofGuest).replace('{NumberofChildren}', values.NumberofChildren === undefined ? 0 : values.NumberofChildren).replace('{Numberofchildrenunder}', values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder).replace('{Tourdeparture}', moment(values.Tourdeparture).format('YYYY/MM/DD')).replace('{PickUpAddress}', values.PickUpAddress).replace('{price}', price).replace('{MethodPayment}', values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment).replace('{Code}',`${Number(dataBookTourByID.IDTour)}${idBook}`).replace('{SpecialRequest}', values.SpecialRequest === undefined ? '' : values.SpecialRequest).replace('{Age}', values.Age).replace('{Gender}', values.Gender).replace('{PhoneCus}', values.PhoneCus).replace('{EmailCus}', values.EmailCus).replace('{Nationality}', countryMap.filter(i => i.code === values.Nationality).map(r => r.name)[0]).replace('{FullName}', values.FullName).replace('{Tourdeparture1}', moment(values.Tourdeparture).format('YYYY/MM/DD'))
                        axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                            "ID": 1,
                            "toEmail": email,
                            "subject": dataEmail.filter(i => i.ID === 1).map(r => r.subject)[0],
                            "content": contentReplace + pluginEmail,
                            "alert": dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0]
                        })
                        axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                            "ID": 1,
                            "toEmail": 'nomadictravelvn@gmail.com',
                            "subject": dataEmail.filter(i => i.ID === 1).map(r => r.subject)[0],
                            "content": emailconfirmcontent,
                            "alert": dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0]
                        })

                        swal(dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0], {
                            icon: 'success',
                            timer: 10000
                        });
                    }
                })
            }
        })

    }
    function sumArray(array) {
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
            sum += array[i];
        }
        return sum;
    }
    return (
        <div style={{ backgroundColor: 'white' }}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={3}>

                        </Col>
                        <Col span={20}>
                            <h2>Check Out</h2>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={3}>

                        </Col>
                        <Col span={20}>

                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row>
                {/* cart */}
                <Col span={3}>

                </Col>
                <Col span={18}>
                    <Row>
                        <Col style={{ textAlign: 'center' }} span={24}>
                            <Steps size="small" current={2}>
                                <Step title="Finished" description="More tour to cart success." />
                                <Step title="In Progress" description="Select extras." />
                                <Step title="Checkout" description="Confirm Information" />
                            </Steps>
                        </Col>
                        <Col span={24}>
                            <h4>Tour information and accompanying services</h4>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col xl={7} xs={24} span={16}>
                                    <Row>
                                        <Col span={24}>
                                            <Card bodyStyle={{ minHeight: 227 }} headStyle={{ borderTopColor: '#F89810' }} title='Order summary'>
                                                <Row>
                                                    <Col span={22}>
                                                        <Meta

                                                            title={dataTourID.map(r => language === 'vi' ? String(r.Title).split(';')[1] : String(r.Title).split(';')[0])}
                                                            description={<div>
                                                                {formatShortDate(dataBookTourByID.StartDay)}
                                                            </div>}
                                                        />
                                                    </Col>
                                                    <Col span={2}>
                                                        <Button onClick={(e) => deleteCart(e)} style={{ color: 'red' }} type="text" icon={<CloseSquareOutlined />}></Button>
                                                    </Col>
                                                </Row>
                                                <Col span={24}>
                                                    <br />
                                                </Col>
                                                <Row>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <b>Participants: {dataBookTourByID.NumberofParticipants}</b>
                                                            </Col>
                                                            <Col span={24}>
                                                                <List
                                                                    dataSource={service}
                                                                    renderItem={(item, index) => (
                                                                        <List.Item>
                                                                            <List.Item.Meta
                                                                                avatar={
                                                                                    <CheckOutlined />
                                                                                }
                                                                                description={
                                                                                    <div>
                                                                                        <Row>
                                                                                            <Col span={18}>
                                                                                                {item.title}
                                                                                            </Col>
                                                                                            <Col span={6}>
                                                                                                {item.price} $
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </List.Item>
                                                                    )}
                                                                />
                                                            </Col>
                                                            <Col span={24}>
                                                                <div style={{ backgroundColor: '#EEEEEE', width: 50, textAlign: 'center' }}>
                                                                    {sumArray(service.map(r => r.price))} $
                                                                </div>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row>
                                                                    <Col span={17}>
                                                                        <b>Children: {dataBookTourByID.NumberofChildren}</b>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <div style={{ width: 50, textAlign: 'center' }}>
                                                                            {Number(dataBookTourByID.NumberofChildren) * Number(dataTourID.map(o => o.Price))} $
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                            <Col span={24}>
                                                                <br />
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row>
                                                                    <Col style={{ textAlign: 'center' }} span={12}>
                                                                        Total
                                                                    </Col>
                                                                    <Col style={{ marginLeft: -20, marginTop: -5 }} span={8}>
                                                                        <h4 style={{ color: '#27AE60' }}> {dataBookTourByID.TotalPrice + Number(dataBookTourByID.NumberofChildren) * Number(dataTourID.map(o => o.Price)) + sumArray(service.map(r => r.price))}  $</h4>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row>
                                                                    <Col style={{ marginTop: -4 }} span={4}>
                                                                        <TagOutlined size={30} style={{ color: '#27AE60' }} />
                                                                    </Col>
                                                                    <Col span={18}>
                                                                        <h6 style={{ color: '#27AE60' }}>BEST PRICE GUARANTEE</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={16} xs={24} span={7}>
                                    <Card title='Your details'>
                                        <Form form={form} onFinish={onFinishSubmit} id="form-customer" layout="vertical"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            autoComplete="off">
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Input your fullname",
                                                },
                                            ]} name={'FullName'} label='FullName'>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Input your phone",
                                                    pattern: new RegExp(/^[0-9]+$/),
                                                },
                                            ]} name={'Phone'} label='Phone'>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Input your email",
                                                    type: "email",
                                                },
                                            ]} name={'Email'} label='Email'>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Input your address",
                                                },
                                            ]} name={'Address'} label="Address">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: 'Choose Gender',
                                                },
                                            ]} name={'Gender'} label='Gender'>
                                                <Select>
                                                    <Select.Option value='Male'>Male</Select.Option>
                                                    <Select.Option value='Female'>Female</Select.Option>
                                                    <Select.Option value='Other'>Other</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Choose your birthday",
                                                },
                                            ]} name={'Birthday'} label='Birthday'>
                                                <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} disabledTime={true} />
                                            </Form.Item>

                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: 'Choose Nationality',
                                                },
                                            ]} name={'Nationality'} label='Nationality'>
                                                <Select showSearch >
                                                    {countryMap.map(r => (
                                                        <Select.Option value={r.code}>{r.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name={'MethodPayment'} label='Method of Payment'>
                                                <Select defaultValue={'Cash'}>
                                                    <Select.Option value='Transfer'>Transfer</Select.Option>
                                                    <Select.Option value='Cash'>Cash</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name={'SpecialRequest'} label='Special Request'>
                                                <Input.TextArea placeholder="Foods, Allergic…" />
                                            </Form.Item>
                                        </Form>

                                        <Row>

                                            <Col span={24}>
                                                <Button loading={loading} form="form-customer" type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#F89810', height: 40, borderRadius: 4, color: 'white' }}><b>SUBMIT</b></Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default CheckOut;