import { Button, Card, Col, Image, Row, Steps, Avatar, InputNumber, Divider, List } from "antd";
import {
    TagOutlined, PlusOutlined, CloseSquareOutlined, LeftOutlined, RightOutlined, FontColorsOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Step } = Steps;
const { Meta } = Card;
const BlogDetail = (language) => {
   
    const history = useHistory();
    const id = history.location.state;
    const [dataBlogByID, setDataBlogByID] = useState([]);
    const [dataCategory,setDataCategory] = useState([]);
    const [dataSubMenu,setDataSubMenu] = useState([]);
    const LoadDataBlogByID = () => {
        axios.post(`https://dgss.vn:7001/api/Blog/GetBlogAll`, '').then(res => {
            if (res.status === 200) {
                setDataBlogByID(res.data.filter(i => i.ID === id));
            }
        })
    }
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '').then(res => {
            setDataCategory(res.data);
        })
    }
    const LoadDataSubMenu = () => {
        axios.post('https://dgss.vn:7001/api/SubMenu/GetSubMenuAll', '').then(res => {
            setDataSubMenu(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    useEffect(() => {
        LoadDataBlogByID();
        LoadDataCategory();
        LoadDataSubMenu();
    }, [id])
    const datarticle = dataBlogByID.map(r => (
        {
            slogan: r.Slogan,
            date: r.DayofWriting,
            title: r.Title,
            description: r.About,
            src: 'https://adventures.com/media/207072/s-iceland-dyrholaey-black-sand-beach-seastack-rock-formation-autumn-sunset-sunrise-south-coast.jpg?anchor=center&mode=crop&width=530&height=330&rnd=132772191600000000&quality=100&format=webp',
            titlesub: r.TitleSale,
            title: language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID,
            writer: r.Writer,
            content: r.Content,
            map:r.Map
        }
    ))
    const dataCategoryPopular = dataCategory.filter(i => i.ID !== 18).slice(0,4).map(r => (
        {
            slogan: r.Description,
            title: language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            id: r.ID,
            image:r.Image
        }
    ))
    const clickCategory = (title,idTour)=>{
        history.push(`/category`, idTour);
    }
    const dataTagCloud = [
        {
            title: 'CANADA'
        },
        {
            title: 'HIKING'
        },
        {
            title: 'ICELAND'
        },
        {
            title: "NORTHERN LIGHTS"
        },
        {
            title: 'NORWAY'
        },
        {
            title: 'REYKJAVIK'
        },
        {
            title: 'SWEDEN'
        }
    ]
    return (
        <div style={{ backgroundColor: 'white' }}>
            <Row>
                <Col span={2}>

                </Col>
                <Col span={20}>
                    <Row>
                        {datarticle.map(r => (
                            <Col span={18}>
                                <Row >
                                    <Col span={24}>
                                        <br />
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={4}>
                                                <Avatar src='https://m.media-amazon.com/images/S/pv-target-images/ae4816cade1a5b7f29787d0b89610132c72c7747041481c6619b9cc3302c0101.jpg' style={{ minWidth: 70, minHeight: 70 }} size={"large"} />
                                            </Col>
                                            <Col span={6}>
                                                <b>{r.writer}</b>
                                            </Col>
                                            <Col span={2}>
                                                <Divider style={{ height: 25 }} type="vertical" />
                                            </Col>
                                            <Col span={4}>
                                                <b>{moment(r.date).format('DD/MM/YYYY')}</b>
                                            </Col>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={4}>

                                                    </Col>
                                                    <Col span={18}>
                                                        <b style={{ color: 'gray' }}>{r.description}</b>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider type="horizontal" />
                                <div style={{fontFamily:'revert'}} dangerouslySetInnerHTML={{ __html: r.content }}>
                                </div>
                                <Divider type="horizontal" />
                            </Col>
                        ))}

                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={12}>

                                            <div style={{ minHeight: 65, textAlign: 'center', paddingTop: 20, backgroundColor: '#F89810', color: 'white' }}>
                                                <b>RECENS BLOG</b>
                                            </div>

                                        </Col>
                                        <Col span={12}>
                                            <div style={{ minHeight: 65, textAlign: 'center', paddingTop: 20, backgroundColor: '#367AA7', color: 'white' }}>
                                                <b>POPULAR BLOGS</b>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <br />
                                        </Col>
                                        <Col span={24}>
                                            <List
                                                
                                                grid={{ gutter: 16, column: 1, xs: 1 }}
                                                dataSource={dataCategoryPopular}
                                                renderItem={(item) => (
                                                    <List.Item onClick={(e => clickCategory(item.title,item.id))}>
                                                        <Card size="small" hoverable bodyStyle={{ backgroundImage: `url(${item.image})`, height: 170, textAlign: 'center', backgroundSize: "cover" }}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <h3 style={{ marginTop: 45, color: 'white' }}>{item.title}</h3>
                                                                    <h6 style={{ color: 'white' }}>See More </h6>
                                                                </Col>
                                                            </Row>
                                                        </Card>

                                                    </List.Item>
                                                )} />
                                        </Col>
                                        <Divider type="horizontal" style={{ width: 50 }} />
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <h4 style={{ color: '#717171' }}>TAG CLOUD</h4>
                                                </Col>
                                                <Col span={24}>
                                                    <List
                                                        grid={{ gutter: 16, column: 2, xs: 1 }}

                                                        dataSource={dataTagCloud}
                                                        renderItem={(item) => (
                                                            <List.Item >
                                                                <div style={{ border: '1px solid #717171', borderRadius: 10, textAlign: 'center', height: 30 }}>
                                                                    <b style={{ color: '#717171' }}>{item.title}</b>
                                                                </div>
                                                            </List.Item>
                                                        )} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}>

                </Col>
            </Row>
        </div>
    )
}
export default BlogDetail;
