import { Button, Card, Col, Image, Row, Steps, Avatar, InputNumber, Divider, Input, Form } from "antd";
import "antd/dist/antd.css";
import album from '../Images/album.jpg'
import {
    TagOutlined, PlusOutlined, CloseSquareOutlined, LeftOutlined, RightOutlined, FontColorsOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import { useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const { Step } = Steps;
const { Meta } = Card;

const Cart = (language) => {
   
    const idBook = sessionStorage.getItem('idBook');
    const statusBook = sessionStorage.getItem('statusbook');
    const history = useHistory();
    const dataState = history.location.state;
    const [dataService, setDataService] = useState([]);
    const [dataTourID, setDataTourID] = useState([]);
    const [form] = Form.useForm();
    const [totalPrice, setTotalPrice] = useState(0);
    const [statusPrice, setStatusPrice] = useState(false);
    const [dataBookByID, setDataBookByID] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    const deleteCart = (e) => {

        if (idBook !== null && idBook !== undefined) {
            axios.post(`https://dgss.vn:7001/api/BookTour/DeleteBookTour/${idBook}`, '').then(res => {

                sessionStorage.clear()
                localStorage.removeItem('price');
                localStorage.removeItem('age');
                localStorage.removeItem('badge');
                localStorage.removeItem('idtour')
                history.push('/', null);

            })
        }
        else {
            sessionStorage.clear()
            localStorage.removeItem('idtour');
            localStorage.removeItem('idBook');
            history.push('/cart')
        }

    }
    const [IDTour, setIDTour] = useState(0);

    const LoadBookByID = () => {
        const Service = [];
        axios.post(`https://dgss.vn:7001/api/BookTour/GetBookTourByID/${idBook}`, '').then(res => {
            if (res.status === 200) {
                setDataBookByID(res.data);
                setIDTour(res.data.IDTour);
                axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(restour => {
                    const dataTour = restour.data.filter(i => i.ID === res.data.IDTour);
                    const arrayService = String(dataTour[0].Services).split(';');
                    arrayService.map(r => {
                        axios.post('https://dgss.vn:7001/api/Services/GetServicesAll', '').then(resService => {
                            const dataService = resService.data.filter(i => i.ID === Number(r) && i.Status === true);
                            dataService.map(r => {
                                Service.push(r);
                            })
                            setDataService(Service)
                        })
                    })
                })
            }
        })
    }

    const LoadTourbyID = () => {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
            setDataTourID(res.data);
        })
    }

    const [arrayPriceTemp, setArrayPriceTemp] = useState([])
    const [serviceDetail, setServiceDetail] = useState([]);
    const fillPrice = (priceBook, ID) => {
        // chèn dữ liệu vào formAccount khi update
        form.setFieldsValue(
            {
                "TotalPrice": priceBook,
                "ID": ID,
            }
        );
    };
    const changeExtra = (e, id, price) => {
        const tmpArrService = serviceDetail;
        if (tmpArrService.filter(i => i.IDService === id).length > 0) {
            const removeIndex = tmpArrService.findIndex(item => item.IDService === id);
            tmpArrService.splice(removeIndex, 1);
            tmpArrService.push({
                IDService: id,
                Quality: Number(e.currentTarget.value),
                Price: price * Number(e.currentTarget.value)
            });
        }
        else {
            tmpArrService.push({
                IDService: id,
                Quality: Number(e.currentTarget.value),
                Price: price * Number(e.currentTarget.value)
            });

        }
        setServiceDetail(tmpArrService);
        const tmpArr = arrayPriceTemp
        if (tmpArr.filter(i => i.ID === id).length > 0) {
            const removeIndex = tmpArr.findIndex(item => item.ID === id);
            tmpArr.splice(removeIndex, 1);
            tmpArr.push({
                ID: id,
                price: price * Number(e.currentTarget.value)
            })
        }
        else {
            tmpArr.push({
                ID: id,
                price: price * Number(e.currentTarget.value)
            })

        }

        //console.log(arrayPriceTemp.map(r => r.price))
        setArrayPriceTemp(tmpArr)
        sumArray(tmpArr.map(r => r.price))
        setStatusPrice(true);
    }

    function sumArray(array) {
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
            sum += array[i];
        }
        //console.log(sum);
        setTotalPrice(Number(sum));
        fillPrice(dataBookByID.TotalPrice + Number(sum), dataBookByID.ID);
        return sum;
    }
    useEffect(() => {
        if(pathnames.includes('cart')){
            LoadTourbyID();
            LoadBookByID();
        }
       
    }, [dataState]);
    const onFinish = (values) => {
        // trường hợp không chọn extra
        setLoading(true)
        if (statusPrice === false) {
            setServiceDetail([])
            sessionStorage.setItem('idBook', idBook !== undefined && idBook !== null ? idBook : dataState)
            setLoading(false)
            sessionStorage.setItem('priceExtra',Number(dataBookByID.TotalPrice))
            history.push('/checkout', idBook !== undefined && idBook !== null ? idBook : dataState); // push ID Service Detail
        }
        else {
            serviceDetail.map(i => (
                axios.post('https://dgss.vn:7001/api/ServiceDetail/AddServiceDetail', {
                    "ID": 1,
                    "IDService": i.IDService,
                    "IDBookTour": idBook !== undefined && idBook !== null ? idBook : dataState,
                    "Quality": i.Quality,
                    "Price": i.Price,
                    "Note": ""
                }).then(res => {
                    // thêm service chuyển đến trang checkout 
                    // axios.post(`https://dgss.vn:7001/api/BookTour/UpdateBookTour/${Number(dataBookByID.IDTour)}`, {
                    //     "ID": idBook !== undefined ? idBook : dataState,
                    //     "IDTour": dataBookByID.IDTour,
                    //     "StartDay": dataBookByID.StartDay,
                    //     "TotalPrice": dataBookByID.TotalPrice,
                    //     "NumberofParticipants": dataBookByID.NumberofParticipants,
                    //     "NumberofChildren": dataBookByID.NumberofChildren,
                    //     "IDServices": 0,
                    //     "IDCustomer": 0
                    // })
                    setServiceDetail([]);
                    setLoading(false);
                    sessionStorage.setItem('priceExtra',Number(dataBookByID.TotalPrice) + Number(totalPrice))
                    sessionStorage.setItem('idBook', idBook !== undefined && idBook !== null ? idBook : dataState)
                    history.push('/checkout', idBook !== undefined && idBook !== null ? idBook : dataState); // push ID Service Detail
                })
            ))
        }
    }
    // if(statusBook === false || statusBook === null){
    //     return <Redirect to={"/"} />;
    // }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <Form form={form} onFinish={onFinish} id="form-cart">
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={3}>
                                <Form.Item hidden name={'ID'}>
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name={'IDService'}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <h2>{language === 'vi' ? 'Giỏ đặt chỗ của bạn' : 'Your Booking Cart'}</h2>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={3}>

                            </Col>
                            <Col span={20}>
                                <h5 style={{ color: '#367AA7' }}>{language === 'en' ? 'SAFE & SECURE PAYMENTS - NO BOOKING FEES - NO HIDDEN FEES' : 'THANH TOÁN AN TOÀN & ĐẢM BẢO - KHÔNG PHÍ ĐẶT CHỖ - KHÔNG PHÍ ẨN'}</h5>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                {statusBook !== null && statusBook !== false ? <Row>
                    {/* cart */}
                    <Col span={3}>

                    </Col>
                    <Col span={18}>
                        <Row>

                            <Col span={24}>
                                <h4>Add extras to your experience...</h4>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <Col xl={16} xs={24} span={16}>
                                        <Row>
                                            <Col span={24}>
                                                <Card bodyStyle={{ minHeight: 253 }} headStyle={{ borderTopColor: '#F89810' }} title='Select extras'>
                                                    {dataService.map((i, index) => (
                                                        <div>
                                                            <Meta
                                                                avatar={<Avatar size={'large'} src={album} />}
                                                                title={i.Title}
                                                                description={<div>
                                                                    {i.Price} $
                                                                </div>}
                                                            />
                                                            <Row>
                                                                <Col span={18}>
                                                                    How many <b>{i.Title} </b>do you want?
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Input id={i.ID} type="number" key={index} style={{ width: 50 }} min={0} onChange={(e) => changeExtra(e, i.ID, i.Price)} defaultValue={0} />
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                    ))}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={7} xs={24} span={7}>
                                        <Card title={dataTourID.filter(o => o.ID === IDTour).map(i => language === 'en' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1])}>
                                            <Row>
                                                <Col span={11}>
                                                    <Row>
                                                        <Col style={{ color: '#C8C8C8' }} span={24}>
                                                            Start Date
                                                        </Col>
                                                        <Col span={24}>

                                                            <p>{moment(dataBookByID.StartDay).format("DD-MM-YYYY")}</p>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={11}>
                                                    <Row>
                                                        <Col style={{ color: '#C8C8C8' }} span={24}>
                                                            Start Time
                                                        </Col>
                                                        <Col span={24}>
                                                            <p>{moment(dataBookByID.StartDay).format("HH:mm")}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={2}>
                                                    <Button onClick={(e) => deleteCart(e)} style={{ color: 'red' }} type="text" icon={<CloseSquareOutlined />}></Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        <Col style={{ color: '#C8C8C8' }} span={24}>
                                                            Participants
                                                        </Col>
                                                        <Col span={24}>
                                                            {dataBookByID.NumberofParticipants}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Row>
                                                        <Col style={{ color: '#C8C8C8', textAlign: 'right' }} span={24}>
                                                            Age
                                                        </Col>
                                                        <Col style={{ textAlign: 'right' }} span={24}>
                                                            {dataBookByID.NumberofChildren}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }} span={12}>
                                                            Total
                                                        </Col>
                                                        <Col style={{ marginLeft: -20, marginTop: -5 }} span={8}>
                                                            <h4 style={{ color: '#27AE60' }}>{statusPrice === false ? Number(dataBookByID.TotalPrice) : Number(dataBookByID.TotalPrice) + Number(totalPrice)}$</h4>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Form.Item hidden name={'TotalPrice'}>
                                                    <Input />
                                                </Form.Item>
                                            </Row>
                                            <Row>
                                                <Col style={{ textAlign: 'center' }} span={24}>
                                                    <Row>
                                                        <Col style={{ marginTop: -4 }} span={4}>
                                                            <TagOutlined size={30} style={{ color: '#27AE60' }} />
                                                        </Col>
                                                        <Col span={18}>
                                                            <h6 style={{ color: '#27AE60' }}>BEST PRICE GUARANTEE</h6>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Button loading={loading} form="form-cart" type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#F89810', height: 40, borderRadius: 4 }}><b>CHECK OUT</b></Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row> : <Row>
                    {/* cart */}
                    <Col span={3}>

                    </Col>
                    <Col span={18}>
                        <Card bodyStyle={{ backgroundColor: '#F8F8F8' }}>
                            <Row>
                                <Col span={24}>

                                    <div>
                                        <h3 style={{ backgroundColor: '#F7EFEF', color: '#E65050' }}>Shopping cart is empty.</h3>
                                    </div>

                                </Col>
                                <Col span={24}>
                                    <Button onClick={(e) => history.push('/')} size="large" style={{ width: '100%', color: '#92B7E7', fontSize: 16 }}>Return to tour</Button>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>}
                <Row>
                    <Col span={24}>
                        <br />
                    </Col>
                    <Col span={24}>
                        <h4 style={{ textAlign: 'center' }}>AVAILABLE PAYMENT METHODS</h4>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={3}>

                            </Col>
                            <Col span={18}>
                                <Image preview={false} width={'100%'} src='https://adventures.com/media/212853/payment-icons.png' />
                            </Col>
                        </Row>

                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={3}>

                            </Col>

                            <Col span={18}>
                                <p >All charges are made in the tour operators currency: For tours in Iceland it's Icelandic krona, ISK, but for tours in Canada/Greenland it's either USD or CAD. On Adventures.com you can view an estimate of prices in EUR and other currencies. This is only to help you understand the approximate price. Your credit card will always be charged in the operators currency. Keep in mind that your card issuer may charge you a foreign transaction fee.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

        </div>
    )
}
export default Cart;