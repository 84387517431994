import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import MobileDropdown from "./MobileDropdown";
import axios from "axios";

const MobileMenuItems = ({ items, depthLevel, showMenu, setShowMenu }) => {
  const [dropdown, setDropdown] = useState(false);
  const history = useHistory();
  const closeDropdown = () => {
    dropdown && setDropdown(false);
    showMenu && setShowMenu(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropdown((prev) => !prev);
  };
  const clickSub = (title, idCate, idSub, idMenu) => {
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (idSub !== 0) {
        history.push(`/category/${title}`, {
          idSub: idSub,
          idMenu: idMenu
        });
      }
      else {
        history.push('/category', idCate)
      }
    }

  }
  const clickMenu = (title, id, idMenu, url) => {
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (id === 0 && idMenu === 0) {
        history.push(`/${url}`)
      }
      else {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
          if (idMenu === 0) {
            history.push(`/tour/${title}`, id);
          }
          else {
            history.push(`/tour/${title}`, {
              idSub: id,
              idMenu: idMenu
            });
          }
        })
      }
    }

  }
  const clickService = (title, idCate, idSub, url) => {
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (url === 'service' && idCate === 0) {
        history.push(`/service`, idCate);
      }
      else {
        history.push(`/service`, idSub);
      }
    }

  }
  const clickServiceMenu = (title, idMenu, idSub) => {
   
    if (idSub === 1) {
      window.open(`/service/spa`);
    }
    if (idSub === 3) {
      window.open(`/service/vehicle`);
    }
    if (idSub === 4) {
      window.open(`/service/accommodation`);
    }
    if (idSub === 5) {
      window.open(`/service/bus`);
    }
    if (idSub === 6) {
      window.open(`/service/restaurant`);
    }
    // dựa vào IDSUb điều hướng đến component service tương ứng
    if (title === 'Customized Tours') {
      window.open(`/customtour`, "_blank");
    }
    else {
      if (idMenu === 0) {
        history.push(`/service`, idSub);
      }
      else {
        history.push(`/service/${idMenu}`);
        window.location.reload()
      }
    }
  }
  return (
    <li className="menu-items" onClick={closeDropdown}>
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            onClick={(e) => items.url === 'service' ? clickService(items.title, items.idCate, items.idSub, items.url) : clickSub(items.title, items.idCate, items.idSub, items.idMenu)}
            aria-expanded={dropdown ? "true" : "false"}>
            <Link onClick={(e) => items.url === 'service' ? clickService(items.title, items.idCate, items.idSub, items.url) : clickSub(items.title, items.idCate, items.idSub, items.idMenu)}>{items.title}</Link>
            <div onClick={(e) => toggleDropdown(e)}>
              {dropdown ? (
                <span className="arrow-close" />
              ) : (
                <span className="arrow" />
              )}
            </div>
          </button>
          <MobileDropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            onClick={(e) => toggleDropdown(e)}
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}>
            {items.title}{" "}
            <div onClick={(e) => toggleDropdown(e)}>
              {dropdown ? (
                <span className="arrow-close" />
              ) : (
                <span className="arrow" />
              )}
            </div>
          </button>
          <MobileDropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <Link onClick={(e) => items.url === 'service' ? clickServiceMenu(items.title, items.idMenu, items.idSub) : clickMenu(items.title, items.idSub, items.idMenu, items.url)}>{items.title}</Link>

      )}
    </li>
  );
};

export default MobileMenuItems;
