import { Form, Input, Row, Col, Button, Image, Carousel, Select } from "antd"
import axios from "axios";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './contact.css';
import swal from "sweetalert";
import moment from "moment";
import 'moment/locale/vi';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import countryMap from '../../common/countries/countries';
import Translate from "../../common/Lang/translate";

const { Option } = Select;
const Contact = (Language) => {
  
    const history = useHistory();
    const [success, setSuccess] = useState(0);
    const [form] = Form.useForm();
    const [loadding, setLoadding] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    
    const onFinish = (values) => {
        setLoadding(true);
        if (values.Name === undefined || values.Name === '') {
            setLoadding(false);
            form.resetFields();
            history.push('/', 0);
            window.location.reload();
        }
        else {
            axios.post('https://dgss.vn:7001/api/Customers/AddCustomers', {
                "ID": 1,
                "FullName": values.Name,
                "Nationality": values.Nationality,
                "Age": Number(values.Age),
                "Gender": values.Gender,
                "Birthday": "",
                "Email": values.Email,
                "Phone": values.Phone,
                "Whatapp": values.WhatsApp,
                "Address": ""
            }).then(res => {
                if (res.status === 200) {
                    swal('Submit success', {
                        icon: 'success',
                        timer: 3000
                    });
                    setLoadding(false);
                    form.resetFields();
                    history.push('/', 0)
                    window.location.reload();
                }
            })
        }
    }
    if (pathname.toLowerCase() === '/contact') {
        swal(Translate(Language, 'slogan_input_form'), {
            icon: 'warning',
            timer: 5000
        })
    }

    return (
        <div>
            <Row>
                <Col xl={24} xs={0} style={{ textAlign: 'center' }} span={24}>
                    <div>
                        <Carousel autoplay>
                            <div>
                                <Image width={'100%'} height={500} preview={false} src={'https://static.vinwonders.com/production/thanh-pho-da-lat-topbanner.jpg'} />
                            </div>
                        </Carousel>

                    </div>
                </Col>
                <Col xs={24} xl={0} style={{ marginTop: 50 }} span={24}>

                </Col>

                <Col span={24}>
                    <Row>
                        <Col span={4}>

                        </Col>
                        <Col xs={24} xl={16} span={16}>
                            <Row>
                                <Col xs={24} xl={24} span={24}>
                                    <div style={{ border: '2px solid #003D67 ', borderRadius: 20 }}>
                                        <Row>
                                            <Col style={{ textAlign: 'center' }} xs={24} xl={24} span={24}>
                                                <b className="text" style={{ fontSize: 25, wordBreak: 'break-word', textAlign: 'center' }}> <span className="text1" style={{ fontSize: 35, fontWeight: 'bold' }}></span> {Translate(Language, 'Guest_Information')} <br /> </b>
                                            </Col>
                                        </Row>
                                        <div>
                                            <br />
                                        </div>
                                        <Form
                                            style={{ textAlign: 'center' }}
                                            form={form}
                                            id="form-submit"
                                            name="basic"
                                            labelCol={{
                                                span: 0,

                                            }}
                                            wrapperCol={{
                                                span: 24,
                                                xs: 24,
                                                xl: 24
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            autoComplete="off"
                                        >
                                            <Form.Item

                                                name="Name"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: Translate(Language, 'message_input_name'),
                                                    },
                                                ]}
                                            >
                                                <Input style={{ width: '90%' }} placeholder={Translate(Language, 'Name')} />

                                            </Form.Item>

                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: Translate(Language, 'message_input_phone'),
                                                        pattern: new RegExp(/^[0-9]+$/),
                                                    },
                                                ]}

                                                name={"Phone"}
                                            >
                                                <Input style={{ width: '90%' }} placeholder={Translate(Language, 'Phone')} maxLength={11} />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: Translate(Language, 'message_input_email'),
                                                        type: "email",
                                                    },
                                                ]}

                                                name={"Email"}
                                            >

                                                <Input style={{ width: '90%' }} placeholder='Email' />
                                            </Form.Item>
                                            <Form.Item

                                                name="Age"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: Translate(Language, 'required_age'),
                                                    },
                                                ]}
                                            >
                                                <Input type="number" style={{ width: '90%' }} placeholder={Translate(Language, 'Age')} />

                                            </Form.Item>
                                            
                                            <Form.Item

                                                name="WhatsApp"

                                            >
                                                <Input style={{ width: '90%' }} placeholder={'WhatsApp'} />
                                            </Form.Item>
                                            <Form.Item

                                                name="Gender"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: Translate(Language, 'message_input_gender'),
                                                    },
                                                ]}
                                            >
                                                <Select style={{ width: '90%', color: 'linear-gradient( rgb(10,155,234), rgb(0,61,103))' }} defaultValue={Translate(Language, 'Gender')}>
                                                    <Option value='men'>{Translate(Language, 'Men')}</Option>
                                                    <Option value='women'>{Translate(Language, 'Women')}</Option>
                                                    <Option value='order'>{Translate(Language, 'order_gender')}</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                
                                                name="Nationality"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: Translate(Language, 'message_input_nationality'),
                                                    },
                                                ]}
                                            >
                                                <Select defaultValue={'VN'} style={{ width: '90%' }} showSearch >
                                                    {countryMap.map(r => (
                                                        <Select.Option value={r.code}>{r.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                wrapperCol={{
                                                    xs: 24,
                                                    span: 14,
                                                }}
                                            >
                                                <Button form="form-submit" loading={loadding} className="buttonRegister" htmlType="submit" style={{ background: 'linear-gradient( rgb(10,155,234), rgb(0,61,103))', borderRadius: 15, height: 40, width: 120, border: '2px solid #003D67' }}><b style={{ color: 'white' }}>{Translate(Language, 'Submit')}</b></Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}
export default Contact