import SlideImage from "../SlideImage/slideimage";
import Translate from "../../common/Lang/translate";
import { Col, Tabs, Row, Card, Table, Divider, Form, Button, Skeleton, Modal, Input, Select, Image, Tag, DatePicker, Upload, AutoComplete, Radio, Carousel, List, InputNumber } from "antd";
import { useEffect, useState } from "react";
import {
    EditOutlined, PlusOutlined, CloseCircleOutlined, CheckOutlined, CheckCircleOutlined, SettingOutlined, FormOutlined, DeleteOutlined
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import axios from "axios";
import swal from "sweetalert";
import "antd/dist/antd.css";
import 'antd/dist/antd.less'
import countryMap from '../../common/countries/countries';
import ButtonGroup from "antd/lib/button/button-group";
import { useSelector } from "react-redux";
import { formatLongDate } from "../../common/Format/index"
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { EditorState, convertToRaw, convertFromRaw, ContentState, SelectionState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ShowMoreText from "react-show-more-text";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const { Option } = Select;
const Manager = (Language) => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    // user properties
    // const ID = localStorage.getItem("currentID");
    const IDLogin = localStorage.getItem("currentID");
    const role = localStorage.getItem("Role");
    const username = localStorage.getItem("username");
    const fullname = localStorage.getItem("user");
    const password = localStorage.getItem("password");
    const phone = localStorage.getItem("phone");
    const email = localStorage.getItem("email");
    const image = localStorage.getItem("image");
    const active = localStorage.getItem("active");
    const database = localStorage.getItem("database");
    const securitycode = localStorage.getItem('securitycode');
    const expirationdate = localStorage.getItem('expirationDate');
    // ----------------------------------------------------------------
    const user = {
        "ID": localStorage.getItem('ID'),
        "Role": localStorage.getItem('Role'),
        "UserName": localStorage.getItem('UserName'),
        "FullName": localStorage.getItem('FullName'),
        "Password": localStorage.getItem('Password'),
        "Phone": localStorage.getItem('Phone'),
        "Email": localStorage.getItem('Email'),
        "Image": localStorage.getItem('Image'),
        "Active": localStorage.getItem('Active'),
        "DatabaseName": localStorage.getItem('DatabaseName'),
        "SecurityCode": localStorage.getItem('SecurityCode'),
        "ExpirationDate": localStorage.getItem('ExpirationDate')
    }
    //header
    const token = localStorage.getItem('token');
    const header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    // ---------------------------------------
    // gợi ý tìm kiếm
    const [searchTour, setSearchTour] = useState("");

    // form
    const [formExtraFooter] = Form.useForm();
    const [formShareAction] = Form.useForm();
    const [formEmail] = Form.useForm();
    const [formSubMenu] = Form.useForm();
    const [formFooter] = Form.useForm();
    const [formMenu] = Form.useForm();
    const [formCategory] = Form.useForm();
    const [formTour] = Form.useForm();
    const [formAccount] = Form.useForm();
    const [formFeedback] = Form.useForm();
    const [formBlog] = Form.useForm();
    const [formService] = Form.useForm();
    const [formServiceMenu] = Form.useForm();
    const [formCompany] = Form.useForm();
    const [formServiceDetail] = Form.useForm();
    const [formAbout] = Form.useForm();
    const [formBannerHome] = Form.useForm();
    const [formSaleBanner] = Form.useForm();
    const [formAttaction] = Form.useForm();
    // ----------------------------------------
    // Email
    const [dataEmail, setDataEmail] = useState([]);
    const [openModalEmail, setOpenModalEmail] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    // About us
    const [dataAbout, setDataAbout] = useState([]);
    const [openModalAbout, setOpenModalAbout] = useState(false);
    const [loadingAbout, setLoadingAbout] = useState(false);

    //  Attaction
    const [dataAttaction, setDataAttaction] = useState([]);
    const [openModalAttaction, setOpenModalAttaction] = useState(false);
    const [loadingAttaction, setLoadingAttaction] = useState(false);
    const [statusAttaction, setStatusAttaction] = useState(false);
    // Company
    const [dataCompany, setDataCompany] = useState([]);
    const [openModalCompany, setOpenModalCompany] = useState(false);
    const [loadingCompany, setLoadingCompany] = useState(false);
    const [statusCompany, setStatusCompany] = useState(false);
    //Service Detail
    const [dataServiceDetail, setDataServiceDetail] = useState([]);
    const [openModalServiceDetail, setOpenModalServiceDetail] = useState(false);
    const [loadingServiceDetail, setLoadingServiceDetail] = useState(false);
    const [statusServiceDetail, setStatusServiceDetail] = useState(false);
    //Customer
    const [dataCustomer, setDataCustomer] = useState([]);
    // BookTour
    const [dataBookTour, setDataBookTour] = useState([]);
    // SubMenu
    const [dataSubMenu, setDataSubMenu] = useState([]);
    const [openModalSubMenu, setOpenModalSubMenu] = useState(false);
    const [loadingSubMenu, setLoadingSubMenu] = useState(false);
    const [statusSubMenu, setStatusSubMenu] = useState(false);
    // SubMenu
    const [dataFooter, setDataFooter] = useState([]);
    const [openModalFooter, setOpenModalFooter] = useState(false);
    const [loadingFooter, setLoadingFooter] = useState(false);
    const [statusFooter, setStatusFooter] = useState(false);
    // Policies
    const [dataPolicies, setDataPolicies] = useState([]);
    const [openModalPolicies, setOpenModalPolicies] = useState(false);
    const [loadingPolicies, setLoadingPolicies] = useState(false);
    // Service Menu
    const [dataServiceMenu, setDataServiceMenu] = useState([]);
    const [openModalServiceMenu, setOpenModalServiceMenu] = useState(false);
    const [loadingServiceMenu, setLoadingServiceMenu] = useState(false);
    const [statusServiceMenu, setStatusServiceMenu] = useState(false);
    // Service
    const [dataService, setDataService] = useState([]);
    const [openModalService, setOpenModalService] = useState(false);
    const [loadingService, setLoadingService] = useState(false);
    const [statusService, setStatusService] = useState(false);
    // Menu
    const [dataMenu, setDataMenu] = useState([]);
    const [openModalMenu, setOpenModalMenu] = useState(false);
    const [loadingMenu, setLoadingMenu] = useState(false);
    const [statusMenu, setStatusMenu] = useState(false);
    // Category
    const [dataCategory, setDataCategory] = useState([]);
    const [openModalCategory, setOpenModalCategory] = useState(false);
    const [loadingCategory, setLoadingCategory] = useState(false);
    const [statusCategory, setStatusCategory] = useState(false);
    const [idCategoryCurrent, setIDCategoryCurrent] = useState(0);
    // Tour
    const [dataTour, setDataTour] = useState([]);
    const [openModalTour, setOpenModalTour] = useState(false);
    const [loadingTour, setLoadingTour] = useState(false);
    const [statusTour, setStatusTour] = useState(false);
    // Account
    const [dataAccount, setDataAccount] = useState([]);
    const [openModalAccount, setOpenModalAccount] = useState(false);
    const [loadingAccount, setLoadingAccount] = useState(false);
    const [statusAccount, setStatusAccount] = useState(false);
    const [idAccountCurrent, setIDAccountCurrent] = useState(0);
    // Blog
    const [dataBlog, setDataBlog] = useState([]);
    const [openModalBlog, setOpenModalBlog] = useState(false);
    const [loadingBlog, setLoadingBlog] = useState(false);
    const [statusBlog, setStatusBlog] = useState(false);

    // Feedback
    const [dataFeedback, setDataFeedback] = useState([]);

    // Banner Home
    const [dataBannerHome, setDataBannerHome] = useState([]);
    const [openModalBannerHome, setOpenModalBannerHome] = useState(false);
    const [loadingBannerHome, setLoadingBannerHome] = useState(false);
    const [statusBannerHome, setStatusBannerHome] = useState(false);
    // SaleBanner 
    const [dataSaleBanner, setDataSaleBanner] = useState([]);
    const [openModalSaleBanner, setOpenModalSaleBanner] = useState(false);
    const [loadingSaleBanner, setLoadingSaleBanner] = useState(false);
    const [statusSaleBanner, setStatusSaleBanner] = useState(false);
    // ExtraFooter 
    const [dataExtraFooter, setDataExtraFooter] = useState([]);
    const [openModalExtraFooter, setOpenModalExtraFooter] = useState(false);
    const [loadingExtraFooter, setLoadingExtraFooter] = useState(false);
    // ShareAction 
    const [dataShareAction, setDataShareAction] = useState([]);
    const [openModalShareAction, setOpenModalShareAction] = useState(false);
    const [loadingShareAction, setLoadingShareAction] = useState(false);
    // upload image
    //const [dataHeader, setDataHeader] = useState([]);

    const [fileList, setFileList] = useState([]);
    const [fileListImageBanner, setFileListImageBanner] = useState([]);
    const [fileListImageHowToGet, setFileListImageHowToGet] = useState([]);
    const [fileListImageItFormed, setFileListImageItFormed] = useState([]);
    const [fileListImageWhy, setFileListImageWhy] = useState([]);
    const [fileListImageRecomentTour, setFileListImageRecomentTour] = useState([]);
    const [fileListBannerHome, setFileListBannerHome] = useState([]);
    //-----------------ServiceDetail----------------------
    const [fileListImageBannerServiceDetail, setFileListImageBannerServiceDetail] = useState([]);
    const [fileListImageHowToGetServiceDetail, setFileListImageHowToGetServiceDetail] = useState([]);
    const [fileListImageItFormedServiceDetail, setFileListImageItFormedServiceDetail] = useState([]);
    const [fileListImageWhyServiceDetail, setFileListImageWhyServiceDetail] = useState([]);
    const [fileListImageRecomentTourServiceDetail, setFileListImageRecomentTourServiceDetail] = useState([]);

    const [previewImage, setPreviewImage] = useState("");
    const [previewVisible, setPreviewVisible] = useState(false);
    // ------------------------------------------------
    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const cancelUpload = () => {
        setPreviewVisible(false);
    };
    const previewUpload = async (file) => {
        const resultUrl = `https://dgss.vn:8081/files/${file.name}`
        setPreviewImage(resultUrl);
        setPreviewVisible(true);
    };
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);

    };

    const LoadDataExtraFooter = () => {
        axios.post('https://dgss.vn:7001/api/ExtraFooter/GetExtraFooterAll', '').then(res => {
            if (res.status === 200) {
                setDataExtraFooter(res.data);
            }
        })
    }
    const LoadDataEmail = () => {
        axios.post('https://dgss.vn:7001/api/Email/GetEmailAll', '').then(res => {
            if (res.status === 200) {

                setDataEmail(res.data);
            }
        })
    }
    const LoadDataShareAction = () => {
        axios.post('https://dgss.vn:7001/api/ShareAction/GetShareActionAll', '').then(res => {
            if (res.status === 200) {
                setDataShareAction(res.data);
            }
        })
    }
    const changeUpload = async ({ fileList }) => {
        setFileList(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        if (openModalAbout === true) {
            fillurlAbout(resultURL)
        } else {
            fillurl(resultURL);
        }
    };
    const changeUploadBlog = async ({ fileList }) => {
        setFileList(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        fillurlBlog(resultURL)
    };
    const changeUploadSubMenuService = async ({ fileList }) => {
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        formService.setFieldsValue({
            Image: resultURL
        })
    };
    const changeUploadBannerHome = async ({ fileList }) => {
        setFileListBannerHome(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        fillurlBannerHome(resultURL)
    };
    const changeUploadSubmenu = async ({ fileList }) => {
        setFileList(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        fillurlSubmenu(resultURL)
    };
    const changeUploadMenu = async ({ fileList }) => {
        setFileList(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        fillurlMenu(resultURL)
    };
    const changeUploadCategory = async ({ fileList }) => {
        setFileList(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        fillurlCategory(resultURL)
    };
    const fillurl = (arrayurl) => {
        // chèn link url hình ảnh
        formTour.setFieldsValue({
            Image: arrayurl
        })
    };
    const fillurlAbout = (arrayurl) => {
        // chèn link url hình ảnh
        formAbout.setFieldsValue({
            Image: arrayurl
        })
    };
    const fillurlSubmenu = (arrayurl) => {
        // chèn link url hình ảnh
        formSubMenu.setFieldsValue({
            Image: arrayurl
        })
    };
    const fillurlMenu = (arrayurl) => {
        // chèn link url hình ảnh
        formMenu.setFieldsValue({
            Image: arrayurl
        })
    };
    const fillurlCategory = (arrayurl) => {
        // chèn link url hình ảnh
        formCategory.setFieldsValue({
            Image: arrayurl
        })
    };
    const fillurlBlog = (arrayurl) => {
        // chèn link url hình ảnh
        formBlog.setFieldsValue({
            Image: arrayurl
        })
    };
    const fillurlBannerHome = (arrayurl) => {
        // chèn link url hình ảnh
        formBannerHome.setFieldsValue({
            Image: arrayurl
        })
    };
    const LoadDataBannerHome = () => {
        axios.post('https://dgss.vn:7001/api/BannerHome/GetBannerHomeAll', '', {
            headers: header
        }).then(res => {
            setDataBannerHome(res.data);
        })
    }
    const LoadDataSaleBanner = () => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/GetSaleBannerAll', '', {
            headers: header
        }).then(res => {
            setDataSaleBanner(res.data);
        })
    }

    const changeUploadImageHowToGet = async ({ fileList }) => {
        setFileListImageHowToGet(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageHowToGet(dataImage)
    };
    const fillImageHowToGet = (arrayurl) => {
        // chèn link url hình ảnh
        formAttaction.setFieldsValue({
            ImageHowToGet: arrayurl
        })
    };
    const changeUploadImageBanner = async ({ fileList }) => {
        setFileListImageBanner(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageBanner(dataImage)
    };
    const fillImageBanner = (arrayurl) => {
        // chèn link url hình ảnh
        formAttaction.setFieldsValue({
            ImageBanner: arrayurl
        })
    };

    const changeUploadImageItFormed = async ({ fileList }) => {
        setFileListImageItFormed(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageItFormed(dataImage)
    };
    const fillImageItFormed = (arrayurl) => {
        // chèn link url hình ảnh
        formAttaction.setFieldsValue({
            ImageItFormed: arrayurl
        })
    };

    const changeUploadImageWhy = async ({ fileList }) => {
        setFileListImageWhy(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageWhy(dataImage)
    };
    const fillImageWhy = (arrayurl) => {
        // chèn link url hình ảnh
        formAttaction.setFieldsValue({
            ImageWhy: arrayurl
        })
    };

    const changeUploadImageRecomentTour = async ({ fileList }) => {
        setFileListImageRecomentTour(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageRecomentTour(dataImage)
    };
    const fillImageRecomentTour = (arrayurl) => {
        // chèn link url hình ảnh
        formAttaction.setFieldsValue({
            ImageRecomentTour: arrayurl
        })
    };

    // ------------------- ServiceDetail ----------------
    const changeUploadImageHowToGetServiceDetail = async ({ fileList }) => {
        setFileListImageHowToGetServiceDetail(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageHowToGetServiceDetail(dataImage)
    };
    const fillImageHowToGetServiceDetail = (arrayurl) => {
        // chèn link url hình ảnh
        formServiceDetail.setFieldsValue({
            ImageHowToGet: arrayurl[0]
        })
    };
    const changeUploadImageBannerServiceDetail = async ({ fileList }) => {
        setFileListImageBannerServiceDetail(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageBannerServiceDetail(dataImage)
    };
    const fillImageBannerServiceDetail = (arrayurl) => {
        // chèn link url hình ảnh
        formServiceDetail.setFieldsValue({
            ImageBanner: arrayurl[0]
        })
    };

    const changeUploadImageItFormedServiceDetail = async ({ fileList }) => {
        setFileListImageItFormedServiceDetail(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageItFormedServiceDetail(dataImage)
    };
    const fillImageItFormedServiceDetail = (arrayurl) => {
        // chèn link url hình ảnh
        formServiceDetail.setFieldsValue({
            ImageItformed: arrayurl[0]
        })
    };

    const changeUploadImageWhyServiceDetail = async ({ fileList }) => {
        setFileListImageWhyServiceDetail(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageWhyServiceDetail(dataImage)
    };
    const fillImageWhyServiceDetail = (arrayurl) => {
        // chèn link url hình ảnh
        formServiceDetail.setFieldsValue({
            ImageWhy: arrayurl[0]
        })
    };

    const changeUploadImageRecomentTourServiceDetail = async ({ fileList }) => {
        setFileListImageRecomentTourServiceDetail(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        fillImageRecomentTourServiceDetail(dataImage)
    };
    const fillImageRecomentTourServiceDetail = (arrayurl) => {
        // chèn link url hình ảnh
        formServiceDetail.setFieldsValue({
            ImageRecomentTour: arrayurl[0]
        })
    };
    const LoadDataAttaction = () => {
        axios.post('https://dgss.vn:7001/api/Attactions/GetAttactionsAll', '', {
            headers: header
        }).then(res => {
            setDataAttaction(res.data);
        })
    }
    const LoadDataServiceDetail = () => {
        axios.post('https://dgss.vn:7001/api/ServiceDetail/GetServiceDetailAll', '', {
            headers: header
        }).then(res => {
            setDataServiceDetail(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1));
        })
    }
    const LoadDataBookTour = () => {
        axios.post('https://dgss.vn:7001/api/BookTour/GetBookTourAll', '', {
            headers: header
        }).then(res => {

            setDataBookTour(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataCustomer = () => {
        axios.post('https://dgss.vn:7001/api/Customers/GetCustomersAll', '', {
            headers: header
        }).then(res => {
            if (res.status === 200) {
                setDataCustomer(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1));
            }
        })
    }
    const LoadDataAccount = () => {
        axios.post('https://dgss.vn:7001/api/Accounts/GetAccountsAll', user, {
            headers: header
        }).then(res => {
            setDataAccount(res.data)
        })
    }
    const LoadDataFeedback = () => {
        axios.post('https://dgss.vn:7001/api/FeedBack/GetFeedBackAll', '', {
            headers: header
        }).then(res => {
            setDataFeedback(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataFooter = () => {
        axios.post('https://dgss.vn:7001/api/Footer/GetFooterAll', '', {
            headers: header
        }).then(res => {
            setDataFooter(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataService = () => {
        axios.post('https://dgss.vn:7001/api/ServiceSubMenu/GetServiceSubMenuAll', '', {
            headers: header
        }).then(res => {
            setDataService(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataServiceMenu = () => {
        axios.post('https://dgss.vn:7001/api/ServiceMenu/GetServiceMenuAll', '', {
            headers: header
        }).then(res => {

            setDataServiceMenu(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataSubMenu = () => {
        axios.post('https://dgss.vn:7001/api/SubMenu/GetSubMenuAll', '', {
            headers: header
        }).then(res => {
            setDataSubMenu(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const dataImage = [
        {
            slogan: Translate(Language, 'Manager'),
            src: 'https://langgo.edu.vn/storage/upload/default/images/bai-viet/ielts-grammar-phan-biet-travel-trip-journey-tour-voyage-excursion-expedition-passage.jpg',
        },
    ]
    const LoadDataBlog = () => {
        axios.post('https://dgss.vn:7001/api/Blog/GetBlogAll', '', {
            headers: header
        }).then(res => {
            setDataBlog(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '', {
            headers: header
        }).then(res => {
            setDataCategory(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const checkLogin = () => {

        if (isLoggedIn === false) {
            return <Redirect to={"/"} />;
        }
    }
    const LoadDataTour = () => {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '', {
            headers: header
        }).then(res => {
            setDataTour(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataCompany = () => {
        axios.post('https://dgss.vn:7001/api/Company/GetCompanyAll', '', {
            headers: header
        }).then(res => {
            setDataCompany(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    useEffect(() => {
        if (isLoggedIn) {
            LoadDataServiceMenu();
            LoadDataEmail();
            LoadDataExtraFooter();
            LoadDataAttaction();
            LoadDataSaleBanner();
            LoadDataBannerHome();
            LoadDataAbout()
            checkLogin();
            LoadDataFooter();
            LoadDataCategory();
            LoadDataTour();
            LoadDataAccount();
            LoadDataFeedback();
            LoadDataBlog();
            LoadDataSubMenu();
            LoadDataMenu();
            LoadDataService();
            LoadDataBookTour();
            LoadDataCustomer();
            LoadDataServiceDetail();
            LoadDataPolicies();
            LoadDataCompany();
            LoadDataShareAction();
        }
    }, [])
    const onDeleteAccount = (id) => {
        axios.post('https://dgss.vn:7001/api/Accounts/DeleteAccounts', {
            ID: id,
            Role: role,
            UserName: username,
            FullName: fullname,
            Password: password,
            Phone: phone,
            Email: email,
            Image: image,
            Active: active,
            DatabaseName: database,
            SecurityCode: securitycode,
            ExpirationDate: expirationdate
        }, {
            headers: header
        }).then(res => {
            if (res.status === 200) {
                swal('Xóa thành công')
            }
        })
    }
    const LoadDataAbout = () => {
        axios.post('https://dgss.vn:7001/api/AboutUs/GetAboutUsAll', '', { headers: header }).then(res => {
            if (res.status === 200) {
                setDataAbout(res.data)
            }
        })
    }
    const onDeleteTour = (record) => {
        if (role !== 'admin') {
            swal("Bạn không có quyền truy cập chức năng này!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Bạn có chắc muốn xóa không?",
                text: "Sau khi xóa, bạn sẽ không thể khôi phục dữ liệu này!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/Tour/DeleteTour/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success')
                        }
                        LoadDataTour();
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteService = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/ServiceSubMenu/DeleteServiceSubMenu/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataService();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteServiceMenu = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/ServiceMenu/DeleteServiceMenu/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataServiceMenu();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteAttaction = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/Attactions/DeleteAttactions/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataAttaction();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteServiceDetail = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/ServiceDetail/DeleteServiceDetail/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataServiceDetail();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteSubMenu = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/SubMenu/DeleteSubMenu/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataSubMenu();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteBlog = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/Blog/DeleteBlog/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataBlog();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onFinishAccount = (values) => {
        // add
        if (idCategoryCurrent === 0) {
            axios.post('https://dgss.vn:7001/api/Accounts/AddAccounts', {
                "ID": 1,
                "Role": "sample string 2",
                "UserName": "sample string 3",
                "FullName": "sample string 4",
                "Password": "sample string 5",
                "Phone": "sample string 6",
                "Email": "sample string 7",
                "Image": "sample string 8",
                "Active": true,
                "DatabaseName": "sample string 10",
                "SecurityCode": "sample string 11",
                "ExpirationDate": "2023-06-23T11:05:01.585014+07:00"
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Thêm mới thành công')
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/Accounts/AddAccounts', {
                "ID": idCategoryCurrent,
                "Role": "sample string 2",
                "UserName": "sample string 3",
                "FullName": "sample string 4",
                "Password": "sample string 5",
                "Phone": "sample string 6",
                "Email": "sample string 7",
                "Image": "sample string 8",
                "Active": true,
                "DatabaseName": "sample string 10",
                "SecurityCode": "sample string 11",
                "ExpirationDate": "2023-06-23T11:05:01.585014+07:00"
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Cập nhật thành công')
                }
            })
        }
    }
    const onFinishFilterBlog = (values) => {

    }
    const renderAccount = () => {
        return (
            <Form
                id="form-Account"
                form={formAccount}
                onFinish={onFinishAccount}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item name={'UserName'} label='Username'>
                    <Input />
                </Form.Item>
                <Form.Item name={'FullName'} label='Fullname'>
                    <Input />
                </Form.Item>
                <Form.Item name={'Password'} label='Password'>
                    <Input />
                </Form.Item>
                <Form.Item name={'Phone'} label='Phone'>
                    <Input />
                </Form.Item>
                <Form.Item name={'Email'} label='Email'>
                    <Input />
                </Form.Item>
                <Form.Item name={'Image'} label='Image'>
                    <Input />
                </Form.Item>
                <Form.Item name={'DatabaseName'} label='Database name'>
                    <Input />
                </Form.Item>
                <Form.Item name={'SecurityCode'} label='Security code'>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Choose Expiration Date",
                    },
                ]} name={'ExpirationDate'} label='Expiration date'>
                    <DatePicker disabledDate={(current) => {
                        let customDate = moment().format("DD-MM-YYYY");
                        return current && current < moment(customDate, "DD-MM-YYYY");
                    }} locale={locale} format={'DD-MM-YYYY HH:mm'} showTime={{ format: 'HH:mm' }} />
                </Form.Item>
                <Form.Item name={'Role'} label='Role'>
                    <Select defaultValue={'admin'}>
                        <Option value='admin'>Admin</Option>
                        <Option value='manager'>Manager</Option>
                        <Option value='user'>User</Option>
                    </Select>
                </Form.Item>
            </Form>
        )
    }
    const fillDataAccount = (data) => {
        // chèn dữ liệu vào formAccount khi update
        formAccount.setFieldsValue(
            {
                "ID": data[0].ID,
                "Role": data[0].Role,
                "UserName": data[0].UserName,
                "FullName": data[0].FullName,
                "Password": data[0].Password,
                "Phone": data[0].Phone,
                "Email": data[0].Email,
                "Image": data[0].Image,
                "Active": data[0].Active,
                "DatabaseName": data[0].DatabaseName,
                "SecurityCode": data[0].SecurityCode,
                "ExpirationDate": data[0].ExpirationDate
            }
        );
    };

    const showModalAccount = (record, data) => {
        //const IDTemp = 0;
        setLoadingAccount(false)
        resetForm();
        if (record === 0) {
            // thêm mới
            setStatusAccount(false);
            setOpenModalAccount(true);
        }
        else {
            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    axios.post(`https://dgss.vn:7001/api/Accounts/GetAccountsByID/${record}`, '', {
                        headers: header
                    }).then(res => {
                        fillDataAccount(res.data);

                    })
                }
                setStatusAccount(true)
                setOpenModalAccount(true);
            }

            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }

        }
        // update
    }
    const fillDataSubMenu = (id) => {
        const data = dataSubMenu.filter(i => i.ID === id);
        formSubMenu.setFieldsValue(
            {
                ID: data[0].ID,
                Link: data[0].Link,
                Image: data[0].Image,
                TitleVI: String(data[0].Title).split(';')[0],
                TitleEN: String(data[0].Title).split(';')[1],
                Status: data[0].Status === true ? 1 : 0,
                IDCategory: data[0].IDCategory,
                OrderView: data[0].OrderView
            }
        );
    }
    const fillDataMenu = (id) => {
        const data = dataMenu.filter(i => i.ID === id);
        formMenu.setFieldsValue(
            {
                ID: data[0].ID,
                Link: data[0].Link,
                Image: data[0].Image,
                TitleVI: String(data[0].Title).split(';')[0],
                TitleEN: String(data[0].Title).split(';')[1],
                Status: data[0].Status === true ? 1 : 0,
                IDSubMenu: data[0].IDSubMenu,
                OrderView: data[0].OrderView
            }
        );
    }

    const fillDataBannerHome = (id) => {
        const data = dataBannerHome.filter(i => i.ID === id);
        const arrImageTour = String(data[0].Image).split(';');

        fillArrayImageBanner(arrImageTour)
        formBannerHome.setFieldsValue(
            {
                ID: data[0].ID,
                SloganVI: String(data[0].Slogan).split(';')[0],
                SloganEN: String(data[0].Slogan).split(';')[1],
                DescriptionVI: String(data[0].Description).split(';')[0],
                DescriptionEN: String(data[0].Description).split(';')[1],
                Image: data[0].Image,
                Button1: String(data[0].Button1).split(';')[0],
                Button2: String(data[0].Button2).split(';')[0],
                Action1: Number(String(data[0].Button1).split(';')[1]),
                Action2: Number(String(data[0].Button2).split(';')[1])
            }
        );
    }
    const fillDataSaleBanner = (id) => {
        const data = dataSaleBanner.filter(i => i.ID === id);
        formSaleBanner.setFieldsValue(
            {
                ID: data[0].ID,
                SloganVI: String(data[0].Slogan).split(';')[0],
                SloganEN: String(data[0].Slogan).split(';')[1],

                Title1VI: String(data[0].Title1).split(';')[0],
                Title1EN: String(data[0].Title1).split(';')[1],

                Title2VI: String(data[0].Title2).split(';')[0],
                Title2EN: String(data[0].Title2).split(';')[1],

                Title3VI: String(data[0].Title3).split(';')[0],
                Title3EN: String(data[0].Title3).split(';')[1],

                Title4VI: String(data[0].Title4).split(';')[0],
                Title4EN: String(data[0].Title4).split(';')[1],

                Title5VI: String(data[0].Title5).split(';')[0],
                Title5EN: String(data[0].Title5).split(';')[1],
            }
        );
    }
    const fillDataExtraFooter = (id) => {
        const data = dataExtraFooter.filter(i => i.ID === id);
        formExtraFooter.setFieldsValue(
            {
                ID: data[0].ID,
                "Phone": data[0].Phone,
                "Hotline": data[0].Hotline,
                "Email": data[0].Email,
                "Messenger": data[0].Messenger,
                "Zalo": data[0].Zalo,
                "Whatapp": data[0].Whatapp,
                "Telegram": data[0].Telegram,
                "Ktalk": data[0].Ktalk
            }
        );
    }
    const fillDataShareAction = (id) => {
        const data = dataShareAction.filter(i => i.ID === id);
        formShareAction.setFieldsValue(
            {
                ID: data[0].ID,
                "Pinterest": data[0].Pinterest,
                "Instagram": data[0].Instagram,
                "Twitter": data[0].Twitter,
                "Facebook": data[0].Facebook,
            }
        );
    }
    const fillDataEmail = (id) => {
        const data = dataEmail.filter(i => i.ID === id);
        setDataEditorEmailContent({
            editorState: htmlToDraftBlocks(data[0].content)
        })
        formEmail.setFieldsValue(
            {
                "ID": data[0].ID,
                "toEmail": data[0].toEmail,
                "subject": data[0].subject,

                "alert": data[0].alert
            }
        );
    }
    const fillDataService = (id) => {
        const data = dataService.filter(i => i.ID === id);
        formService.setFieldsValue(
            {
                ID: data[0].ID,
                TitleEN: String(data[0].Title).split(';')[0],
                TitleVN: String(data[0].Title).split(';')[1],
                Description: data[0].Description,
                Image: data[0].Image
            }
        );
    }
    const fillDataServiceMenu = (id) => {
        const data = dataServiceMenu.filter(i => i.ID === id);
        formServiceMenu.setFieldsValue(
            {
                ID: data[0].ID,
                TitleEN: String(data[0].Title).split(';')[0],
                TitleVN: String(data[0].Title).split(';')[1],
                IDServiceSubMenu: data[0].IDServiceSubMenu,
                Description: data[0].Description,
            }
        );
    }
    const showModalSubMenu = (record, data) => {
        //const IDTemp = 0;
        setLoadingSubMenu(false)
        formSubMenu.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusSubMenu(false);
            setOpenModalSubMenu(true);
        }
        else {

            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataSubMenu(record);
                    setStatusSubMenu(true)
                    setOpenModalSubMenu(true);
                }
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalService = (record) => {
        //const IDTemp = 0;
        setLoadingService(false)
        formService.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusService(false);
            setOpenModalService(true);
        }
        else {

            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataService(record);
                    setStatusService(true)
                    setOpenModalService(true);
                }

            }

            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }

        }
    }
    const showModalServiceMenu = (record) => {
        //const IDTemp = 0;
        setLoadingServiceMenu(false)
        formServiceMenu.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusServiceMenu(false);
            setOpenModalServiceMenu(true);
        }
        else {
            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataServiceMenu(record);
                    setStatusServiceMenu(true)
                    setOpenModalServiceMenu(true);
                }

            }

            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }

        }
    }

    const showModalMenu = (record, data) => {
        //const IDTemp = 0;
        setLoadingMenu(false)
        formMenu.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusMenu(false);
            setOpenModalMenu(true);
        }
        else {
            // cập nhật
            if (role === 'admin' || role === 'manager') {

                fillDataMenu(record)
                setStatusMenu(true)
                setOpenModalMenu(true);
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }

        }
        // update
    }
    const showModalBannerHome = (record, data) => {
        //const IDTemp = 0;


        setLoadingBannerHome(false)
        formBannerHome.resetFields();
        if (record !== 0) {
            // cập nhật
            if (role === 'admin' || role === 'manager') {
                setFileListBannerHome([]);
                fillDataBannerHome(record)
                setStatusBannerHome(true)
                setOpenModalBannerHome(true);
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalSaleBanner = (record, data) => {
        setLoadingSaleBanner(false)
        formSaleBanner.resetFields();
        if (record !== 0) {
            // cập nhật
            if (role === 'admin' || role === 'manager') {

                fillDataSaleBanner(record)
                setStatusSaleBanner(true)
                setOpenModalSaleBanner(true);
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalExtraFooter = (record, data) => {
        setLoadingExtraFooter(false)
        formExtraFooter.resetFields();
        if (record !== 0) {
            // cập nhật
            if (role === 'admin' || role === 'manager') {

                fillDataExtraFooter(record)
                setOpenModalExtraFooter(true);
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalShareAction = (record, data) => {
        setLoadingShareAction(false)
        formShareAction.resetFields();
        if (record !== 0) {
            // cập nhật
            if (role === 'admin' || role === 'manager') {

                fillDataShareAction(record)
                setOpenModalShareAction(true);
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalEmail = (record, data) => {
        setLoadingEmail(false)
        formEmail.resetFields();
        if (record !== 0) {
            // cập nhật
            if (role === 'admin' || role === 'manager') {

                fillDataEmail(record)
                setOpenModalEmail(true);
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const onFinishCategory = (values) => {
        // add
        if (statusCategory === false) {
            axios.post('https://dgss.vn:7001/api/CategoryTour/AddCategoryTour', {
                ID: values.ID === undefined ? 1 : 0,
                Title: values.TitleVI + ';' + values.TitleEN,
                Description: values.Description,
                Image: values.Image,
                Link: values.Link,
                Button1: values.Button1,
                Button2: values.Button2,
                IDUser: IDLogin,
                OrderView: values.OrderView === undefined ? 1 : values.OrderView,
                CoverImage: values.CoverImage,
                Status: values.Status === undefined ? 1 : values.Status
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Add new success');
                    LoadDataCategory();
                    setOpenModalCategory(false);
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/CategoryTour/UpdateCategoryTour', {
                ID: values.ID,
                Title: values.TitleVI + ';' + values.TitleEN,
                Description: values.Description,
                Image: values.Image,
                Link: values.Link,
                Button1: values.Button1,
                Button2: values.Button2,
                IDUser: IDLogin,
                CoverImage: values.CoverImage,
                OrderView: values.OrderView,
                Status: values.Status
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Update success');
                    LoadDataCategory();
                    setOpenModalCategory(false);
                }
            })
        }


    }
    const renderCategory = () => {
        return (
            <Form
                id="form-Category"
                form={formCategory}
                onFinish={onFinishCategory}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleVI'} label={'Title VI'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleEN'} label={'Title EN'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Description' name={'Description'}>
                    <Input.TextArea />
                </Form.Item>

                <Form.Item hidden label='Link' name={'Link'}>
                    <Input />
                </Form.Item>
                <Form.Item hidden label='Title Button 1' name={'Button1'}>
                    <Input />
                </Form.Item>
                <Form.Item hidden label='Title Button 2' name={'Button2'}>
                    <Input />
                </Form.Item>
                <Form.Item hidden label='Cover Image' name={'CoverImage'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Order View' name={'OrderView'}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item label='Status' name={'Status'}>
                    <Select defaultValue={'Choose Status'}>
                        <Option value={1}>Active</Option>
                        <Option value={0}>Deactive</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={previewImage}
                        onChange={changeUploadCategory}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item name={'Image'} label='Link Image' >
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const onFinishAbout = (values) => {
        axios.post('https://dgss.vn:7001/api/AboutUs/UpdateAboutUs', {
            "ID": values.ID,
            "WhoWeAre": draftToHtml(convertToRaw(dataEditorWWA.editorState.getCurrentContent())),
            "CoreValues": draftToHtml(convertToRaw(dataEditorCore.editorState.getCurrentContent())),
            "Video": values.Video + ';' + values.DescribeVideo,
            "Image": values.Image
        }, { headers: header }).then(res => {
            if (res.status === 200) {
                swal('Update success', {
                    icon: 'success',
                    timer: 3000
                })
                setOpenModalAbout(false)
                LoadDataAbout();
                setFileList([]);
            }
        })
        setFileList([]);
    }
    const onFinishAttaction = (values) => {
        if (statusAttaction === false) {
            axios.post('https://dgss.vn:7001/api/Attactions/AddAttactions', {
                "ID": 1,
                "VideoSrc": values.VideoSrc,
                "LocationMap": values.LocationMap,
                "ImageBanner": values.ImageBanner,
                "ImageHowToGet": values.ImageHowToGet,
                "HOWTOGET": draftToHtml(convertToRaw(dataEditorHOWTOGET.editorState.getCurrentContent())),
                "ImageItFormed": values.ImageItFormed,
                "howitfomed": draftToHtml(convertToRaw(dataEditorHOWITFORMED.editorState.getCurrentContent())),
                "ImageWhy": values.ImageWhy,
                "WHY": draftToHtml(convertToRaw(dataEditorWHY.editorState.getCurrentContent())),
                "Title": values.Title,
                "ImageRecomentTour": values.ImageRecomentTour,
                "RecomentTour": draftToHtml(convertToRaw(dataEditorRecomentTour.editorState.getCurrentContent())),
                "Slogan": values.Slogan,
                "IDCategory": values.IDCategory,
                "IDSubMenu": values.IDSubMenu,
                "IDMenu": values.IDMenu
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Add new success');
                    LoadDataAttaction();
                    setOpenModalAttaction(false);
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/Attactions/UpdateAttactions', {
                "ID": values.ID,
                "VideoSrc": values.VideoSrc,
                "LocationMap": values.LocationMap,
                "ImageBanner": values.ImageBanner,
                "ImageHowToGet": values.ImageHowToGet,
                "HOWTOGET": draftToHtml(convertToRaw(dataEditorHOWTOGET.editorState.getCurrentContent())),
                "ImageItFormed": values.ImageItFormed,
                "howitfomed": draftToHtml(convertToRaw(dataEditorHOWITFORMED.editorState.getCurrentContent())),
                "ImageWhy": values.ImageWhy,
                "WHY": draftToHtml(convertToRaw(dataEditorWHY.editorState.getCurrentContent())),
                "Title": values.Title,
                "ImageRecomentTour": values.ImageRecomentTour,
                "RecomentTour": draftToHtml(convertToRaw(dataEditorRecomentTour.editorState.getCurrentContent())),
                "Slogan": values.Slogan,
                "IDCategory": values.IDCategory,
                "IDSubMenu": values.IDSubMenu,
                "IDMenu": values.IDMenu
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Update success');
                    LoadDataAttaction();
                    setOpenModalAttaction(false);
                }
            })
        }
        setFileList([])
        setFileListImageBanner([]);
        setFileListImageHowToGet([]);
        setFileListImageItFormed([]);
        setFileListImageRecomentTour([]);
        setFileListImageWhy([]);

    }
    const onFinishServiceDetail = (values) => {
        if (statusServiceDetail === false) {
            axios.post('https://dgss.vn:7001/api/ServiceDetail/AddServiceDetail', {
                "ID": 1,
                "VideoSrc": values.VideoSrc,
                "ImageBanner": values.ImageBanner,
                "ImageHowToGet": values.ImageHowToGet,
                "HOWTOGET": draftToHtml(convertToRaw(dataEditorHOWTOGETServiceDetail.editorState.getCurrentContent())),
                "ImageItformed": values.ImageItformed,
                "HOWITFORMED": draftToHtml(convertToRaw(dataEditorHOWITFORMEDServiceDetail.editorState.getCurrentContent())),
                "ImageWhy": values.ImageWhy,
                "WHY": draftToHtml(convertToRaw(dataEditorWHYServiceDetail.editorState.getCurrentContent())),
                "Title": values.Title,
                "ImageRecomentTour": values.ImageRecomentTour,
                "RecomentTour": draftToHtml(convertToRaw(dataEditorRecomentTourServiceDetail.editorState.getCurrentContent())),
                "Slogan": values.Slogan,
                "IDService": values.IDService,

            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Add new success');
                    LoadDataServiceDetail();
                    setOpenModalServiceDetail(false);
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/ServiceDetail/UpdateServiceDetail', {
                "ID": values.ID,
                "VideoSrc": values.VideoSrc,
                "ImageBanner": values.ImageBanner,
                "ImageHowToGet": values.ImageHowToGet,
                "HOWTOGET": draftToHtml(convertToRaw(dataEditorHOWTOGETServiceDetail.editorState.getCurrentContent())),
                "ImageItformed": values.ImageItformed,
                "HOWITFORMED": draftToHtml(convertToRaw(dataEditorHOWITFORMEDServiceDetail.editorState.getCurrentContent())),
                "ImageWhy": values.ImageWhy,
                "WHY": draftToHtml(convertToRaw(dataEditorWHYServiceDetail.editorState.getCurrentContent())),
                "Title": values.Title,
                "ImageRecomentTour": values.ImageRecomentTour,
                "RecomentTour": draftToHtml(convertToRaw(dataEditorRecomentTourServiceDetail.editorState.getCurrentContent())),
                "Slogan": values.Slogan,
                "IDService": values.IDService,
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Update success');
                    LoadDataServiceDetail();
                    setOpenModalServiceDetail(false);
                }
            })
        }
        setFileList([])
        setFileListImageBannerServiceDetail([]);
        setFileListImageHowToGetServiceDetail([]);
        setFileListImageItFormedServiceDetail([]);
        setFileListImageRecomentTourServiceDetail([]);
        setFileListImageWhyServiceDetail([]);
    }
    const renderAbout = () => {
        return (
            <Form
                id="form-About"
                form={formAbout}
                onFinish={onFinishAbout}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item label='Link Video' name={'Video'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Describe Video' name={'DescribeVideo'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Who We Are' name={'WhoWeAre'}>
                    <Editor editorState={dataEditorWWA.editorState} onEditorStateChange={onEditorStateWWA} />
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={previewUpload}
                        onChange={changeUpload}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item label='Link Image' name={'Image'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label='Core Values' name={'CoreValues'}>
                    <Editor editorState={dataEditorCore.editorState} onEditorStateChange={onEditorStateCore} />
                </Form.Item>
            </Form>
        )
    }
    const renderServiceDetail = () => {
        return (
            <Form
                id="form-ServiceDetail"
                form={formServiceDetail}
                onFinish={onFinishServiceDetail}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title",
                    },
                ]} label='Title' name={'Title'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan",
                    },
                ]} label='Slogan' name={'Slogan'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Link Video' name={'VideoSrc'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Upload Image Banner' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileListImageBannerServiceDetail}
                        onPreview={previewUpload}
                        onChange={changeUploadImageBannerServiceDetail}
                        maxCount={1}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item label='Image Banner' name={'ImageBanner'}>
                    <Input />
                </Form.Item>
                <Card title={'IDService'}>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Choose Service",
                        },
                    ]} label='Service' name={'IDService'}>
                        <Select defaultValue={'Choose Service'}>
                            {
                                dataServiceMenu.map((i, index) => (
                                    <Option value={i.ID}>
                                        {
                                            Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                        }
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>

                </Card>
                <Card title='How to get'>
                    <Form.Item label='Upload Image HowToGet' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageHowToGetServiceDetail}
                            onPreview={previewUpload}
                            onChange={changeUploadImageHowToGetServiceDetail}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image HowtoGet' name={'ImageHowToGet'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='HOW TO GET' name={'HOWTOGET'}>
                        <Editor editorState={dataEditorHOWTOGETServiceDetail.editorState} onEditorStateChange={onEditorStateHOWTOGETServiceDetail} />
                    </Form.Item>
                </Card>
                <Card title='It formed'>
                    <Form.Item label='Upload Image ItFormed' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageItFormedServiceDetail}
                            onPreview={previewUpload}
                            onChange={changeUploadImageItFormedServiceDetail}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image It Formed' name={'ImageItformed'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='HOW IT FORMED' name={'howitfomed'}>
                        <Editor editorState={dataEditorHOWITFORMEDServiceDetail.editorState} onEditorStateChange={onEditorStateHOWITFORMEDServiceDetail} />
                    </Form.Item>
                </Card>
                <Card title='Why'>
                    <Form.Item label='Upload Image Why' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageWhyServiceDetail}
                            onPreview={previewUpload}
                            onChange={changeUploadImageWhyServiceDetail}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image Why' name={'ImageWhy'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='WHY' name={'WHY'}>
                        <Editor editorState={dataEditorWHYServiceDetail.editorState} onEditorStateChange={onEditorStateWHYServiceDetail} />
                    </Form.Item>
                </Card>

                <Card title='Recoment Tour'>
                    <Form.Item label='Upload Image Recoment Tour' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageRecomentTourServiceDetail}
                            onPreview={previewUpload}
                            onChange={changeUploadImageRecomentTourServiceDetail}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image Recoment Tour' name={'ImageRecomentTour'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Recoment Tour' name={'RecomentTour'}>
                        <Editor editorState={dataEditorRecomentTourServiceDetail.editorState} onEditorStateChange={onEditorStateRecomentTourServiceDetail} />
                    </Form.Item>
                </Card>
            </Form>
        )
    }
    const renderAttaction = () => {
        return (
            <Form
                id="form-Attaction"
                form={formAttaction}
                onFinish={onFinishAttaction}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title",
                    },
                ]} label='Title' name={'Title'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan",
                    },
                ]} label='Slogan' name={'Slogan'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Link Video' name={'VideoSrc'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Link Map' name={'LocationMap'}>
                    <Input />
                </Form.Item>

                <Form.Item label='Upload Image Banner' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileListImageBanner}
                        onPreview={previewUpload}
                        onChange={changeUploadImageBanner}
                        maxCount={1}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item label='Image Banner' name={'ImageBanner'}>
                    <Input />
                </Form.Item>
                <Card title={'Categories, submenu and menu'}>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Choose Category",
                        },
                    ]} label='Category' name={'IDCategory'}>
                        <Select onChange={onChangeCategory} defaultValue={'Choose Category'}>
                            {
                                dataCategory.map((i, index) => (
                                    <Option key={i.ID} value={i.ID}>
                                        {
                                            Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                        }
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Choose SubMenu",
                        },
                    ]} label='SubMenu' name={'IDSubMenu'}>
                        <Select onChange={onChangeSubmenu} defaultValue={'Choose SubMenu'}>
                            <Option value={0}>Default</Option>
                            {
                                dataSelectCategory.map((i, index) => (

                                    <Option key={i.ID} value={i.ID}>
                                        {
                                            Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                        }
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Menu' name={'IDMenu'}>
                        <Select defaultValue={'Choose Menu'}>
                            <Option value={0}>
                                Default
                            </Option>
                            {
                                dataSelectSubmenu.map((i, index) => (
                                    <Option key={i.ID} value={i.ID}>
                                        {
                                            Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                        }
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Card>
                <Card title='How to get'>
                    <Form.Item label='Upload Image HowToGet' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageHowToGet}
                            onPreview={previewUpload}
                            onChange={changeUploadImageHowToGet}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image HowtoGet' name={'ImageHowToGet'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='HOW TO GET' name={'HOWTOGET'}>
                        <Editor editorState={dataEditorHOWTOGET.editorState} onEditorStateChange={onEditorStateHOWTOGET} />
                    </Form.Item>
                </Card>
                <Card title='It formed'>
                    <Form.Item label='Upload Image ItFormed' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageItFormed}
                            onPreview={previewUpload}
                            onChange={changeUploadImageItFormed}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image It Formed' name={'ImageItFormed'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='HOW IT FORMED' name={'howitfomed'}>
                        <Editor editorState={dataEditorHOWITFORMED.editorState} onEditorStateChange={onEditorStateHOWITFORMED} />
                    </Form.Item>
                </Card>
                <Card title='Why'>
                    <Form.Item label='Upload Image Why' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageWhy}
                            onPreview={previewUpload}
                            onChange={changeUploadImageWhy}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image Why' name={'ImageWhy'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='WHY' name={'WHY'}>
                        <Editor editorState={dataEditorWHY.editorState} onEditorStateChange={onEditorStateWHY} />
                    </Form.Item>
                </Card>

                <Card title='Recoment Tour'>
                    <Form.Item label='Upload Image Recoment Tour' >
                        <Upload
                            action="https://dgss.vn:8081/upload"
                            listType="picture-card"
                            fileList={fileListImageRecomentTour}
                            onPreview={previewUpload}
                            onChange={changeUploadImageRecomentTour}
                            maxCount={1}
                        >
                            <div>Choose file Upload</div>
                            <Modal footer={null} onCancel={cancelUpload}>
                                <Image preview={false} src={previewImage} />
                            </Modal>
                        </Upload>
                    </Form.Item>
                    <Form.Item label='Image Recoment Tour' name={'ImageRecomentTour'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Recoment Tour' name={'RecomentTour'}>
                        <Editor editorState={dataEditorRecomentTour.editorState} onEditorStateChange={onEditorStateRecomentTour} />
                    </Form.Item>
                </Card>
            </Form>
        )
    }
    const onFinishBannerHome = (values) => {
        axios.post('https://dgss.vn:7001/api/BannerHome/UpdateBannerHome', {
            "ID": values.ID,
            "Image": values.Image,
            "Slogan": values.SloganVI + ';' + values.SloganEN,
            "Description": values.DescriptionVI + ';' + values.DescriptionEN,
            "Button1": values.Button1 + ';' + values.Action1,
            "Button2": values.Button2 + ';' + values.Action2
        }, { headers: header }).then(res => {
            if (res.status === 200) {
                swal('Update success', {
                    icon: 'success',
                    timer: 3000
                });
                LoadDataBannerHome();
                setOpenModalBannerHome(false);
                setFileListBannerHome([]);

            }
        })
    }
    const renderBannerHome = () => {
        return (
            <Form
                id="form-BannerHome"
                form={formBannerHome}
                onFinish={onFinishBannerHome}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan VI",
                    },
                ]} label='SloganVI' name={'SloganVI'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan EN",
                    },
                ]} label='SloganEN' name={'SloganEN'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Description VI",
                    },
                ]} label='DescriptionVI' name={'DescriptionVI'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Description EN",
                    },
                ]} label='DescriptionEN' name={'DescriptionEN'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileListBannerHome}
                        onPreview={handlePreview}
                        onChange={changeUploadBannerHome}
                        onDownload={null}
                        beforeUpload={() => false}
                    >
                        <div>Choose file Upload</div>
                        <Modal visible={previewVisible} footer={null} onCancel={cancelUpload}>
                            <Image.PreviewGroup>
                                <img style={{ width: '100%' }} src={previewImage} />
                            </Image.PreviewGroup>

                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item label='Link Image' name={'Image'}>
                    <Input.TextArea />
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Card title='Button 1'>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Button 1",
                                },
                            ]} label='Button 1' name={'Button1'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Choose one Action 1",
                                },
                            ]} label='Action 1' name={'Action1'}>
                                <Select placeholder='Select action button 1'>
                                    {dataCategory.map(r => (
                                        <Option value={r.ID}>{Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1]}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title='Button 2'>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Button 2",
                                },
                            ]} label='Button 2' name={'Button2'}>
                                <Input />
                            </Form.Item>

                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Choose one Action 2",
                                },
                            ]} label='Action 2' name={'Action2'}>
                                <Select placeholder='Select action button 2'>
                                    {dataCategory.map(r => (
                                        <Option value={r.ID}>{Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1]}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>



            </Form>
        )
    }
    const onFinishBlog = (values) => {
        if (statusBlog === false) {
            axios.post('https://dgss.vn:7001/api/Blog/AddBlog', {
                "ID": 1,
                "Content": draftToHtml(convertToRaw(dataEditorBlog.editorState.getCurrentContent())),
                "Image": values.Image,
                "Title": values.TitleVI + ';' + values.TitleEN,
                "Slogan": values.Slogan,
                "DayofWriting": moment(),
                "About": values.About,
                "Writer": values.Writer,
                "IDPosts": 0,
                "IDUser": Number(IDLogin),
                "CoverImage": "",
                "Status": values.Status === undefined ? true : values.Status,
                "TopTitle": "",
                "TitleSale": values.TitleSale,
                "Map": values.Map
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Add Success', {
                        icon: 'success',
                        timer: 3000
                    })
                    setOpenModalBlog(false)
                    LoadDataBlog();
                }
            })
        }
        else {
            axios.post('https://dgss.vn:7001/api/Blog/UpdateBlog', {
                "ID": values.ID,
                "Content": draftToHtml(convertToRaw(dataEditorBlog.editorState.getCurrentContent())),
                "Image": values.Image,
                "Title": values.TitleVI + ';' + values.TitleEN,
                "Slogan": values.Slogan,
                "DayofWriting": moment(),
                "About": values.About,
                "Writer": values.Writer,
                "IDPosts": 0,
                "IDUser": Number(IDLogin),
                "CoverImage": "",
                "Status": values.Status === undefined ? true : values.Status,
                "TopTitle": "",
                "TitleSale": values.TitleSale,
                "Map": values.Map
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Update Success', {
                        icon: 'success',
                        timer: 3000
                    })
                    setOpenModalBlog(false)
                    LoadDataBlog();
                }
            })
        }
    }
    const renderBlog = () => {
        return (
            <Form
                id="form-Blog"
                form={formBlog}
                onFinish={onFinishBlog}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Your Name",
                    },
                ]} name={'Writer'} label={'Writer'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title Sale",
                    },
                ]} name={'TitleSale'} label={'Title Sale'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleVI'} label={'Title VI'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleEN'} label={'Title EN'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan",
                    },
                ]} name={'Slogan'} label={'Slogan'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input About",
                    },
                ]} name={'About'} label={'About'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: false,
                        message: "Input Map",
                    },
                ]} name={'Map'} label={'Link Map'}>
                    <Input />
                </Form.Item>

                <Form.Item label='Status' name={'Status'}>
                    <Select defaultValue={'Choose Status'}>
                        <Option value={true}>Active</Option>
                        <Option value={false}>Deactive</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={previewImage}
                        onChange={changeUploadBlog}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item name={'Image'} label='Link Image' >
                    <Input />
                </Form.Item>
                <Form.Item label='Content' name={'Content'}>
                    <Editor editorState={dataEditorBlog.editorState} onEditorStateChange={onEditorStateChangeBlog} />
                </Form.Item>
            </Form>
        )
    }
    const onFinishSaleBanner = (values) => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/UpdateSaleBanner', {
            "ID": values.ID,
            "Slogan": values.SloganVI + ';' + values.SloganEN,
            "Title1": values.Title1VI + ';' + values.Title1EN,
            "Title2": values.Title2VI + ';' + values.Title2EN,
            "Title3": values.Title3VI + ';' + values.Title3EN,
            "Title4": values.Title4VI + ';' + values.Title4EN,
            "Title5": values.Title5VI + ';' + values.Title5EN
        }, { headers: header }).then(res => {
            if (res.status === 200) {
                swal('Update success', {
                    icon: 'success',
                    timer: 3000
                });
                LoadDataSaleBanner();
                setOpenModalSaleBanner(false);
            }
        })
    }
    const renderSaleBanner = () => {
        return (
            <Form
                id="form-SaleBanner"
                form={formSaleBanner}
                onFinish={onFinishSaleBanner}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan VI",
                    },
                ]} name={'SloganVI'} label={'SloganVI'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Slogan EN",
                    },
                ]} name={'SloganEN'} label={'SloganEN'}>
                    <Input />
                </Form.Item>
                <Form.Item name={'Title1VI'} label={'Title 1 VI'}>
                    <Input />
                </Form.Item>
                <Form.Item name={'Title1EN'} label={'Title 1 EN'}>
                    <Input />
                </Form.Item>

                <Form.Item name={'Title2VI'} label={'Title 2 VI'}>
                    <Input />
                </Form.Item>
                <Form.Item name={'Title2EN'} label={'Title 2 EN'}>
                    <Input />
                </Form.Item>

                <Form.Item name={'Title3VI'} label={'Title 3 VI'}>
                    <Input />
                </Form.Item>
                <Form.Item name={'Title3EN'} label={'Title 3 EN'}>
                    <Input />
                </Form.Item>

                <Form.Item name={'Title4VI'} label={'Title 4 VI'}>
                    <Input />
                </Form.Item>
                <Form.Item name={'Title4EN'} label={'Title 4 EN'}>
                    <Input />
                </Form.Item>

                <Form.Item name={'Title5VI'} label={'Title 5 VI'}>
                    <Input />
                </Form.Item>
                <Form.Item name={'Title5EN'} label={'Title 5 EN'}>
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const onFinishExtraFooter = (values) => {
        axios.post('https://dgss.vn:7001/api/ExtraFooter/UpdateExtraFooter', values, { headers: header }).then(res => {
            if (res.status === 200) {
                swal('Update success', {
                    icon: 'success',
                    timer: 3000
                });
                LoadDataExtraFooter();
                setOpenModalExtraFooter(false);
                formExtraFooter.resetFields()
            }
        })
    }
    const renderExtraFooter = () => {
        return (
            <Form
                id="form-ExtraFooter"
                form={formExtraFooter}
                onFinish={onFinishExtraFooter}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Phone",
                    },
                ]} name={'Phone'} label={'Phone'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Hotline",
                    },
                ]} name={'Hotline'} label={'Hot line'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Email",
                    },
                ]} name={'Email'} label={'Email'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Messenger",
                    },
                ]} name={'Messenger'} label={'Messenger'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Zalo",
                    },
                ]} name={'Zalo'} label={'Zalo'}>
                    <Input />
                </Form.Item>

                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Whatapp",
                    },
                ]} name={'Whatapp'} label={'Whatapp'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Telegram",
                    },
                ]} name={'Telegram'} label={'Telegram'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Ktalk",
                    },
                ]} name={'Ktalk'} label={'Ktalk'}>
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const onFinishShareAction = (values) => {
        axios.post('https://dgss.vn:7001/api/ShareAction/UpdateShareAction', values, { headers: header }).then(res => {
            if (res.status === 200) {
                swal('Update success', {
                    icon: 'success',
                    timer: 3000
                });
                LoadDataShareAction();
                setOpenModalShareAction(false);
                formShareAction.resetFields()
            }
        })
    }
    const renderShareAction = () => {
        return (
            <Form
                id="form-ShareAction"
                form={formShareAction}
                onFinish={onFinishShareAction}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Pinterest",
                    },
                ]} name={'Pinterest'} label={'Pinterest'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Instagram",
                    },
                ]} name={'Instagram'} label={'Instagram'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Twitter",
                    },
                ]} name={'Twitter'} label={'Twitter'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Facebook",
                    },
                ]} name={'Facebook'} label={'Facebook'}>
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const onFinishEmail = (values) => {
        axios.post('https://dgss.vn:7001/api/Email/UpdateEmail', {
            "ID": values.ID,
            "toEmail": values.toEmail,
            "subject": values.subject,
            "content": draftToHtml(convertToRaw(dataEditorEmailContent.editorState.getCurrentContent())),
            "alert": values.alert
        }, { headers: header }).then(res => {
            if (res.status === 200) {
                swal('Update success', {
                    icon: 'success',
                    timer: 3000
                });
                LoadDataEmail();
                setOpenModalEmail(false);
                formEmail.resetFields()
            }
        })
    }
    const renderEmail = () => {
        return (
            <Form
                id="form-EmailInfo"
                form={formEmail}
                onFinish={onFinishEmail}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Card title={'Subject & Alert'}>
                    <Form.Item hidden name={'ID'} >
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name={'toEmail'} label={'From'}>
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Input Subject Email",
                        },
                    ]} name={'subject'} label={'Subject'}>
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Input Alert Send Success",
                        },
                    ]} name={'alert'} label={'Alert Send Success'}>
                        <Input />
                    </Form.Item>
                </Card>
                <Card title='Email Content'>
                    <Row>
                        <Col span={24}>
                            <div style={{ wordWrap: 'break-word' }}>Insert <span style={{ color: 'blue' }}>{'{nameCustomer}'}</span> to show name customer</div>
                        </Col>
                        <Col span={24}>
                            <div style={{ wordWrap: 'break-word' }}>Insert <span style={{ color: 'blue' }}>{'{price}'}</span> to show price tour</div>
                        </Col>
                        <Col span={24}>
                            <div style={{ wordWrap: 'break-word' }}>Insert <span style={{ color: 'blue' }}>{'{phone}'}</span> to show phone customer </div>
                        </Col>
                        <Col span={24}>
                            <div style={{ wordWrap: 'break-word' }}>Insert <span style={{ color: 'blue' }}>{'{dayBookTour}'}</span> to show day book tour</div>
                        </Col>

                        <Col span={24}>
                            <Divider type="horizontal" />
                        </Col>
                        <Col span={24}>
                            <Editor editorState={dataEditorEmailContent.editorState} onEditorStateChange={onEditorStateEmailContent} />
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }
    const onFinishCompany = (values) => {
        if (statusCompany === false) {
            axios.post('https://dgss.vn:7001/api/Company/AddCompany', values, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Add new success');
                    LoadDataCompany();
                    setOpenModalCompany(false);
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/Company/UpdateCompany', values, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Update success');
                    LoadDataCompany();
                    setOpenModalCompany(false);
                }
            })
        }
    }
    const renderCompany = () => {
        return (
            <Form
                id="form-Company"
                form={formCompany}
                onFinish={onFinishCompany}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Card title='Information Company'>
                    <Form.Item hidden name={'ID'} >
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Input Company Name",
                        },
                    ]} name={'CompanyName'} label={'Company Name'}>
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Input Company Address",
                        },
                    ]} name={'Address'} label={'Company Address'}>
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Input Email Company",
                        },
                    ]} name={'Email'} label={'Email'}>
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Input Company Phone',
                            pattern: new RegExp(/^[0-9]+$/),
                        },
                    ]} name={'Phone'} label={'Phone'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='TaxCode' name={'TaxCode'}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label='Content Footer' name={'LegalRepresentative'}>
                        <Input.TextArea />
                    </Form.Item>
                </Card>
                <Card title={'Send Email'}>
                    <Form.Item rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Input Email App",
                        },
                    ]} name={'AppEmail'} label={'App Mail'}>
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: "Input Pass App Email",
                        },
                    ]} name={'PassAppEmail'} label={'Password Mail'}>
                        <Input.Password />
                    </Form.Item>
                </Card>
            </Form>
        )
    }
    const onFinishFooter = (values) => {
        if (statusFooter === false) {
            axios.post('https://dgss.vn:7001/api/Footer/AddFooter', values, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Add new success');
                    LoadDataFooter();
                    setOpenModalFooter(false);
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/Footer/UpdateFooter', values, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Update success');
                    LoadDataFooter();
                    setOpenModalFooter(false);
                }
            })
        }
    }
    const renderFooter = () => {
        return (
            <Form
                id="form-Footer"
                form={formFooter}
                onFinish={onFinishFooter}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Link Facebook",
                    },
                ]} name={'Facebook'} label={'Facebook'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Link Youtube",
                    },
                ]} name={'Youtube'} label={'Youtube'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Link Instagram",
                    },
                ]} name={'Instagram'} label={'Instagram'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Link Tripadvisor",
                    },
                ]} name={'Tripadvisor'} label={'Tripadvisor'}>
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const LoadDataMenu = () => {
        axios.post('https://dgss.vn:7001/api/Menu/GetMenuAll', '', { headers: header }).then(res => {
            if (res.status === 200) {
                setDataMenu(res.data);
            }
        })
    }
    const onFinishMenu = (values) => {
        if (statusMenu === false) {
            axios.post('https://dgss.vn:7001/api/Menu/AddMenu', {
                ID: 1,
                Link: '',
                Image: values.Image,
                Title: values.TitleVI + ';' + values.TitleEN,
                Status: values.Status === undefined ? 1 : values.Status,
                IDSubMenu: values.IDSubMenu,
                OrderView: values.OrderView === undefined ? 1 : values.OrderView
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Add success', {
                        icon: 'success',
                        timer: 3000
                    });

                    LoadDataMenu();
                    setOpenModalMenu(false);

                }
            })
        }
        else {
            axios.post('https://dgss.vn:7001/api/Menu/UpdateMenu', {
                ID: values.ID,
                Link: values.Link,
                Image: values.Image,
                Title: values.TitleVI + ';' + values.TitleEN,
                Status: values.Status === undefined ? 1 : values.Status,
                IDSubMenu: values.IDSubMenu,
                OrderView: values.OrderView
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Update success', {
                        icon: 'success',
                        timer: 3000
                    });
                    LoadDataMenu();
                    setOpenModalMenu(false);
                }
            })
        }
    }
    const onFinishSubMenu = (values) => {
        if (statusSubMenu === false) {
            axios.post('https://dgss.vn:7001/api/SubMenu/AddSubMenu', {
                ID: 1,
                Link: '',
                Image: values.Image,
                Title: values.TitleVI + ';' + values.TitleEN,
                Status: values.Status === undefined ? 1 : values.Status,
                IDCategory: values.IDCategory,
                OrderView: values.OrderView === undefined ? 1 : values.OrderView
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Add success', {
                        icon: 'success',
                        timer: 3000
                    });

                    LoadDataSubMenu();
                    setOpenModalSubMenu(false);

                }
            })
        }
        else {
            axios.post('https://dgss.vn:7001/api/SubMenu/UpdateSubMenu', {
                ID: values.ID,
                Link: values.Link,
                Image: values.Image,
                Title: values.TitleVI + ';' + values.TitleEN,
                Status: values.Status === undefined ? 1 : values.Status,
                IDCategory: values.IDCategory,
                OrderView: values.OrderView
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Update success', {
                        icon: 'success',
                        timer: 3000
                    });
                    LoadDataSubMenu();
                    setOpenModalSubMenu(false);
                }
            })
        }
    }

    const renderSubMenu = () => {
        return (
            <Form
                id="form-Submenu"
                form={formSubMenu}
                onFinish={onFinishSubMenu}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleVI'} label={'Title VI'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleEN'} label={'Title EN'}>
                    <Input />
                </Form.Item>

                <Form.Item rules={[
                    {
                        required: true,
                        message: "Choose Category",
                    },
                ]} label='Category' name={'IDCategory'}>
                    <Select defaultValue={'Choose Category'}>
                        {
                            dataCategory.map((i, index) => (
                                <Option key={i.ID} value={i.ID}>
                                    {
                                        Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                    }
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label='Order View' name={'OrderView'}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item label='Status' name={'Status'}>
                    <Select defaultValue={'Choose Status'}>
                        <Option value={1}>Active</Option>
                        <Option value={0}>Deactive</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={previewImage}
                        onChange={changeUploadSubmenu}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item name={'Image'} label='Link Image' >
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const renderMenu = () => {
        return (
            <Form

                id="form-menu"
                form={formMenu}
                onFinish={onFinishMenu}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleVI'} label={'Title VI'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleEN'} label={'Title EN'}>
                    <Input />
                </Form.Item>


                <Form.Item rules={[
                    {
                        required: true,
                        message: "Choose Submenu",
                    },
                ]} label='SubMenu' name={'IDSubMenu'}>
                    <Select defaultValue={'Choose SubMenu'}>

                        {
                            dataSubMenu.map((i, index) => (
                                <Option key={i.ID} value={i.ID}>
                                    {
                                        Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                    }
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label='Order View' name={'OrderView'}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item label='Status' name={'Status'}>
                    <Select defaultValue={'Choose Status'}>
                        <Option value={1}>Active</Option>
                        <Option value={0}>Deactive</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={previewImage}
                        onChange={changeUploadMenu}
                    >
                        <div>Choose file Upload</div>
                        <Modal footer={null} onCancel={cancelUpload}>
                            <Image preview={false} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item name={'Image'} label='Link Image' >
                    <Input />
                </Form.Item>
            </Form>
        )
    }
    const getServiceDetail = (IDBook) => {
        var dataDetail = dataServiceDetail.filter(i => i.IDBookTour === IDBook).map(r => r.IDService);

        const temp = dataDetail.map(r => (
            {
                id: r,
                title: dataService.filter(i => i.ID === r).map(n => n.Title),
                price: dataService.filter(i => i.ID === r).map(n => n.Price)
            }
        ))
        return (

            <div>
                {temp.map(item => (
                    <Row>
                        <Col span={16}>
                            {item.title}
                        </Col>
                        <Col span={8}>
                            {item.price} $
                        </Col>
                        <Divider />
                    </Row>
                ))}

            </div>
        )
    }
    const onFinishService = (values) => {
        if (statusService === false) {
            axios.post('https://dgss.vn:7001/api/ServiceSubMenu/AddServiceSubMenu', {
                "ID": 1,
                "Title": values.TitleEN + ';' + values.TitleVN,
                "Image": values.Image,
                "Description": values.Description
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Add success', {
                        icon: 'success',
                        timer: 3000
                    });
                    LoadDataService();
                    setOpenModalService(false);
                }
            })
        }
        else {
            axios.post('https://dgss.vn:7001/api/ServiceSubMenu/UpdateServiceSubMenu', {
                "ID": values.ID,
                "Title": values.TitleEN + ';' + values.TitleVN,
                "Image": values.Image,
                "Description": values.Description
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Update success', {
                        icon: 'success',
                        timer: 3000
                    });
                    LoadDataService();
                    setOpenModalService(false);
                }
            })
        }
    }
    const renderService = () => {
        return (
            <Form
                id="form-Service"
                form={formService}
                onFinish={onFinishService}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>

                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleEN'} label={'Title EN'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title VN",
                    },
                ]} name={'TitleVN'} label={'Title VN'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Description' name={'Description'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label='Image' >
                    <Upload
                        action="https://dgss.vn:8081/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={previewUpload}
                        onChange={changeUploadSubMenuService}
                    >
                        <div>Choose file Upload</div>
                        <Modal visible={previewVisible} footer={null} onCancel={cancelUpload}>
                            <img style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Upload>
                </Form.Item>
                <Form.Item label='Link Image' name={'Image'}>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        )
    }
    const onFinishServiceMenu = (values) => {

        if (statusServiceMenu === false) {
            axios.post('https://dgss.vn:7001/api/ServiceMenu/AddServiceMenu', {
                "ID": 1,
                "Title": values.TitleEN + ';' + values.TitleVN,
                "IDServiceSubMenu": values.IDServiceSubMenu,
                "Image": "",
                "Description": values.Description
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Add success', {
                        icon: 'success',
                        timer: 3000
                    });
                    LoadDataServiceMenu();
                    setOpenModalServiceMenu(false);
                }
            })
        }
        else {
            axios.post('https://dgss.vn:7001/api/ServiceMenu/UpdateServiceMenu', {
                "ID": values.ID,
                "Title": values.TitleEN + ';' + values.TitleVN,
                "IDServiceSubMenu": values.IDServiceSubMenu,
                "Image": "",
                "Description": values.Description
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    swal('Update success', {
                        icon: 'success',
                        timer: 3000
                    });
                    LoadDataServiceMenu();
                    setOpenModalServiceMenu(false);
                }
            })
        }
    }
    const renderServiceMenu = () => {
        return (
            <Form
                id="form-ServiceMenu"
                form={formServiceMenu}
                onFinish={onFinishServiceMenu}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item hidden name={'ID'} >
                    <Input />
                </Form.Item>
                <Form.Item label='Service SubMenu' name={'IDServiceSubMenu'}>
                    <Select>
                        {dataService.map(r => (
                            <Option value={r.ID}>{Language === 'en' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1]}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title EN",
                    },
                ]} name={'TitleEN'} label={'Title EN'}>
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: "Input Title VN",
                    },
                ]} name={'TitleVN'} label={'Title VN'}>
                    <Input />
                </Form.Item>
                <Form.Item label='Description' name={'Description'}>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        )
    }
    const onEditorStateHOWTOGETServiceDetail = (editor) => {
        setDataEditorHOWTOGETServiceDetail({ editorState: editor })
    };
    const [dataEditorHOWTOGETServiceDetail, setDataEditorHOWTOGETServiceDetail] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateWHYServiceDetail = (editor) => {
        setDataEditorWHYServiceDetail({ editorState: editor })
    };
    const [dataEditorWHYServiceDetail, setDataEditorWHYServiceDetail] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateHOWITFORMEDServiceDetail = (editor) => {
        setDataEditorHOWITFORMEDServiceDetail({ editorState: editor })
    };
    const [dataEditorHOWITFORMEDServiceDetail, setDataEditorHOWITFORMEDServiceDetail] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateRecomentTourServiceDetail = (editor) => {
        setDataEditorRecomentTourServiceDetail({ editorState: editor })
    };
    const [dataEditorRecomentTourServiceDetail, setDataEditorRecomentTourServiceDetail] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------------------------------------------------

    // -----------------------Editor About--------------------------
    const onEditorStateChange = (editor) => {
        setDataEditor({ editorState: editor })
    };
    const [dataEditor, setDataEditor] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    const onEditorStateWWA = (editor) => {
        setDataEditorWWA({ editorState: editor })
    };
    const [dataEditorWWA, setDataEditorWWA] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    const onEditorStateCore = (editor) => {
        setDataEditorCore({ editorState: editor })
    };
    const [dataEditorCore, setDataEditorCore] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    const onEditorStateChangeBlog = (editor) => {
        setDataEditorBlog({ editorState: editor })
    };
    const [dataEditorBlog, setDataEditorBlog] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something Blog'))
    })
    // --------------- data Editor Attaction ------------------
    const onEditorStateHOWTOGET = (editor) => {
        setDataEditorHOWTOGET({ editorState: editor })
    };
    const [dataEditorHOWTOGET, setDataEditorHOWTOGET] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateWHY = (editor) => {
        setDataEditorWHY({ editorState: editor })
    };
    const [dataEditorWHY, setDataEditorWHY] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateHOWITFORMED = (editor) => {
        setDataEditorHOWITFORMED({ editorState: editor })
    };
    const [dataEditorHOWITFORMED, setDataEditorHOWITFORMED] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateRecomentTour = (editor) => {
        setDataEditorRecomentTour({ editorState: editor })
    };
    const [dataEditorRecomentTour, setDataEditorRecomentTour] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------------------------------------------------
    const [dataEditorEmailContent, setDataEditorEmailContent] = useState({
        editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
    })
    // --------------
    const onEditorStateEmailContent = (editor) => {
        setDataEditorEmailContent({ editorState: editor })
    };
    const renderPolicies = () => {
        return (
            <div>
                <Editor editorState={dataEditor.editorState} onEditorStateChange={onEditorStateChange} />
            </div>
        )
    }
    const onFinishTour = (values) => {
        // add Tour
        if (values.ID === undefined || values.ID === 0) {
            axios.post('https://dgss.vn:7001/api/Tour/AddTour', {
                "ID": 1,
                "IDCategory": values.IDCategory,
                "IDSubMenu": values.IDMenu === undefined || values.IDMenu === 0 ? 0 : values.IDSubMenu,
                "IDMenu": values.IDMenu === undefined || values.IDMenu === 0 ? values.IDSubMenu : values.IDMenu,
                "TopTitle": values.TopTitle,
                "TitleSale": values.TitleSale,
                "TitleTour": values.TitleTour,
                "Title": values.TitleEN + ';' + values.TitleVI,
                "Availability": values.Availability,
                "Duration": values.Duration,
                "DepartsFrom": values.DepartsFrom,
                "Price": Number(values.Price),
                "Image": values.Image,
                "Rating": values.Rating,
                "Reviewer": values.Reviewer,
                "Itinerary": values.Itinerary,
                "SrcMap": values.SrcMap,
                "SrcVideo": values.SrcVideo,
                "RequiredParticipants": values.RequiredParticipants,
                "AboutTour": values.AboutTour,
                "Age": Number(values.Age),
                "Children": Number(values.Children),
                "Participants": Number(values.Participants),
                "Highlights": values.Highlights,
                "Included": values.Included,
                "Bring": values.Bring,
                "Difficulty": String(values.Difficulty),
                "Minimumage": Number(values.Minimumage),
                "Pickup": values.Pickup,
                "MeetonLocation": values.MeetonLocation,
                "Peradult": values.Peradult,
                "DateStart": moment(),
                "Services": '',
                "Status": values.Status === undefined ? 1 : values.Status,
                "OrderView": values.OrderView === undefined ? 1 : values.OrderView,
                "DateOfTravel": values.DateOfTravel === undefined ? '' : values.DateOfTravel
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Add new success!!!')
                    LoadDataTour();
                    setOpenModalTour(false);
                    setFileList([]);
                }
            })
        }
        else {
            // update
            axios.post('https://dgss.vn:7001/api/Tour/UpdateTour', {
                "ID": values.ID,
                "IDCategory": values.IDCategory,
                "IDSubMenu": values.IDMenu === undefined || values.IDMenu === 0 ? 0 : values.IDSubMenu,
                "IDMenu": values.IDMenu === undefined || values.IDMenu === 0 ? values.IDSubMenu : values.IDMenu,
                "TopTitle": values.TopTitle,
                "TitleSale": values.TitleSale,
                "TitleTour": values.TitleTour,
                "Title": values.TitleEN + ';' + values.TitleVI,
                "Availability": values.Availability,
                "Duration": values.Duration,
                "DepartsFrom": values.DepartsFrom,
                "Price": Number(values.Price),
                "Image": values.Image,
                "Rating": values.Rating,
                "Reviewer": values.Reviewer,
                "Itinerary": values.Itinerary,
                "SrcMap": values.SrcMap,
                "SrcVideo": values.SrcVideo,
                "RequiredParticipants": values.RequiredParticipants,
                "AboutTour": values.AboutTour,
                "Age": Number(values.Age),
                "Children": Number(values.Children),
                "Participants": Number(values.Participants),
                "Highlights": values.Highlights,
                "Included": values.Included,
                "Bring": values.Bring,
                "Difficulty": String(values.Difficulty),
                "Minimumage": Number(values.Minimumage),
                "Pickup": values.Pickup,
                "MeetonLocation": values.MeetonLocation,
                "Peradult": values.Peradult,
                "DateStart": moment(),
                "Services": '',
                "Status": values.Status === undefined ? 1 : values.Status,
                "OrderView": values.OrderView,
                "DateOfTravel": values.DateOfTravel === undefined ? '' : values.DateOfTravel
            }, {
                headers: header
            }).then(res => {
                if (res.status === 200) {
                    swal('Update success');
                    LoadDataTour();
                    setOpenModalTour(false);
                    setFileList([]);
                }
            })
        }
    }
    const [dataSelectCategory, setDataSelectCategory] = useState([]);
    const [dataSelectSubmenu, setDataSelectSubmenu] = useState([]);
    const onChangeCategory = (value) => {
        setDataSelectCategory(dataSubMenu.filter(i => i.IDCategory === Number(value)));
    }
    const onChangeSubmenu = (value) => {
        setDataSelectSubmenu(dataMenu.filter(i => i.IDSubMenu === Number(value)));
    }
    const renderTour = () => {
        return (
            <Form
                id="form-Tour"
                form={formTour}
                onFinish={onFinishTour}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >

                <Row>
                    <Col span={24}>
                        <Card title={'Tour Infomation'}>
                            <Form.Item hidden name={'ID'}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Top Title' name={'TopTitle'}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Title Sale' name={'TitleSale'}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Title Tour' name={'TitleTour'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Title EN",
                                },
                            ]} name={'TitleVI'} label={'Title VI'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Title EN",
                                },
                            ]} name={'TitleEN'} label={'Title EN'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Availability",
                                },
                            ]} label='Availability' name={'Availability'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Duration",
                                },
                            ]} label='Duration' name={'Duration'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Departs From",
                                },
                            ]} label='Departs From' name={'DepartsFrom'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input Price",
                                },
                            ]} label='Price' name={'Price'}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Itinerary' name={'Itinerary'}>
                                <Input.TextArea style={{minHeight:200}} />
                            </Form.Item>
                            <Form.Item label='Required Participants' name={'RequiredParticipants'}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Title Book Day' name={'DateOfTravel'}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: "Input About Tour",
                                },
                            ]} label='About Tour' name={'AboutTour'}>
                                <Input.TextArea style={{minHeight:200}} />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col xs={24} xl={12} span={12}>
                                <Row>
                                    <Col span={24}>
                                        <Card title={'Categories, statuses and priorities'}>
                                            <Form.Item label='Order View' name={'OrderView'}>
                                                <Input min={0} type="number" />
                                            </Form.Item>
                                            <Form.Item label='Status' name={'Status'}>
                                                <Select defaultValue={true}>
                                                    <Option value={true}>Active</Option>
                                                    <Option value={false}>Deactive</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Choose Category",
                                                },
                                            ]} label='Category' name={'IDCategory'}>
                                                <Select onChange={onChangeCategory} defaultValue={'Choose Category'}>
                                                    {
                                                        dataCategory.map((i, index) => (
                                                            <Option key={i.ID} value={i.ID}>
                                                                {
                                                                    Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                                                }
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item rules={[
                                                {
                                                    required: true,
                                                    message: "Choose SubMenu",
                                                },
                                            ]} label='SubMenu' name={'IDSubMenu'}>
                                                <Select onChange={onChangeSubmenu} defaultValue={'Choose SubMenu'}>
                                                    <Option value={0}>Default</Option>
                                                    {
                                                        dataSelectCategory.map((i, index) => (

                                                            <Option key={i.ID} value={i.ID}>
                                                                {
                                                                    Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                                                }
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label='Menu' name={'IDMenu'}>
                                                <Select defaultValue={'Choose Menu'}>
                                                    <Option value={0}>
                                                        Default
                                                    </Option>
                                                    {
                                                        dataSelectSubmenu.map((i, index) => (
                                                            <Option key={i.ID} value={i.ID}>
                                                                {
                                                                    Language === 'vi' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1]
                                                                }
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                    <Col xs={24} xl={24} span={24}>
                                        <Card title='Upload Image,Video and Map'>
                                            <Form.Item label='Image' >
                                                <Upload
                                                    action="https://dgss.vn:8081/upload"
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onPreview={previewUpload}
                                                    onChange={changeUpload}
                                                >
                                                    <div>Choose file Upload</div>
                                                    <Modal visible={previewVisible} footer={null} onCancel={cancelUpload}>
                                                        <img style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Upload>
                                            </Form.Item>
                                            <Form.Item hidden label='Link Image' name={'Image'}>
                                                <Input.TextArea />
                                            </Form.Item>
                                            <Form.Item label='MapUrl' name={'SrcMap'}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label='VideoUrl' name={'SrcVideo'}>
                                                <Input />
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={24} xl={12} span={12}>
                                <Card title={'Information required when participating'}>

                                    <Form.Item label='Age' name={'Age'}>
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item label='Children' name={'Children'}>
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item rules={[
                                        {
                                            required: true,
                                            message: "Input Minimumage",
                                        },
                                    ]} name={'Minimumage'} label='Minimumage'>
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item label='Participants' name={'Participants'}>
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item rules={[
                                        {
                                            required: true,
                                            message: "Input highlights",
                                        },
                                    ]} label='Highlights' name={'Highlights'}>
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item rules={[
                                        {
                                            required: true,
                                            message: "Input Included",
                                        },
                                    ]} label='Included' name={'Included'}>
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item rules={[
                                        {
                                            required: true,
                                            message: "Input Bring",
                                        },
                                    ]} label='Bring' name={'Bring'}>
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item rules={[
                                        {
                                            required: true,
                                            message: "Input Difficulty",
                                        },
                                    ]} label='Difficulty' name={'Difficulty'}>
                                        <Select placeholder='Choose a difficulty'>
                                            <Option value='Relaxing'>Relaxing</Option>
                                            <Option value='Easy'>Easy</Option>
                                            <Option value='Moderate'>Moderate</Option>
                                            <Option value='Extreme'>Extreme</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item rules={[
                                        {
                                            required: false,
                                            message: "Choose Pickup",
                                        },
                                    ]} label='Pickup' name={'Pickup'}>
                                        <Select defaultValue={true}>
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label='Peradult' name={'Peradult'}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden label='Services' name={'Services'}>
                                        <Input />
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
    const fillDataCategory = (id) => {
        const data = dataCategory.filter(i => i.ID === id);

        // chèn dữ liệu vào formCategory khi update
        // const dataButton1 = data[0].Button1.split(';')
        // const dataButton2 = data[0].Button2.split(';')
        formCategory.setFieldsValue(
            {
                "TitleVI": String(data[0].Title).split(';')[0],
                "TitleEN": String(data[0].Title).split(';')[1],
                "Description": data[0].Description,
                "Image": data[0].Image,
                "Link": data[0].Link,
                "Button1": data[0].Button1,
                "Button2": data[0].Button2,
                "IDUser": data[0].IDUser,
                "CoverImage": data[0].CoverImage,
                "Status": data[0].Status === true ? 1 : 0,
                "OrderView": data[0].OrderView,
                "ID": data[0].ID
            }
        );
    };
    const fillDataCompany = (id) => {
        const data = dataCompany.filter(i => i.ID === id);
        formCompany.setFieldsValue(
            {
                "CompanyName": data[0].CompanyName,
                "TaxCode": data[0].TaxCode,
                "Address": data[0].Address,
                "Phone": data[0].Phone,
                "Email": data[0].Email,
                "LegalRepresentative": data[0].LegalRepresentative,
                "ID": data[0].ID,
                "AppEmail": data[0].AppEmail,
                "PassAppEmail": data[0].PassAppEmail
            }
        );
    };
    const fillArrayImageAbout = (arr) => {
        const list = fileList
        arr.map(r => (
            toDataURL(r)
                .then(dataUrl => {
                    var fileData = dataURLtoFile(dataUrl, r.split('https://dgss.vn:8081/files/')[1]);
                    list.push({
                        lastModified: fileData.lastModified,
                        name: fileData.name,
                        percent: 0,
                        size: fileData.size,
                        type: "image/jpeg",
                        uid: `__AUTO__1694415727047_${list.length + 1}__`,
                        originFileObj: fileData
                    });

                })
        ))
        setFileList(list);
    }
    const showModalAbout = (id) => {
        const data = dataAbout.filter(i => i.ID === id);
        const arrImageAbout = String(data.map(r => r.Image)).split(';');
        fillArrayImageAbout(arrImageAbout)
        setDataEditorWWA({
            editorState: htmlToDraftBlocks(data[0].WhoWeAre)
        })
        setDataEditorCore({
            editorState: htmlToDraftBlocks(data[0].CoreValues)
        })
        formAbout.setFieldsValue(
            {
                "Image": data[0].Image,
                "Video": String(data[0].Video).split(';')[0],
                "ID": data[0].ID,
                "DescribeVideo": String(data[0].Video).split(';')[1]
            }
        );
        setOpenModalAbout(true);
    }
    const showModalServiceDetail = (record) => {
        //const IDTemp = 0;
        setDataEditorHOWTOGETServiceDetail({
            editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
        })
        setDataEditorHOWITFORMEDServiceDetail({
            editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
        })
        setDataEditorWHYServiceDetail({
            editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
        })
        setDataEditorRecomentTourServiceDetail({
            editorState: EditorState.createWithContent(ContentState.createFromText('Write something'))
        })
        setLoadingServiceDetail(false)
        formServiceDetail.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusServiceDetail(false);
            setOpenModalServiceDetail(true);
        }
        else {
            setStatusServiceDetail(true);
            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    const data = dataServiceDetail.filter(i => i.ID === record);
                    setDataEditorHOWTOGETServiceDetail({
                        editorState: htmlToDraftBlocks(data[0].HOWTOGET)
                    })

                    setDataEditorHOWITFORMEDServiceDetail({
                        editorState: htmlToDraftBlocks(data[0].HOWITFORMED)
                    })
                    setDataEditorWHYServiceDetail({
                        editorState: htmlToDraftBlocks(data[0].WHY)
                    })
                    setDataEditorRecomentTourServiceDetail({
                        editorState: htmlToDraftBlocks(data[0].RecomentTour)
                    })
                    formServiceDetail.setFieldsValue(
                        {
                            "ID": data[0].ID,
                            "VideoSrc": data[0].VideoSrc,
                            "ImageBanner": data[0].ImageBanner,
                            "ImageHowToGet": data[0].ImageHowToGet,
                            "ImageItformed": data[0].ImageItformed,
                            "ImageWhy": data[0].ImageWhy,
                            "Title": data[0].Title,
                            "ImageRecomentTour": data[0].ImageRecomentTour,
                            "Slogan": data[0].Slogan,
                            "IDService": data[0].IDService,
                        }
                    );
                    setOpenModalServiceDetail(true);
                }
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalAttaction = (record) => {
        //const IDTemp = 0;
        setLoadingAttaction(false)
        formAttaction.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusAttaction(false);
            setOpenModalAttaction(true);
        }
        else {
            setStatusAttaction(true);
            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    const data = dataAttaction.filter(i => i.ID === record);
                    setDataEditorHOWTOGET({
                        editorState: htmlToDraftBlocks(data[0].HOWTOGET)
                    })
                    setDataEditorHOWITFORMED({
                        editorState: htmlToDraftBlocks(data[0].howitfomed)
                    })
                    setDataEditorWHY({
                        editorState: htmlToDraftBlocks(data[0].WHY)
                    })
                    setDataEditorRecomentTour({
                        editorState: htmlToDraftBlocks(data[0].RecomentTour)
                    })
                    formAttaction.setFieldsValue(
                        {
                            "ID": data[0].ID,
                            "VideoSrc": data[0].VideoSrc,
                            "LocationMap": data[0].LocationMap,
                            "ImageBanner": data[0].ImageBanner,
                            "ImageHowToGet": data[0].ImageHowToGet,
                            "ImageItFormed": data[0].ImageItFormed,
                            "ImageWhy": data[0].ImageWhy,
                            "Title": data[0].Title,
                            "ImageRecomentTour": data[0].ImageRecomentTour,
                            "Slogan": data[0].Slogan,
                            "IDCategory": data[0].IDCategory,
                            "IDSubMenu": data[0].IDSubMenu,
                            "IDMenu": data[0].IDMenu
                        }
                    );
                    setOpenModalAttaction(true);
                }
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const fillDataBlog = (id) => {
        const data = dataBlog.filter(i => i.ID === id);
        setDataEditorBlog({
            editorState: htmlToDraftBlocks(data[0].Content)
        })
        formBlog.setFieldsValue(
            {
                ID: data[0].ID,
                TitleVI: String(data[0].Title).split(';')[0],
                TitleEN: String(data[0].Title).split(';')[1],
                Slogan: data[0].Slogan,
                Content: data[0].Content,
                DayofWriting: data[0].DayofWriting,
                About: data[0].About,
                Writer: data[0].Writer,
                IDPosts: data[0].IDPosts,
                IDUser: data[0].IDUser,
                Image: data[0].Image,
                Status: data[0].Status,
                TopTitle: data[0].TopTitle,
                TitleSale: data[0].TitleSale,
                Map: data[0].Map,
            }
        );
    };
    const fillDataFooter = (id) => {
        const data = dataFooter.filter(i => i.ID === id);
        formFooter.setFieldsValue(
            {
                "Facebook": data[0].Facebook,
                "Youtube": data[0].Youtube,
                "Instagram": data[0].Instagram,
                "Tripadvisor": data[0].Tripadvisor,
                "ID": data[0].ID
            }
        );
    };

    const fillArrayImage = (arr) => {
        const list = []
        arr.map(r => (
            toDataURL(r)
                .then(dataUrl => {
                    var fileData = dataURLtoFile(dataUrl, r.split('https://dgss.vn:8081/files/')[1]);
                    list.push({
                        lastModified: fileData.lastModified,
                        name: fileData.name,
                        status: 'done',
                        url: r,
                        uid: `__AUTO__1694415727047_${Math.floor(Math.random() * 101)}__`,
                    });

                })
        ))
        setFileList(list);
    }
    const fillArrayImageBanner = (arr) => {
        const list = [];
        arr.map(r => (
            toDataURL(r)
                .then(dataUrl => {
                    var fileData = dataURLtoFile(dataUrl, r.split('https://dgss.vn:8081/files/')[1]);
                    list.push({
                        lastModified: fileData.lastModified,
                        name: fileData.name,
                        status: 'done',
                        url: r,
                        uid: `__AUTO__1694415727047_${Math.floor(Math.random() * 101)}__`,
                    });
                })
        ))
        setFileListBannerHome(list);
    }
    const fillDataTour = (id) => {
        const data = dataTour.filter(i => i.ID === id);
        const arrService = String(data.map(r => r.Services)).split(';');
        const arrImageTour = String(data.map(r => r.Image)).split(';');
        fillArrayImage(arrImageTour)
        //------------------------------------------------------------------------
        setDataSelectCategory(dataSubMenu.filter(i => i.ID === data[0].IDSubMenu))
        setDataSelectSubmenu(dataMenu.filter(i => i.ID === data[0].IDMenu));

        formTour.setFieldsValue(
            {
                "ID": id,
                "IDCategory": data[0].IDCategory,
                "IDSubMenu": data[0].IDSubMenu,
                "IDMenu": data[0].IDMenu,
                "TopTitle": data[0].TopTitle,
                "TitleSale": data[0].TitleSale,
                "TitleTour": data[0].TitleTour,
                "TitleVI": String(data[0].Title).split(';')[1],
                "TitleEN": String(data[0].Title).split(';')[0],
                "Availability": data[0].Availability,
                "Duration": data[0].Duration,
                "DepartsFrom": data[0].DepartsFrom,
                "Price": data[0].Price,
                "Image": data[0].Image,
                "Rating": data[0].Rating,
                "Reviewer": data[0].Reviewer,
                "Itinerary": data[0].Itinerary,
                "SrcMap": data[0].SrcMap,
                "SrcVideo": data[0].SrcVideo,
                "RequiredParticipants": data[0].RequiredParticipants,
                "AboutTour": data[0].AboutTour,
                "Age": data[0].Age,
                "Children": data[0].Children,
                "Participants": data[0].Participants,
                "Highlights": data[0].Highlights,
                "Included": data[0].Included,
                "Bring": data[0].Bring,
                "Difficulty": data[0].Difficulty,
                "Minimumage": data[0].Minimumage,
                "Pickup": data[0].Pickup,
                "MeetonLocation": data[0].MeetonLocation,
                "Peradult": data[0].Peradult,
                "DateStart": moment(data[0].DateStart),
                "Services": arrService,
                "Status": data[0].Status,
                "OrderView": data[0].OrderView,
                "Title": String(data[0].Title).split(';')[0] + ';' + String(data[0].Title).split(';')[1]
            }
        );
    };
    const showModalCategory = (record) => {
        //const IDTemp = 0;
        setLoadingCategory(false)
        formCategory.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusCategory(false);
            setOpenModalCategory(true);
        }
        else {

            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataCategory(record);
                    setStatusCategory(true)
                    setOpenModalCategory(true);
                }
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalBlog = (record) => {
        //const IDTemp = 0;
        setLoadingBlog(false)
        formBlog.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusBlog(false);
            setOpenModalBlog(true);
        }
        else {

            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataBlog(record);
                    setStatusBlog(true)
                    setOpenModalBlog(true);
                }
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }
    const showModalFooter = (record) => {
        //const IDTemp = 0;
        setLoadingFooter(false)
        formFooter.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusFooter(false);
            setOpenModalFooter(true);
        }
        else {

            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataFooter(record);
                    setStatusFooter(true)
                    setOpenModalFooter(true);
                }
            }
            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }
        }
    }

    const showModalCompany = (record) => {
        //const IDTemp = 0;
        setLoadingCompany(false)
        formCompany.resetFields();
        if (record === 0) {
            // thêm mới
            setStatusCompany(false);
            setOpenModalCompany(true);
        }
        else {

            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataCompany(record);
                    setStatusCompany(true)
                    setOpenModalCompany(true);
                }

            }

            else {
                swal('Bạn không có quyền truy cập chức năng này', {
                    icon: 'warning',
                    buttons: false,
                    timer: 3000
                })
            }

        }
    }

    const handleOkCategory = () => {
        setLoadingCategory(true);
        setTimeout(() => {
            setLoadingCategory(false);
            setOpenModalCategory(false);
        }, 3000);
    }
    const handleCancelCategory = () => {
        resetForm();
        setLoadingCategory(false);
        setOpenModalCategory(false);

    }

    const handleOkExtraFooter = () => {
        setLoadingExtraFooter(true);
        setTimeout(() => {
            setLoadingExtraFooter(false);
            setOpenModalExtraFooter(false);
        }, 3000);
    }
    const handleCancelExtraFooter = () => {
        formExtraFooter.resetFields();
        setLoadingExtraFooter(false);
        setOpenModalExtraFooter(false);

    }
    const handleOkShareAction = () => {
        setLoadingShareAction(true);
        setTimeout(() => {
            setLoadingShareAction(false);
            setOpenModalShareAction(false);
        }, 3000);
    }
    const handleCancelShareAction = () => {
        formShareAction.resetFields();
        setLoadingShareAction(false);
        setOpenModalShareAction(false);

    }
    const handleOkEmail = () => {
        setLoadingEmail(true);
        setTimeout(() => {
            setLoadingEmail(false);
            setOpenModalEmail(false);
        }, 3000);
    }
    const handleCancelEmail = () => {
        formEmail.resetFields();
        setLoadingEmail(false);
        setOpenModalEmail(false);

    }
    const handleOkAttaction = () => {
        setLoadingAttaction(true);
        setTimeout(() => {
            setLoadingAttaction(false);
            setOpenModalAttaction(false);
        }, 3000);
    }
    const handleCancelAttaction = () => {
        formAttaction.resetFields();
        setLoadingAttaction(false);
        setOpenModalAttaction(false);

    }
    const handleOkAbout = () => {
        setLoadingAbout(true);
        setTimeout(() => {
            setLoadingAbout(false);
            setOpenModalAbout(false);
        }, 3000);
    }
    const handleCancelAbout = () => {
        formAbout.resetFields();
        setLoadingAbout(false);
        setOpenModalAbout(false);

    }
    const handleOkServiceDetail = () => {
        setLoadingServiceDetail(true);
        setTimeout(() => {
            setLoadingServiceDetail(false);
            setOpenModalServiceDetail(false);
        }, 3000);
    }
    const handleCancelServiceDetail = () => {
        formServiceDetail.resetFields();
        setLoadingServiceDetail(false);
        setOpenModalServiceDetail(false);

    }
    const handleOkBlog = () => {
        setLoadingBlog(true);
        setTimeout(() => {
            setLoadingBlog(false);
            setOpenModalBlog(false);
        }, 3000);
    }
    const handleCancelBlog = () => {
        formBlog.resetFields()
        setLoadingBlog(false);
        setOpenModalBlog(false);

    }
    const handleOkFooter = () => {
        setLoadingFooter(true);
        setTimeout(() => {
            setLoadingFooter(false);
            setOpenModalFooter(false);
        }, 3000);
    }
    const handleCancelFooter = () => {
        formFooter.resetFields();
        setLoadingFooter(false);
        setOpenModalFooter(false);

    }
    const handleOkCompany = () => {
        setLoadingCompany(true);
        setTimeout(() => {
            setLoadingCompany(false);
            setOpenModalCompany(false);
        }, 3000);
    }
    const handleCancelCompany = () => {
        formCompany.resetFields();
        setLoadingCompany(false);
        setOpenModalCompany(false);

    }
    const resetForm = () => {
        formCategory.resetFields();
        formTour.resetFields();
        formAccount.resetFields();
        formFeedback.resetFields();
        formBlog.resetFields();
    }
    const onFinishFilterCategory = (values) => {

    }
    const onDeleteMenu = (record) => {
        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/Menu/DeleteMenu/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataMenu();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onDeleteCategory = (record) => {

        if (role !== 'admin') {
            swal("You do not have permission to access this function!!!", {
                icon: "warning",
                buttons: false,
            });
            return <></>;
        }
        if (record !== 0 && role === 'admin') {
            swal({
                title: "Are you sure you want to delete??",
                text: "Once deleted, you will not be able to recover this data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(`https://dgss.vn:7001/api/CategoryTour/DeleteCategoryTour/${record}`, '', {
                        headers: header
                    }).then(res => {
                        if (res.status === 200) {
                            swal('Delete success');
                            LoadDataCategory();
                        }
                    })
                } else {
                    swal({
                        text: "Cancel success",
                        icon: "success",
                    });
                }
            });
        }
    }
    const onFinishFilterTour = (values) => {
        if (values.ID !== 0 && dataTour.filter(i => i.ID === values.ID).length > 0) {
            setDataTour(dataTour.filter(i => i.ID === values.ID));
        }
        else {
            LoadDataTour();
        }
    }
    const handleOkAccount = () => {
        setLoadingAccount(true);
        setTimeout(() => {
            setLoadingAccount(false);
            setOpenModalAccount(false);
        }, 3000);
    }
    const handleCancelAccount = () => {
        resetForm();
        setLoadingAccount(false);
        setOpenModalAccount(false);

    }
    const handleOkSubMenu = () => {
        setLoadingSubMenu(true);
        setTimeout(() => {
            setLoadingSubMenu(false);
            setOpenModalSubMenu(false);
        }, 3000);
    }
    const handleOkService = () => {
        setLoadingService(true);
        setTimeout(() => {
            setLoadingService(false);
            setOpenModalService(false);
        }, 3000);
    }
    const handleOkServiceMenu = () => {
        setLoadingServiceMenu(true);
        setTimeout(() => {
            setLoadingServiceMenu(false);
            setOpenModalServiceMenu(false);
        }, 3000);
    }
    const handleOkPolicies = () => {
        setLoadingPolicies(true);
        setTimeout(() => {
            setLoadingPolicies(false);
            setOpenModalPolicies(false);
        }, 3000);
    }
    const handleCancelPolicies = () => {
        setLoadingPolicies(false);
        setOpenModalPolicies(false);

    }
    const handleCancelMenu = () => {
        formMenu.resetFields();
        setLoadingMenu(false);
        setOpenModalMenu(false);

    }
    const handleCancelService = () => {
        formService.resetFields();
        setLoadingService(false);
        setOpenModalService(false);

    }
    const handleCancelServiceMenu = () => {
        formServiceMenu.resetFields();
        setLoadingServiceMenu(false);
        setOpenModalServiceMenu(false);

    }
    const handleOkMenu = () => {
        setLoadingMenu(true);
        setTimeout(() => {
            setLoadingMenu(false);
            setOpenModalMenu(false);
        }, 3000);
    }
    const handleCancelSubMenu = () => {
        formSubMenu.resetFields();
        setLoadingSubMenu(false);
        setOpenModalSubMenu(false);

    }
    const handleOkBannerHome = () => {
        setLoadingBannerHome(true);
        setTimeout(() => {
            setLoadingBannerHome(false);
            setOpenModalBannerHome(false);
        }, 3000);
    }
    const handleCancelBannerHome = () => {
        formBannerHome.resetFields();
        setLoadingBannerHome(false);
        setOpenModalBannerHome(false);
    }
    const handleOkSaleBanner = () => {
        setLoadingSaleBanner(true);
        setTimeout(() => {
            setLoadingSaleBanner(false);
            setOpenModalSaleBanner(false);
        }, 3000);
    }
    const handleCancelSaleBanner = () => {
        formSaleBanner.resetFields();
        setLoadingSaleBanner(false);
        setOpenModalSaleBanner(false);
    }
    const handleOkTour = () => {
        setLoadingTour(true);
        setTimeout(() => {
            setLoadingTour(false);
            setOpenModalTour(false);
        }, 3000);
    }
    const handleCancelTour = () => {
        resetForm();
        setLoadingTour(false);
        setFileList([])
        setOpenModalTour(false);

    }
    const onFinishFilterAccount = (values) => {

    }
    const columnBookTour = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>ID</h6>
                </span>
            ),
            dataIndex: 'IDTour',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {record.IDTour}{record.ID}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Tour</h6>
                </span>
            ),
            dataIndex: 'IDTour',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        dataTour.filter(i => i.ID === record.IDTour).map(r => Language === 'en' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>StartDay</h6>
                </span>
            ),

            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {formatLongDate(record.StartDay)}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Customer</h6>
                </span>
            ),
            dataIndex: 'IDCustomer',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {dataCustomer.filter(i => i.ID === record.IDCustomer).map(r => r.FullName)}
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Participants</h6>
                </span>
            ),

            dataIndex: 'NumberofParticipants',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}> ${'Children < 120 cm'}</h6>
                </span>
            ),

            dataIndex: 'NumberofChildren',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}> ${'Children < 100 cm'}</h6>
                </span>
            ),

            dataIndex: 'Numberofchildrenunder',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}> PickUpAddress</h6>
                </span>
            ),

            dataIndex: 'PickUpAddress',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Price Tour</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {dataTour.filter(i => i.ID === record.IDTour).map(r => r.Price + '$')}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Service</h6>
                </span>
            ),
            with: 180,
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {getServiceDetail(record.ID)}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Total Price</h6>
                </span>
            ),

            dataIndex: 'TotalPrice',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.TotalPrice} $
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Method Of Payment</h6>
                </span>
            ),

            dataIndex: 'MethodOfPayment',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.MethodOfPayment}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Special Request</h6>
                </span>
            ),

            dataIndex: 'SpecialRequest',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {record.SpecialRequest}
                </div>
            ),
        },
    ]
    const columnBannerHome = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Slogan</h6>
                </span>
            ),
            dataIndex: 'Slogan',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Slogan).split(';')[0] : String(record.Slogan).split(';')[1]
                    }
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Description</h6>
                </span>
            ),

            dataIndex: 'Description',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Description).split(';')[0] : String(record.Description).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Button1</h6>
                </span>
            ),

            dataIndex: 'Button1',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        String(record.Button1).split(';')[0]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Action 1</h6>
                </span>
            ),

            dataIndex: 'Action1',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        dataCategory.filter(i => i.ID === Number(String(record.Button1).split(';')[1])).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Button2</h6>
                </span>
            ),

            dataIndex: 'Button2',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        String(record.Button2).split(';')[0]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Action 2</h6>
                </span>
            ),

            dataIndex: 'Button2',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        dataCategory.filter(i => i.ID === Number(String(record.Button2).split(';')[1])).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),

            dataIndex: 'Image',
            render: (text, record) => {
                const data = String(record.Image).split(';');
                return (
                    <div style={{ textAlign: "center" }}>
                        <Image.PreviewGroup>
                            <Carousel infinite={false} autoplay>
                                {data.map(r => (
                                    <Image style={{ width: '20%' }} src={r} />
                                ))}
                            </Carousel>
                        </Image.PreviewGroup>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalBannerHome(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnSaleBanner = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Slogan</h6>
                </span>
            ),
            dataIndex: 'Slogan',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Slogan).split(';')[0] : String(record.Slogan).split(';')[1]
                    }
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title 1</h6>
                </span>
            ),

            dataIndex: 'Title1',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title1).split(';')[0] : String(record.Title1).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title 2</h6>
                </span>
            ),

            dataIndex: 'Title2',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title2).split(';')[0] : String(record.Title2).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title 3</h6>
                </span>
            ),

            dataIndex: 'Title1',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title3).split(';')[0] : String(record.Title3).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title 4</h6>
                </span>
            ),

            dataIndex: 'Title2',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title4).split(';')[0] : String(record.Title4).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title 5</h6>
                </span>
            ),

            dataIndex: 'Title2',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title5).split(';')[0] : String(record.Title5).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalSaleBanner(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const columnExtraFooter = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Phone</h6>
                </span>
            ),
            dataIndex: 'Phone',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Hotline</h6>
                </span>
            ),
            dataIndex: 'Hotline',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Email</h6>
                </span>
            ),
            dataIndex: 'Email',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Messenger</h6>
                </span>
            ),
            dataIndex: 'Messenger',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Zalo</h6>
                </span>
            ),
            dataIndex: 'Zalo',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Whatapp</h6>
                </span>
            ),
            dataIndex: 'Whatapp',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Telegram</h6>
                </span>
            ),
            dataIndex: 'Telegram',
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Ktalk</h6>
                </span>
            ),
            dataIndex: 'Ktalk',

        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>

                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalExtraFooter(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnShareAction = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Pinterest</h6>
                </span>
            ),
            dataIndex: 'Pinterest',
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        <a target="_blank" href={record.Pinterest}>{record.Pinterest}</a>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Instagram</h6>
                </span>
            ),
            dataIndex: 'Instagram',
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        <a target="_blank" href={record.Instagram}>{record.Instagram}</a>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Whatsapp</h6>
                </span>
            ),
            dataIndex: 'Twitter',
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        <a target="_blank" href={record.Twitter}>{record.Twitter}</a>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Facebook</h6>
                </span>
            ),
            dataIndex: 'Facebook',
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        <a target="_blank" href={record.Facebook}>{record.Facebook}</a>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalShareAction(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnEmail = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Type Email</h6>
                </span>
            ),
            dataIndex: 'ID',
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        {record.ID === 1 ? 'Send email when booking tour' : 'Email subscription'}

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Subject Email</h6>
                </span>
            ),
            dataIndex: 'subject',
            width: 200,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        {record.subject}
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Alert Send Success</h6>
                </span>
            ),
            dataIndex: 'EmailAlert',
            width: 200,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "left", fontSize: 12 }}>
                        {record.alert}
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Content</h6>
                </span>
            ),
            dataIndex: 'EmailContent',
            width: 400,
            render: (text, record) => {
                return (
                    <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="show-more-less-clickable"
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                        <div style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                        }} dangerouslySetInnerHTML={{ __html: record.content }}>
                        </div>
                    </ShowMoreText>
                );
            },
        },

        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalEmail(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnAbout = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>WhoWeAre</h6>
                </span>
            ),
            dataIndex: 'WhoWeAre',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.WhoWeAre }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>CoreValues</h6>
                </span>
            ),
            dataIndex: 'CoreValues',

            render: (text, record) => (
                <ShowMoreText

                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.CoreValues }}>
                    </div>
                </ShowMoreText>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Video</h6>
                </span>
            ),
            dataIndex: 'Video',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>

                    <a href={String(record.VideoSrc).split(';')[0]} target="_blank" rel="noopener noreferrer">
                        {String(record.VideoSrc).split(';')[0]}
                    </a>
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Describe Video</h6>
                </span>
            ),
            dataIndex: 'Video',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {String(record.Video).split(';')[1]}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),

            dataIndex: 'Image',
            render: (text, record) => {
                const data = String(record.Image).split(';');
                return (
                    <div style={{ textAlign: "center" }}>
                        <Image.PreviewGroup>
                            <Carousel infinite={false} autoplay>
                                {data.map(r => (
                                    <Image style={{ width: '20%' }} src={r} />
                                ))}
                            </Carousel>
                        </Image.PreviewGroup>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalAbout(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnServiceDetail = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            dataIndex: 'Title',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {record.Title}
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Slogan</h6>
                </span>
            ),
            dataIndex: 'Slogan',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {record.Slogan}
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>IDService</h6>
                </span>
            ),
            dataIndex: 'IDCategory',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {record.IDService}

                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Video</h6>
                </span>
            ),
            dataIndex: 'Video',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>

                    <a href={record.VideoSrc} target="_blank" rel="noopener noreferrer">
                        {record.VideoSrc}
                    </a>
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image Banner</h6>
                </span>
            ),

            dataIndex: 'Image',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageBanner} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Location Map</h6>
                </span>
            ),

            dataIndex: 'LocationMap',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.LocationMap} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image HowToGet</h6>
                </span>
            ),

            dataIndex: 'ImageHowToGet',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageHowToGet} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>HOWTOGET</h6>
                </span>
            ),
            dataIndex: 'HOWTOGET',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.HOWTOGET }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image ItFormed</h6>
                </span>
            ),

            dataIndex: 'ImageItformed',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageItformed} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>HOW IT FORMED</h6>
                </span>
            ),
            dataIndex: 'howitfomed',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.HOWITFORMED }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image Why</h6>
                </span>
            ),

            dataIndex: 'ImageWhy',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageWhy} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>WHY</h6>
                </span>
            ),
            dataIndex: 'WHY',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.WHY }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image Why</h6>
                </span>
            ),

            dataIndex: 'ImageWhy',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageWhy} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>WHY</h6>
                </span>
            ),
            dataIndex: 'WHY',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.WHY }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image RecomentTour</h6>
                </span>
            ),

            dataIndex: 'ImageRecomentTour',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageRecomentTour} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>RecomentTour</h6>
                </span>
            ),
            dataIndex: 'RecomentTour',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.RecomentTour }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalServiceDetail(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteServiceDetail(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },


    ]
    const columnAttaction = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            dataIndex: 'Title',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {record.Title}
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Slogan</h6>
                </span>
            ),
            dataIndex: 'Slogan',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {record.Slogan}
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Category</h6>
                </span>
            ),
            dataIndex: 'IDCategory',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {
                        dataCategory.filter(i => i.ID === record.IDCategory).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])
                    }

                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>SubMenu</h6>
                </span>
            ),
            dataIndex: 'IDSubMenu',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {
                        dataSubMenu.filter(i => i.ID === record.IDSubMenu).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])
                    }

                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Menu</h6>
                </span>
            ),
            dataIndex: 'IDMenu',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>
                    {
                        dataMenu.filter(i => i.ID === record.IDMenu).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])
                    }

                </div>
            ),

        }, {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Video</h6>
                </span>
            ),
            dataIndex: 'Video',
            render: (text, record) => (
                <div style={{ textAlign: "left", fontSize: 12 }}>

                    <a href={record.VideoSrc} target="_blank" rel="noopener noreferrer">
                        {record.VideoSrc}
                    </a>
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image Banner</h6>
                </span>
            ),

            dataIndex: 'Image',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageBanner} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Location Map</h6>
                </span>
            ),

            dataIndex: 'LocationMap',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.LocationMap} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image HowToGet</h6>
                </span>
            ),

            dataIndex: 'ImageHowToGet',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageHowToGet} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>HOWTOGET</h6>
                </span>
            ),
            dataIndex: 'HOWTOGET',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.HOWTOGET }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image ItFormed</h6>
                </span>
            ),

            dataIndex: 'ImageItFormed',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageItFormed} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>HOW IT FORMED</h6>
                </span>
            ),
            dataIndex: 'howitfomed',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.howitfomed }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image Why</h6>
                </span>
            ),

            dataIndex: 'ImageWhy',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageWhy} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>WHY</h6>
                </span>
            ),
            dataIndex: 'WHY',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.WHY }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image Why</h6>
                </span>
            ),

            dataIndex: 'ImageWhy',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageWhy} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>WHY</h6>
                </span>
            ),
            dataIndex: 'WHY',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.WHY }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image RecomentTour</h6>
                </span>
            ),

            dataIndex: 'ImageRecomentTour',
            render: (text, record) => {
                // const data = String(record.ImageBanner).split(';');
                return (
                    <div style={{ textAlign: "center" }}>

                        <Image style={{ width: '20%' }} src={record.ImageRecomentTour} />

                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>RecomentTour</h6>
                </span>
            ),
            dataIndex: 'RecomentTour',
            render: (text, record) => (
                <ShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    <div style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }} dangerouslySetInnerHTML={{ __html: record.RecomentTour }}>
                    </div>
                </ShowMoreText>
            )
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalAttaction(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteAttaction(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const onChangePolicies = (e) => {
        setValuePolicies(e.target.value)
    }
    const htmlToDraftBlocks = (html) => {
        // chuyển đổi nội dung html to text
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    }
    const showModalPolicies = (e) => {
        if (valuePolicies === 1) {
            setDataEditor({
                editorState: htmlToDraftBlocks(dataPolicies[0].CancelationRefund)
            })
        }
        if (valuePolicies === 2) {
            setDataEditor({
                editorState: htmlToDraftBlocks(dataPolicies[0].Payment)
            })
        }
        if (valuePolicies === 3) {
            setDataEditor({
                editorState: htmlToDraftBlocks(dataPolicies[0].Children)
            })
        }
        setLoadingPolicies(false);

        setOpenModalPolicies(true);
    }
    const optionPolicies = [
        { label: 'Cancelation & Refund', value: 1 },
        { label: 'Payment', value: 2 },
        { label: 'Children', value: 3 },
    ];
    const [valuePolicies, setValuePolicies] = useState(1);
    const columnCustomer = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>FullName</h6>
                </span>
            ),
            dataIndex: 'FullName',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.FullName}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Nationality</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {countryMap.filter(i => i.code === record.Nationality).map(r => r.name)}
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Email</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Email}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Phone</h6>
                </span>
            ),

            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Phone}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Address</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Address}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>WhatsApp</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Whatapp}
                </div>
            ),

        },
    ]
    const columnCompany = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>CompanyName</h6>
                </span>
            ),
            dataIndex: 'CompanyName',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.CompanyName}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Address</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Address}
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Email</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Email}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Phone</h6>
                </span>
            ),

            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Phone}
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>App Email</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.AppEmail}
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Password</h6>
                </span>
            ),
            render: (text, record) => (
                <Input.Password readOnly value={record.PassAppEmail} />
            ),

        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalCompany(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnFooter = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Facebook</h6>
                </span>
            ),
            dataIndex: 'CompanyName',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Facebook}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Youtube</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Youtube}
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Instagram</h6>
                </span>
            ),
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Instagram}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Tripadvisor</h6>
                </span>
            ),

            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Tripadvisor}
                </div>
            ),
        },

        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalFooter(record.ID, e)} ></Button>
                            &ensp;
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const LoadDataPolicies = () => {
        axios.post('https://dgss.vn:7001/api/Policies/GetPoliciesAll', '').then(res => {
            if (res.status === 200) {
                setDataPolicies(res.data.filter(i => i.ID === 1))
            }
        })

    }
    const onFinishPolicies = () => {
        if (valuePolicies === 1) {
            axios.post('https://dgss.vn:7001/api/Policies/UpdatePolicies', {
                "ID": 1,
                "CancelationRefund": draftToHtml(convertToRaw(dataEditor.editorState.getCurrentContent())),
                "Payment": dataPolicies[0].Payment,
                "Children": dataPolicies[0].Children
            }, { headers: header }).then(res => {
                if (res.status === 200) {

                    setOpenModalPolicies(false)
                    LoadDataPolicies();
                }
            })
        }
        if (valuePolicies === 2) {
            axios.post('https://dgss.vn:7001/api/Policies/UpdatePolicies', {
                "ID": 1,
                "CancelationRefund": dataPolicies[0].CancelationRefund,
                "Payment": draftToHtml(convertToRaw(dataEditor.editorState.getCurrentContent())),
                "Children": dataPolicies[0].Children
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    setOpenModalPolicies(false)
                    LoadDataPolicies();
                }
            })
        }
        if (valuePolicies === 3) {
            axios.post('https://dgss.vn:7001/api/Policies/UpdatePolicies', {
                "ID": 1,
                "CancelationRefund": dataPolicies[0].CancelationRefund,
                "Payment": dataPolicies[0].Payment,
                "Children": draftToHtml(convertToRaw(dataEditor.editorState.getCurrentContent()))
            }, { headers: header }).then(res => {
                if (res.status === 200) {
                    setOpenModalPolicies(false);
                    LoadDataPolicies();
                }
            })
        }
    }
    const columnFeedback = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Email</h6>
                </span>
            ),
            width: 180,
            dataIndex: 'Email'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Phone</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Phone'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Name</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Name'
        },
        // {
        //     title: (
        //         <span>
        //             <h6 style={{ textAlign: "center" }}>Status</h6>
        //         </span>
        //     ),
        //     width: 100,
        //     dataIndex: 'Status',
        //     render: (text, record) => (
        //         <div style={{ textAlign: "center", fontSize: 12 }}>
        //             {record.Status === 1 ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
        //             {record.Status === 0 ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

        //         </div>
        //     ),
        // },
        // {
        //     title: (
        //         <span>
        //             <h6 style={{ textAlign: "center" }}>Image</h6>
        //         </span>
        //     ),
        //     width: 270,
        //     dataIndex: 'Image',
        //     render: (text, record) => (
        //         <Image src={record.Image} width={100} height={60} />
        //     ),
        // },
        // {
        //     title: (
        //         <span>
        //             <h6 style={{ textAlign: "center" }}>CoverImage</h6>
        //         </span>
        //     ),
        //     width: 270,
        //     dataIndex: 'CoverImage',
        //     render: (text, record) => (
        //         <Image src={record.CoverImage} width={100} height={60} />
        //     ),
        // },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Content</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Content',

        },
        // {
        //     title: (
        //         <span>
        //             <h4 style={{ textAlign: "center" }}>
        //                 <SettingOutlined />
        //             </h4>
        //         </span>
        //     ),
        //     width: 100,
        //     render: (text, record) => {
        //         return (
        //             <div style={{ textAlign: "center" }}>
        //                 <ButtonGroup>
        //                     <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalCategory(record.ID, e)} ></Button>

        //                 </ButtonGroup>
        //             </div>
        //         );
        //     },
        // },
    ]
    const columnSubMenu = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            dataIndex: 'Title',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title).split(';')[0] : String(record.Title).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Status</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Status',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Status === true ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
                    {record.Status === false ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Image',
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Image src={record.Image} width={100} height={60} />
                </div>

            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Category</h6>
                </span>
            ),
            width: 270,
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    {dataCategory.filter(i => i.ID === record.IDCategory).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalSubMenu(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteSubMenu(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const columnMenu = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            dataIndex: 'Title',
            width: 150,
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title).split(';')[0] : String(record.Title).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Status</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Status',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Status === true ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
                    {record.Status === false ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Submenu</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'IDSubMenu',
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    {dataSubMenu.filter(i => i.ID === record.IDSubMenu).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Image',
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Image src={record.Image} width={100} height={60} />
                </div>

            ),
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalMenu(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteMenu(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const columnService = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            dataIndex: 'Title',
            width: 150,
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {Language === 'en' ? String(record.Title).split(';')[0] : String(record.Title).split(';')[1]}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 150,
            dataIndex: 'Description',
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <Image src={record.Image} />
                    </div>
                );
            },

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Description</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Description'
        },

        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalService(record.ID)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteService(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const columnServiceMenu = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            dataIndex: 'Title',
            width: 150,
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {Language === 'en' ? String(record.Title).split(';')[0] : String(record.Title).split(';')[1]}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>SubMenu Service</h6>
                </span>
            ),
            dataIndex: 'Title',
            width: 150,
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {dataService.filter(i => i.ID === record.IDServiceSubMenu).map(r => Language === 'en' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Description</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Description'
        },

        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalServiceMenu(record.ID)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteServiceMenu(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const columnAccount = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Username</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'UserName'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>FullName</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'FullName'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Phone</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Phone'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Email</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Email'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Image',
            render: (text, record) => (
                <Image src={record.Image} width={100} height={60} />
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Active</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Active',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Active === 1 ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
                    {record.Active === 0 ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Database Name</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'DatabaseName'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Security Code</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'SecurityCode'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Expiration Date</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'ExpirationDate'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Role</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Description'
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalAccount(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteAccount(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },

    ]
    const columnCategory = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Title',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'vi' ? String(record.Title).split(';')[0] : String(record.Title).split(';')[1]
                    }
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Description</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Description'
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Status</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Status',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Status === true ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
                    {record.Status === false ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Image',
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Image src={record.Image} width={100} height={60} />
                </div>

            ),
        },

        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalCategory(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => onDeleteCategory(record.ID, e)}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const columnBlog = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Title'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Slogan</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Slogan'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Content</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Content',
            render: (text, record) => (
                <div style={{ textOverflow: "clip", width: 200 }} dangerouslySetInnerHTML={{ __html: record.Content }}>
                </div>
            ),

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Status</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Status',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Status === 1 ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
                    {record.Status === 0 ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 200,
            dataIndex: 'Image',
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Image src={record.Image} width={100} height={60} />
                </div>

            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Day of Writing</h6>
                </span>
            ),
            width: 150,
            dataIndex: 'DayofWriting',
            render: (text, record) => (
                <p> {moment(record.DayofWriting).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>About</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'About',

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Writer</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Writer',
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalBlog(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => onDeleteBlog(record.ID, e)}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]

    const columnTour = [
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Category</h6>
                </span>
            ),
            width: 270,
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>
                    {dataCategory.filter(i => i.ID === record.IDCategory).map(r => Language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1])}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>TopTitle</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'TopTitle'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>TitleSale</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'TitleSale'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>TitleTour</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'TitleTour'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Title</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Title',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {
                        Language === 'en' ? String(record.Title).split(';')[0] : String(record.Title).split(';')[1]
                    }
                </div>
            )
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Availability</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Availability'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Duration</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Duration'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>DepartsFrom</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'DepartsFrom'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Price</h6>
                </span>
            ),
            width: 170,
            dataIndex: 'Price',
            render: (text, record) => (
                <div style={{ textAlign: "center" }}>
                    {record.Price + '$'}
                </div>
            )
        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Pickup</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Pickup'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Meet on Location</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'MeetonLocation'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Peradult</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Peradult'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Itinerary</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Itinerary',

        },

        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Required Participants</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'RequiredParticipants'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>AboutTour</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'AboutTour',

        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Age</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Age'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Children</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Children'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Minimum Age</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Minimumage'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Participants</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Participants'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Highlights</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Highlights'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Included</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Included'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Bring</h6>
                </span>
            ),
            width: 270,
            dataIndex: 'Bring'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Difficulty</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Difficulty'
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Status</h6>
                </span>
            ),
            width: 100,
            dataIndex: 'Status',
            render: (text, record) => (
                <div style={{ textAlign: "center", fontSize: 12 }}>
                    {record.Status === true ? <Tag icon={<CheckCircleOutlined />} color='success'>{Translate(Language, 'active')}</Tag> : null}
                    {record.Status === false ? <Tag icon={<CloseCircleOutlined />} color="#f50">{Translate(Language, 'deactive')}</Tag> : null}

                </div>
            ),
        },
        {
            title: (
                <span>
                    <h6 style={{ textAlign: "center" }}>Image</h6>
                </span>
            ),
            width: 200,
            dataIndex: 'Image',
            render: (text, record) => {
                const data = String(record.Image).split(';');
                return (
                    <div style={{ textAlign: "center" }}>
                        <Image.PreviewGroup>
                            <Carousel infinite={false} autoplay>
                                {data.map(r => (
                                    <Image style={{ width: '20%' }} src={r} />
                                ))}
                            </Carousel>
                        </Image.PreviewGroup>
                    </div>
                );
            },
        },
        {
            title: (
                <span>
                    <h4 style={{ textAlign: "center" }}>
                        <SettingOutlined />
                    </h4>
                </span>
            ),
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <ButtonGroup>
                            <Button icon={<FormOutlined style={{ color: "#33CCFF" }} />} onClick={(e) => showModalTour(record.ID, e)} ></Button>
                            &ensp;
                            <Button
                                onClick={(e) => {
                                    onDeleteTour(record.ID, e);
                                }}
                                icon={<DeleteOutlined style={{ color: "red" }} />}
                            ></Button>
                        </ButtonGroup>
                    </div>
                );
            },
        },
    ]
    const onFinishFilterFeedback = (values) => {
    }
    const showModalTour = (record) => {

        setFileList([])
        //const IDTemp = 0;
        setLoadingTour(false)
        resetForm();
        if (record === 0) {
            // thêm mới
            setStatusTour(false);
            setOpenModalTour(true);
        }
        else {
            // cập nhật
            if (role === 'admin' || role === 'manager') {
                if (record !== 0 || record !== '') {
                    fillDataTour(record);
                    setStatusTour(true)
                    setOpenModalTour(true);
                }

                else {
                    swal('Bạn không có quyền truy cập chức năng này', {
                        icon: 'warning',
                        buttons: false,
                        timer: 3000
                    })
                }
            }
        }
        // update
    }
    const fillDataSuggestionTour = (data) => {
        // chèn dữ liệu khi đã tồn tại sdt khách hàng trong hệ thống
        formTour.setFieldsValue({
            Title: data[0].Title,
        })
    }
    const suggestionSelectTour = (value, index) => {
        const ID = Number(index.key);

        if (value !== '' || value !== undefined || value !== null) {
            axios
                .post(
                    `https://dgss.vn:7001/api/Tour/GetTourByID/${ID}`, ''
                    , {
                        headers: header,
                    }
                )
                .then((res) => {
                    fillDataSuggestionTour(res.data);
                });
        }
    }
    const suggestionSearchTour = (value) => {
        let res = "";
        if (!value) {
            res = "";
        } else {
            res = value;
        }
        setSearchTour(res);
    }
    if (isLoggedIn === false) {
        return <Redirect to={"/"} />;
    }
    return (
        <div>
            {SlideImage(dataImage)}
            <Row>
                <Col span={1}>

                </Col>
                <Col span={22}>
                    <Tabs>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_category')} key={1}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formCategory}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterCategory}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>


                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameCategory')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>
                                                <Button onClick={(e) => showModalCategory(0, e)} type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 500 }} tableLayout="auto" sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnCategory} dataSource={dataCategory} />
                                </Col>
                            </Row>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_tour')} key={2}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formTour}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterTour}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="Title">
                                                    <AutoComplete onSelect={suggestionSelectTour} placeholder={Translate(Language, 'titletour')} onSearch={suggestionSearchTour} dropdownMatchSelectWidth={252}>
                                                        {dataTour.filter((item) => {
                                                            const title = item.Title;
                                                            const search = String(searchTour).toString();
                                                            const searchTerm = search.toLowerCase();
                                                            return (
                                                                searchTerm && title.startsWith(searchTerm) && searchTerm !== title
                                                            )
                                                        }).slice(0, 5).map((i, index) => (
                                                            <Option key={i.ID} value={i.ID}>
                                                                {`${i.Title}`}
                                                            </Option>
                                                        ))}
                                                    </AutoComplete>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalTour(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table size="small" sticky={{
                                        offsetHeader: 50,
                                    }} scroll={{ x: 2000 }} columns={columnTour} dataSource={dataTour} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_blog')} key={3}>
                            <Row>
                                <Col span={24}>
                                    <Form

                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterBlog}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="title">
                                                    <Input placeholder={Translate(Language, 'titleblog')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalBlog(0)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnBlog} dataSource={dataBlog} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={Translate(Language, 'Manager_feedback')} key={4}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formFeedback}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterFeedback}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="Title">
                                                    <Input placeholder={Translate(Language, 'titleFeedback')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnFeedback} dataSource={dataFeedback} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_account')} key={5}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>


                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalAccount(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnAccount} dataSource={dataAccount} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_SubMenu')} key={6}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalSubMenu(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnSubMenu} dataSource={dataSubMenu} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_Menu')} key={7}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalMenu(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnMenu} dataSource={dataMenu} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Manager SubMenu Service'} key={8}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalService(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnService} dataSource={dataService} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Manager Menu Service'} key={21}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalServiceMenu(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnServiceMenu} dataSource={dataServiceMenu} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Manager Service Detail'} key={22}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalServiceDetail(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 3000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnServiceDetail} dataSource={dataServiceDetail} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_BookTour')} key={9}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="BookTour">
                                                    <Input placeholder={Translate(Language, 'bookTour')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnBookTour} dataSource={dataBookTour} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_Customer')} key={10}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="BookTour">
                                                    <Input placeholder={Translate(Language, 'bookTour')} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnCustomer} dataSource={dataCustomer} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_Company')} key={12}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameCategory')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>
                                                <Button hidden={dataCompany.length === 0 ? false : true} onClick={(e) => showModalCompany(0, e)} type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col style={{ minHeight: 300 }} span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnCompany} dataSource={dataCompany} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Language === 'vi' ? 'Mạng xã hội' : 'Social networks'} key={13}>

                            <Table scroll={{ x: 1000 }} sticky={{
                                offsetHeader: 50,
                            }} columns={columnFooter} dataSource={dataFooter} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Language === 'vi' ? 'Về chúng tôi' : 'About us'} key={14}>

                            <Table scroll={{ x: 1000 }} sticky={{
                                offsetHeader: 50,
                            }} columns={columnAbout} dataSource={dataAbout} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={Translate(Language, 'Manager_Policies')} key={11}>
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col span={8}>
                                            <Radio.Group options={optionPolicies} onChange={onChangePolicies} value={valuePolicies} />
                                        </Col>
                                        <Col style={{ marginTop: -5 }} span={6}>
                                            <Button onClick={(e) => showModalPolicies(e)} type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                <p style={{ color: "white" }}>{Language === 'vi' ? 'Cập nhật' : 'Update'}</p>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider />
                                <Col hidden={valuePolicies === 1 ? false : true} style={{ minHeight: 300 }} span={24}>
                                    <Card title='Preview Cancelation & Refund'>
                                        <div dangerouslySetInnerHTML={{ __html: dataPolicies.map(r => r.CancelationRefund) }}>

                                        </div>
                                    </Card>

                                    {/* {dataPolicies.map(r => String(r.CancelationRefund).replace(/<[a-zA-Z]*>|<\/[a-zA-Z]*>/g, ""))} */}
                                </Col>
                                <Col hidden={valuePolicies === 2 ? false : true} style={{ minHeight: 300 }} span={24}>
                                    <Card title='Preview Payment'>
                                        <div dangerouslySetInnerHTML={{ __html: dataPolicies.map(r => r.Payment) }}>

                                        </div>
                                    </Card>

                                </Col>
                                <Col hidden={valuePolicies === 3 ? false : true} style={{ minHeight: 300 }} span={24}>
                                    <Card title='Preview Children'>
                                        <div dangerouslySetInnerHTML={{ __html: dataPolicies.map(r => r.Children) }}>

                                        </div>
                                    </Card>

                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Banner Home'} key={15}>
                            <Row>
                                <Divider />
                                <Col span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnBannerHome} dataSource={dataBannerHome} />
                                </Col>

                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Sale Banner'} key={16}>
                            <Row>
                                <Divider />
                                <Col span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnSaleBanner} dataSource={dataSaleBanner} />
                                </Col>

                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Attractions'} key={17}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={formAccount}
                                        name="basic"
                                        id="form-filter"
                                        onFinish={onFinishFilterAccount}
                                        labelCol={{ span: 0 }}
                                        labelAlign="right"
                                        colon={false}
                                        wrapperCol={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col xs={24} xl={4} span={4}>
                                                <Form.Item name="CustommerName">
                                                    <Input placeholder={Translate(Language, 'nameAccount')} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} xl={3} span={3}>
                                                <Form.Item name="Status">
                                                    <Select defaultValue={Translate(Language, 'status')} >
                                                        <Option value={'default'}>{Translate(Language, 'status')}</Option>
                                                        <Option value={1}>{Translate(Language, 'active')}</Option>
                                                        <Option value={0}>{Translate(Language, 'deactive')}</Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} xl={4} span={4}>
                                                <Form.Item>
                                                    <Button htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                        <p style={{ color: "white" }}>{Translate(Language, 'filter_button')}</p>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: -100 }} xl={4} xs={12} span={4}>

                                                <Button onClick={(e) => showModalAttaction(0, e)} htmlType="submit" form="form-filter" type="primary" style={{ width: "50%", backgroundColor: "#26A69A" }}>
                                                    {Translate(Language, 'addnew_button')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <Table scroll={{ x: 2500 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnAttaction} dataSource={dataAttaction} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Extra Footer'} key={18}>
                            <Row>
                                <Divider />
                                <Col span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnExtraFooter} dataSource={dataExtraFooter} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Share Action'} key={19}>
                            <Row>
                                <Divider />
                                <Col span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnShareAction} dataSource={dataShareAction} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'Manager Email'} key={20}>
                            <Row>
                                <Divider />
                                <Col span={24}>
                                    <Table scroll={{ x: 1000 }} sticky={{
                                        offsetHeader: 50,
                                    }} columns={columnEmail} dataSource={dataEmail} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>

                </Col>
            </Row>
            <Modal
                width={700}
                className="modalCategory"
                open={openModalCategory}
                title={
                    <span>
                        {statusCategory === true ? (
                            <>
                                <EditOutlined /> Update Category
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Category
                            </>
                        )}
                    </span>
                }
                onOk={handleOkCategory}
                onCancel={handleCancelCategory}
                footer={[
                    <Button key="back" onClick={handleCancelCategory}>
                        Cancel
                    </Button>,
                    <Button form="form-Category" type="primary" htmlType="submit" loading={loadingCategory}>
                        {statusCategory === true ? <>Update</> : <>Add Category</>}
                    </Button>,
                ]}
            >
                {loadingCategory ? (
                    <>
                        <Skeleton loading={loadingCategory} active />
                    </>
                ) : (
                    <>{renderCategory()}</>
                )}
            </Modal>

            <Modal
                className="modalaccount"
                width={700}
                open={openModalAccount}
                title={
                    <span>
                        {statusAccount === true ? (
                            <>
                                <EditOutlined /> Update Account
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Account
                            </>
                        )}
                    </span>
                }
                onOk={handleOkAccount}
                onCancel={handleCancelAccount}
                footer={[
                    <Button key="back" onClick={handleCancelAccount}>
                        Cancel
                    </Button>,
                    <Button form="form-Account" type="primary" htmlType="submit" loading={loadingAccount}>
                        {statusAccount === true ? <>Update</> : <>Add Account</>}
                    </Button>,
                ]}
            >
                {loadingAccount ? (
                    <>
                        <Skeleton loading={loadingAccount} active />
                    </>
                ) : (
                    <>{renderAccount()}</>
                )}
            </Modal>
            <Modal
                width={1000}
                className="modalTour"
                open={openModalTour}
                title={
                    <span>
                        {statusTour === true ? (
                            <>
                                <EditOutlined /> Update Tour
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Tour
                            </>
                        )}
                    </span>
                }
                onOk={handleOkTour}
                onCancel={handleCancelTour}
                footer={[
                    <Button key="back" onClick={handleCancelTour}>
                        Cancel
                    </Button>,
                    <Button form="form-Tour" type="primary" htmlType="submit" loading={loadingTour}>
                        {statusTour === true ? <>Update</> : <>Add Tour</>}
                    </Button>,
                ]}
            >
                {loadingTour ? (
                    <>
                        <Skeleton loading={loadingTour} active />
                    </>
                ) : (
                    <>{renderTour()}</>
                )}
            </Modal>
            <Modal
                className="modalSubmenu"
                width={700}
                open={openModalSubMenu}
                title={
                    <span>
                        {statusSubMenu === true ? (
                            <>
                                <EditOutlined /> Update SubMenu
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new SubMenu
                            </>
                        )}
                    </span>
                }
                onOk={handleOkSubMenu}
                onCancel={handleCancelSubMenu}
                footer={[
                    <Button key="back" onClick={handleCancelSubMenu}>
                        Cancel
                    </Button>,
                    <Button form="form-Submenu" type="primary" htmlType="submit" loading={loadingSubMenu}>
                        {statusSubMenu === true ? <>Update</> : <>Add SubMenu</>}
                    </Button>,
                ]}
            >
                {loadingSubMenu ? (
                    <>
                        <Skeleton loading={loadingSubMenu} active />
                    </>
                ) : (
                    <>{renderSubMenu()}</>
                )}
            </Modal>
            <Modal
                className="modalMenu"
                width={700}
                open={openModalMenu}
                title={
                    <span>
                        {statusMenu === true ? (
                            <>
                                <EditOutlined /> Update Menu
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Menu
                            </>
                        )}
                    </span>
                }
                onOk={handleOkMenu}
                onCancel={handleCancelMenu}
                footer={[
                    <Button key="back" onClick={handleCancelMenu}>
                        Cancel
                    </Button>,
                    <Button form="form-menu" type="primary" htmlType="submit" loading={loadingMenu}>
                        {statusMenu === true ? <>Update</> : <>Add Menu</>}
                    </Button>,
                ]}
            >
                {loadingMenu ? (
                    <>
                        <Skeleton loading={loadingMenu} active />
                    </>
                ) : (
                    <>{renderMenu()}</>
                )}
            </Modal>
            <Modal
                className="modalMenu"
                width={700}
                open={openModalService}
                title={
                    <span>
                        {statusService === true ? (
                            <>
                                <EditOutlined /> Update SubMenu Service
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add SubMenu Service
                            </>
                        )}
                    </span>
                }
                onOk={handleOkService}
                onCancel={handleCancelService}
                footer={[
                    <Button key="back" onClick={handleCancelService}>
                        Cancel
                    </Button>,
                    <Button form="form-Service" type="primary" htmlType="submit" loading={loadingService}>
                        {statusService === true ? <>Update</> : <>Add Service</>}
                    </Button>,
                ]}
            >
                {loadingService ? (
                    <>
                        <Skeleton loading={loadingService} active />
                    </>
                ) : (
                    <>{renderService()}</>
                )}
            </Modal>
            <Modal
                className="modalServiceMenu"
                width={700}
                open={openModalServiceMenu}
                title={
                    <span>
                        {statusServiceMenu === true ? (
                            <>
                                <EditOutlined /> Update Menu Service
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add Menu Service
                            </>
                        )}
                    </span>
                }
                onOk={handleOkServiceMenu}
                onCancel={handleCancelServiceMenu}
                footer={[
                    <Button key="back" onClick={handleCancelServiceMenu}>
                        Cancel
                    </Button>,
                    <Button form="form-ServiceMenu" type="primary" htmlType="submit" loading={loadingServiceMenu}>
                        {statusService === true ? <>Update</> : <>Add Menu Service</>}
                    </Button>,
                ]}
            >
                {loadingServiceMenu ? (
                    <>
                        <Skeleton loading={loadingServiceMenu} active />
                    </>
                ) : (
                    <>{renderServiceMenu()}</>
                )}
            </Modal>
            <Modal
                className="modalPolicies"
                width={1000}
                open={openModalPolicies}
                title={
                    <span>
                        <EditOutlined /> Update Policies
                    </span>
                }
                onOk={handleOkPolicies}
                onCancel={handleCancelPolicies}
                footer={[
                    <Button key="back" onClick={handleCancelPolicies}>
                        Cancel
                    </Button>,
                    <Button onClick={(e) => onFinishPolicies(e)} loading={loadingPolicies}>
                        Update
                    </Button>,
                ]}
            >
                {loadingPolicies ? (
                    <>
                        <Skeleton loading={loadingPolicies} active />
                    </>
                ) : (
                    <>{renderPolicies()}</>
                )}
            </Modal>
            <Modal
                className="modalCompany"
                width={1000}
                open={openModalCompany}
                title={
                    <span>
                        {statusCompany === true ? (
                            <>
                                <EditOutlined /> Update Company
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Company
                            </>
                        )}
                    </span>
                }
                onOk={handleOkCompany}
                onCancel={handleCancelCompany}
                footer={[
                    <Button key="back" onClick={handleCancelCompany}>
                        Cancel
                    </Button>,
                    <Button form="form-Company" type="primary" htmlType="submit" loading={loadingCompany}>
                        {statusCompany === true ? <>Update Company</> : <>Add Company</>}
                    </Button>,
                ]}
            >
                {loadingCompany ? (
                    <>
                        <Skeleton loading={loadingCompany} active />
                    </>
                ) : (
                    <>{renderCompany()}</>
                )}
            </Modal>
            <Modal
                className="modalFooter"
                width={1000}
                open={openModalFooter}
                title={
                    <span>
                        {statusFooter === true ? (
                            <>
                                <EditOutlined /> Update Footer
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Footer
                            </>
                        )}
                    </span>
                }
                onOk={handleOkFooter}
                onCancel={handleCancelFooter}
                footer={[
                    <Button key="back" onClick={handleCancelFooter}>
                        Cancel
                    </Button>,
                    <Button form="form-Footer" type="primary" htmlType="submit" loading={loadingFooter}>
                        {statusFooter === true ? <>Update Footer</> : <>Add Footer</>}
                    </Button>,
                ]}
            >
                {loadingFooter ? (
                    <>
                        <Skeleton loading={loadingFooter} active />
                    </>
                ) : (
                    <>{renderFooter()}</>
                )}
            </Modal>
            <Modal
                className="modalBlog"
                width={1000}
                open={openModalBlog}
                title={
                    <span>
                        {statusBlog === true ? (
                            <>
                                <EditOutlined /> Update Blog
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Blog
                            </>
                        )}
                    </span>
                }
                onOk={handleOkBlog}
                onCancel={handleCancelBlog}
                footer={[
                    <Button key="back" onClick={handleCancelBlog}>
                        Cancel
                    </Button>,
                    <Button form="form-Blog" type="primary" htmlType="submit" loading={loadingBlog}>
                        {statusBlog === true ? <>Update Blog</> : <>Add Blog</>}
                    </Button>,
                ]}
            >
                {loadingBlog ? (
                    <>
                        <Skeleton loading={loadingBlog} active />
                    </>
                ) : (
                    <>{renderBlog()}</>
                )}
            </Modal>
            <Modal
                className="modalAbout"
                width={1000}
                open={openModalAbout}
                title={
                    <span>

                        <EditOutlined /> Update About us

                    </span>
                }
                onOk={handleOkAbout}
                onCancel={handleCancelAbout}
                footer={[
                    <Button key="back" onClick={handleCancelAbout}>
                        Cancel
                    </Button>,
                    <Button form="form-About" type="primary" htmlType="submit" loading={loadingAbout}>
                        <>Update About Us</>
                    </Button>,
                ]}
            >
                {loadingAbout ? (
                    <>
                        <Skeleton loading={loadingAbout} active />
                    </>
                ) : (
                    <>{renderAbout()}</>
                )}
            </Modal>
            <Modal
                className="modalAbout"
                width={1000}
                open={openModalAbout}
                title={
                    <span>
                        {statusServiceDetail ? <span><EditOutlined /> Update Service Detail </span> : <span><PlusOutlined /> Add Service Detail </span>}
                    </span>
                }
                onOk={handleOkServiceDetail}
                onCancel={handleCancelServiceDetail}
                footer={[
                    <Button key="back" onClick={handleCancelServiceDetail}>
                        Cancel
                    </Button>,
                    <Button form="form-ServiceDetail" type="primary" htmlType="submit" loading={loadingServiceDetail}>
                        <>Update About Us</>
                    </Button>,
                ]}
            >
                {loadingServiceDetail ? (
                    <>
                        <Skeleton loading={loadingServiceDetail} active />
                    </>
                ) : (
                    <>{renderServiceDetail()}</>
                )}
            </Modal>
            <Modal
                className="modalBannerHome"
                width={700}
                open={openModalBannerHome}
                title={
                    <span>
                        <EditOutlined /> Update Banner Home
                    </span>
                }
                onOk={handleOkBannerHome}
                onCancel={handleCancelBannerHome}
                footer={[
                    <Button key="back" onClick={handleCancelBannerHome}>
                        Cancel
                    </Button>,
                    <Button form="form-BannerHome" type="primary" htmlType="submit" loading={loadingBannerHome}>
                        Update Banner Home
                    </Button>,
                ]}
            >
                {loadingBannerHome ? (
                    <>
                        <Skeleton loading={loadingBannerHome} active />
                    </>
                ) : (
                    <>{renderBannerHome()}</>
                )}
            </Modal>
            <Modal
                className="modalAttaction"
                width={700}
                open={openModalAttaction}
                title={
                    <span>
                        {statusAttaction === true ? (
                            <>
                                <EditOutlined /> Update Attaction
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new Attaction
                            </>
                        )}
                    </span>
                }
                onOk={handleOkAttaction}
                onCancel={handleCancelAttaction}
                footer={[
                    <Button key="back" onClick={handleCancelAttaction}>
                        Cancel
                    </Button>,
                    <Button form="form-Attaction" type="primary" htmlType="submit" loading={loadingAttaction}>
                        {statusAttaction === true ? <>Update Attaction</> : <>Add Attaction</>}
                    </Button>,
                ]}
            >
                {loadingAttaction ? (
                    <>
                        <Skeleton loading={loadingAttaction} active />
                    </>
                ) : (
                    <>{renderAttaction()}</>
                )}
            </Modal>
            <Modal
                className="modalServiceDetail"
                width={700}
                open={openModalServiceDetail}
                title={
                    <span>
                        {statusServiceDetail === true ? (
                            <>
                                <EditOutlined /> Update ServiceDetail
                            </>
                        ) : (
                            <>
                                <PlusOutlined /> Add new ServiceDetail
                            </>
                        )}
                    </span>
                }
                onOk={handleOkServiceDetail}
                onCancel={handleCancelServiceDetail}
                footer={[
                    <Button key="back" onClick={handleCancelServiceDetail}>
                        Cancel
                    </Button>,
                    <Button form="form-ServiceDetail" type="primary" htmlType="submit" loading={loadingServiceDetail}>
                        {statusServiceDetail === true ? <>Update ServiceDetail</> : <>Add ServiceDetail</>}
                    </Button>,
                ]}
            >
                {loadingServiceDetail ? (
                    <>
                        <Skeleton loading={loadingServiceDetail} active />
                    </>
                ) : (
                    <>{renderServiceDetail()}</>
                )}
            </Modal>
            <Modal
                className="modalSaleBanner"
                width={1000}
                open={openModalSaleBanner}
                title={
                    <span>

                        <EditOutlined /> Update Sale Banner

                    </span>
                }
                onOk={handleOkSaleBanner}
                onCancel={handleCancelSaleBanner}
                footer={[
                    <Button key="back" onClick={handleCancelSaleBanner}>
                        Cancel
                    </Button>,
                    <Button form="form-SaleBanner" type="primary" htmlType="submit" loading={loadingSaleBanner}>
                        <>Update Sale Banner </>
                    </Button>,
                ]}
            >
                {loadingSaleBanner ? (
                    <>
                        <Skeleton loading={loadingSaleBanner} active />
                    </>
                ) : (
                    <>{renderSaleBanner()}</>
                )}
            </Modal>

            <Modal
                className="modalExtraFooter"
                width={1000}
                open={openModalExtraFooter}
                title={
                    <span>

                        <EditOutlined /> Update Extra Footer

                    </span>
                }
                onOk={handleOkExtraFooter}
                onCancel={handleCancelExtraFooter}
                footer={[
                    <Button key="back" onClick={handleCancelExtraFooter}>
                        Cancel
                    </Button>,
                    <Button form="form-ExtraFooter" type="primary" htmlType="submit" loading={loadingExtraFooter}>
                        <>Update Extra Footer </>
                    </Button>,
                ]}
            >
                {loadingExtraFooter ? (
                    <>
                        <Skeleton loading={loadingExtraFooter} active />
                    </>
                ) : (
                    <>{renderExtraFooter()}</>
                )}
            </Modal>


            <Modal
                className="modalShareAction"
                width={1000}
                open={openModalShareAction}
                title={
                    <span>

                        <EditOutlined /> Update Share Action

                    </span>
                }
                onOk={handleOkShareAction}
                onCancel={handleCancelShareAction}
                footer={[
                    <Button key="back" onClick={handleCancelShareAction}>
                        Cancel
                    </Button>,
                    <Button form="form-ShareAction" type="primary" htmlType="submit" loading={loadingShareAction}>
                        <>Update Share Action </>
                    </Button>,
                ]}
            >
                {loadingShareAction ? (
                    <>
                        <Skeleton loading={loadingShareAction} active />
                    </>
                ) : (
                    <>{renderShareAction()}</>
                )}
            </Modal>
            <Modal
                className="modalEmail"
                width={700}
                open={openModalEmail}
                title={
                    <span>

                        <EditOutlined /> Update Email

                    </span>
                }
                onOk={handleOkEmail}
                onCancel={handleCancelEmail}
                footer={[
                    <Button key="back" onClick={handleCancelEmail}>
                        Cancel
                    </Button>,
                    <Button form="form-EmailInfo" type="primary" htmlType="submit" loading={loadingEmail}>
                        <>Update Email </>
                    </Button>,
                ]}
            >
                {loadingEmail ? (
                    <>
                        <Skeleton loading={loadingEmail} active />
                    </>
                ) : (
                    <>{renderEmail()}</>
                )}
            </Modal>
        </div>
    )
}
export default Manager;