import { Card, Col, Divider, Row, List, Image, Button, Avatar, Carousel, Form, Input } from "antd";

import ReactStars from 'react-stars'
import {
    TagOutlined, MonitorOutlined, CheckCircleOutlined, MoneyCollectOutlined, PhoneOutlined, DribbbleOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import React, { useRef, Fragment, useEffect, useState } from "react";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import SlideImage from "../SlideImage/slideimage";
import axios from "axios";
import SearchBar from "../SearchBar/searchbar";
const TourDetail = (language) => {

    const history = useHistory();
    const dataState = history.location.state;

    const clickPopular = (id, title, price) => {
        history.push(`/book/${title}`, id);
    }
    let idSubmenu = 0;
    let idMenu = 0;

    if (dataState !== undefined && dataState !== null) {
        idSubmenu = dataState.idSub !== undefined ? dataState.idSub : 0;
        idMenu = dataState.idMenu !== undefined ? dataState.idMenu : dataState;
    }
    const [dataTour, setDataTour] = useState([]);
    const [dataCategory, setDataCategory] = useState([]);
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '').then(res => {
            setDataCategory(res.data);
        })
    }
    const LoadDataSaleBanner = () => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/GetSaleBannerAll', '').then(res => {
            if (res.status === 200) {
                setDataSaleBanner(res.data);
            }
        })
    }
    const [dataSaleBanner, setDataSaleBanner] = useState([]);
    const serviceCategory = [
        {
            title: language === 'vi' ? 'Dịch vụ khác' : 'Other Services',
            src: 'https://topgotourist.vn/img/service/origin/full_topgotourist_dich-vu-tu-van-va-to-chuc-tour-du-lich-tron-goi-2021830141558.jpeg',
            id: 0
        }
    ]
    const Category = dataCategory.slice(0, 4).map(r => (
        {
            title: language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID
        }
    ))


    const clickCategory = (id, title) => {
        if (id === 0) {
            history.push('/service', id);
        }
        else {
            history.push(`/category`, id);
        }
    }
    const LoadDataTour = () => {
        const id = idMenu === 0 ? dataState : idMenu
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
            if (res.data.filter(i => i.IDMenu === dataState && i.IDSubMenu === 0).length > 1) {
                setDataTour(res.data.filter(i => i.IDMenu === dataState && i.IDSubMenu === 0))
            }
            else {
                setDataTour(res.data.filter(i => i.IDMenu === id && i.IDSubMenu === idSubmenu));
            }
        })
    }
    useEffect(() => {
        LoadDataTour();
        LoadDataCategory();
        LoadDataSaleBanner()
    }, [dataState])
    const data = dataTour.map(i => (
        {
            toptitle: i.TopTitle,
            titlesale: i.TitleSale,
            titletour: i.TitleTour,
            title: language === 'vi' ? String(i.Title).split(';')[1] : String(i.Title).split(';')[0],
            availability: i.Availability,
            duration: i.Duration,
            departsfrom: i.DepartsFrom,
            price: `${i.Price} USD`,
            src: String(i.Image).split(';')[0],
            rating: 5,
            reviewer: 15,
            id: i.ID // id tour
        }
    ))

    return (
        <div>

            {SearchBar(dataSaleBanner, language)}

            <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
                <Row gutter={[16, 16]}>
                    <Col style={{ marginTop: 5 }} span={24}>

                    </Col>
                    <Col xs={24} xl={24} span={24}>
                        <Row>
                            <Col xs={24} xl={6} span={5}>
                                <Row>
                                    <Col style={{ textAlign: 'center' }} span={24}>
                                        <MonitorOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                    </Col>
                                    <Col span={24}>
                                        {dataSaleBanner.map(r => (
                                            <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title1).split(';')[0] : String(r.Title1).split(';')[1]}</h6>
                                        ))}

                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={24} xl={6} span={5}>
                                <Row>
                                    <Col style={{ textAlign: 'center' }} span={24}>
                                        <DribbbleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                    </Col>
                                    <Col span={24}>
                                        {dataSaleBanner.map(r => (
                                            <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title2).split(';')[0] : String(r.Title2).split(';')[1]}</h6>
                                        ))}
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={24} xl={4} span={5}>
                                <Row>
                                    <Col style={{ textAlign: 'center' }} span={24}>
                                        <CheckCircleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                    </Col>
                                    <Col span={24}>
                                        {dataSaleBanner.map(r => (
                                            <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title3).split(';')[0] : String(r.Title3).split(';')[1]}</h6>
                                        ))}
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={24} xl={4} span={5}>
                                <Row>
                                    <Col style={{ textAlign: 'center' }} span={24}>
                                        <PhoneOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                    </Col>
                                    <Col span={24}>
                                        {dataSaleBanner.map(r => (
                                            <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title4).split(';')[0] : String(r.Title4).split(';')[1]}</h6>
                                        ))}
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={24} xl={4} span={4}>
                                <Row>
                                    <Col style={{ textAlign: 'center' }} span={24}>
                                        <MoneyCollectOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                    </Col>
                                    <Col span={24}>
                                        {dataSaleBanner.map(r => (
                                            <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title5).split(';')[0] : String(r.Title5).split(';')[1]}</h6>
                                        ))}
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginTop: 5 }} span={24}>

                    </Col>
                </Row>
            </div>
            <Card bodyStyle={{ backgroundColor: 'white', minHeight: 300, position: 'relative' }}>
                <Row gutter={[32, 8]}>
                    {Category.concat(serviceCategory).slice(0, 4).map(item => (
                        //xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2
                        <Col xs={24} xl={6} xxl={6} lg={6} sm={24} md={24} span={6}>
                            <Card onClick={(e) => clickCategory(item.id, item.title)} hoverable bodyStyle={{ backgroundImage: `url(${item.src})`, minWidth: 310, minHeight: 220, textAlign: 'center', backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                <Row>
                                    <Col style={{ height: 120 }} span={24}>
                                        <h4 style={{ marginTop: 60, color: 'white', textShadow: "0px  0px  10px  black" }}>{item.title}</h4>
                                    </Col>
                                    <Col span={24}>
                                        <Button onClick={(e) => clickCategory(item.id, item.title)} type="text">  <h6 onClick={(e) => clickCategory(item.id, item.title)} style={{ color: 'white', marginBottom: 30, textShadow: "0px  0px  10px  black" }}>See More </h6></Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </Card>
            <Card headStyle={{ backgroundColor: '#EFF1F4' }} title={<div style={{ textAlign: 'center' }}>
                <Row>
                    <Col span={24}>
                        <strong style={{ color: '#367AA7', fontSize: 25, fontWeight: 'bold' }}>POPULAR ACTIVITIES</strong>
                    </Col>

                </Row>
            </div>}>
                <List
                    grid={{ gutter: 16, column: 4, xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2 }}
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item onClick={(e) => clickPopular(item.id, item.title, item.price)}>
                            <Card bodyStyle={{ minHeight: 300, position: 'relative' }} headStyle={{ backgroundImage: `url(${item.src})`, backgroundSize: "cover", padding: 0 }} title={<div style={{ minWidth: 312, height: 220 }}>
                                <br />
                                <div style={{ borderTopRightRadius: 15, height: 36, borderBottomRightRadius: 15, color: 'white', width: 230, backgroundColor: '#367AA7', position: 'relative' }}>
                                    <span style={{ color: 'white', marginLeft: 5, position: 'absolute', top: 5 }}>{String(item.toptitle).toLocaleUpperCase()}</span>
                                </div>

                                <div style={{ width: 170, height: 35, borderTopRightRadius: 15, borderBottomRightRadius: 15, color: 'white', backgroundColor: '#F89810', marginTop: 10, position: 'relative' }}>
                                    <span style={{ color: 'white', marginLeft: 5, top: 5, position: 'absolute' }}>{String(item.titlesale).toLocaleUpperCase()}</span>
                                </div>
                            </div>} hoverable>
                                <div style={{ backgroundColor: 'white', borderRadius: 7, marginTop: -42, height: 32, textAlign: 'center', border: '1px solid gray' }}>
                                    <h6 style={{ color: 'rgb(66,130,172)', marginTop: 5, fontWeight: 'bold' }}>{String(item.titletour).toUpperCase()}</h6>
                                </div>
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <strong style={{ fontWeight: 'bold', fontSize: 16, textShadow: "0px  1px  1px  gray" }}>{String(item.title).toUpperCase()}</strong>
                                    </div>
                                    <Row>
                                        <Col xl={10} xs={10} span={10}>
                                            <ReactStars
                                                count={5}
                                                edit={false}
                                                value={item.rating}
                                                size={24}
                                                color2={'#ffd700'} />
                                        </Col>

                                        <Col style={{ marginTop: 9, color: 'gray' }} xl={14} xs={14} span={8}>
                                            {item.reviewer} REVIEWS
                                        </Col>
                                    </Row>

                                    <Row gutter={[8, 8]}>

                                        <Col span={7}>
                                            <Row>
                                                <Col span={24}>
                                                    <span style={{ fontSize: 12, color: '#367AA7', textShadow: "0px  1px  1px  gray" }}> AVAILABILITY</span>
                                                </Col>
                                                <Col span={24}>
                                                    <b style={{ fontSize: 12, color: '#367AA7', fontWeight: 'bold', textShadow: "0px  1px  1px  gray" }}>{item.availability}</b>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Divider style={{ height: 80, backgroundColor: '#367AA7', textShadow: "0px  1px  1px  gray" }} type="vertical" />
                                        <Col span={6}>
                                            <Row>
                                                <Col span={24}>
                                                    <span style={{ fontSize: 12, color: '#367AA7', textShadow: "0px  1px  1px  gray" }}>DURATION</span>
                                                </Col>
                                                <Col span={24}>
                                                    <b style={{ fontSize: 12, color: '#367AA7', fontWeight: 'bold', textShadow: "0px  1px  1px  gray" }}>{item.duration}</b>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Divider style={{ height: 80, backgroundColor: '#367AA7' }} type="vertical" />

                                        <Col span={7}>
                                            <Row>
                                                <Col span={24}>
                                                    <span style={{ fontSize: 12, color: '#367AA7', textShadow: "0px  1px  1px  gray" }}>DEPARTS FROM</span>
                                                </Col>
                                                <Col span={24}>
                                                    <b style={{ fontSize: 12, color: '#367AA7', fontWeight: 'bold', textShadow: "0px  1px  1px  gray" }}>{item.departsfrom}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row gutter={[64]} style={{ position: 'absolute', bottom: 20 }}>
                                        <Col span={12}>
                                            <Button style={{ backgroundColor: '#003D67', color: 'white', borderRadius: 7, width: 135, height: 35, textAlign: 'center' }}><strong style={{ color: 'white', fontWeight: 'bold' }}>{language === 'en' ? 'View Tour' : 'Xem Tour'}</strong></Button>
                                        </Col>
                                        <Col span={12}>
                                            <strong style={{ fontSize: 24, fontWeight: 'bold' }}>{item.price}</strong>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </List.Item>

                    )} />

                {/* <div style={{ textAlign: 'center' }}>
                    <Button style={{ backgroundColor: '#003D67', color: 'white', borderRadius: 7, width: 135, height: 35 }}>VIEW ALL</Button>
                </div> */}

            </Card>
        </div>
    )
}
export default TourDetail