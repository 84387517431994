import { Button, Card, Col, Image, Row, Steps, Avatar, Skeleton, Modal, Tabs, Divider, List, Calendar, Radio, InputNumber, Form, TimePicker, Input, Carousel, Layout, Select, DatePicker } from "antd";
import {
    HeartOutlined, TagOutlined, CheckSquareTwoTone, RightOutlined, FontColorsOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import ReactStars from "react-stars";
import countryMap from '../../common/countries/countries';
import { useCallback, useEffect, useState } from "react";
import swal from "sweetalert";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import "moment/locale/vi";
import dayjs from 'dayjs';
import ImageViewer from 'react-simple-image-viewer';
import './booktour.css';
import MenuFooter from "../Menu/menu";
import Translate from "../../common/Lang/translate";
const tableTour = `
<html>
<body>
<h2 style="text-align: center"> BOOKING DETAIL</h2>
<p style="text-align: right;font-style: italic">TOUR IDCODE:{Code}</p>
<font size="3" face="Verdana" >
<table style="border: 1px solid black; border-collapse: collapse;">
  <tr style="border: 1px solid black; padding: 10px;">
	<th style="border: 1px solid black; padding: 10px;">Type of Tour</th>
    <th style="border: 1px solid black; padding: 10px;">Name of primary guest</th>
    <th style="border: 1px solid black; padding: 10px;">Number of guest</th>
    <th style="border: 1px solid black; padding: 10px;">Number of Chidren < 120cm </th>
    <th style="border: 1px solid black; padding: 10px;">Number of Children < 100 cm </th>
    <th style="border: 1px solid black; padding: 10px;">Date of departure</th>
    <th style="border: 1px solid black; padding: 10px;">Pick up address</th>
  </tr>
  <tr style="border: 1px solid black; padding: 10px;">
     <td style="border: 1px solid black; padding: 10px;">{TypeofTour}</td>
    <td style="border: 1px solid black; padding: 10px;">{nameCustomer}</td>
	 <td style="border: 1px solid black; padding: 10px;">{NumberofParticipants}</td>
     <td style="border: 1px solid black; padding: 10px;">{NumberofChildren}</td>
    <td style="border: 1px solid black; padding: 10px;">{Numberofchildrenunder}</td>
     <td style="border: 1px solid black; padding: 10px;">{StartDay}</td>
    <td style="border: 1px solid black; padding: 10px;">{PickUpAddress}</td>
 <tfoot>
    <tr style="border: 1px solid black; padding: 10px;">
    <th >Note</th>
    <td style="border: 1px solid black; padding: 10px;" colspan="8">{SpecialRequest}</td>
    
    </tr>
  </tfoot>
  <tfoot>
    <tr style="border: 1px solid black; padding: 10px;">
      <th>Total</th>
      <td style="border: 1px solid black; padding: 10px; text-align: right;" colspan="8">{TotalPrice} USD</td>
    </tr>
  </tfoot>
  <tfoot>
    <tr style="border: 1px solid black; padding: 10px;">
    <th >Payment</th>
    <td style="border: 1px solid black; padding: 10px; text-align: right;" colspan="8">{MethodOfPayment}</td>
    </tr>
  </tfoot>
</table>
</font>
</body>
</html>
`
const emailconfirm = `

<html>

<body>

<h2 style="text-align: center">TOUR BOOKING INFORMATION - {Tourdeparture}</h2>

<p >Tour information - IDCODE:{Code}</p>
<table style="border-collapse: collapse;
  width: 100%">
  <tr>
   <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Type of Tour</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Pick Up Address</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Tour departure</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Method Payment</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Special Request</th>
   
 
  </tr>
  <tr>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{TypeTour}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{PickUpAddress}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Tourdeparture1}</td>
   <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{MethodPayment}</td>
  <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{SpecialRequest}</td>
  </tr>
 
</table>	
<p>Customer information</p>
<table style="border-collapse: collapse;
  width: 100%">
  <tr>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Guest's name</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Age</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Gender</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Contact</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Email</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Nationality</th>
    
  </tr>
  <tr>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{FullName}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Age}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Gender}</td>
   <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{PhoneCus}</td>
  <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{EmailCus}</td>
  <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Nationality}</td>
  </tr>
 
</table>
<p >Invoice</p>
<table style="border-collapse: collapse;
  width: 100%">
  <tr>
   <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Number of Guest</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Number of Children < 120</th>
    <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Number of Children < 100</th>
  <th style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">Total</th>
 
   
 
  </tr>
  <tr>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{NumberofGuest}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{NumberofChildren}</td>
    <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{Numberofchildrenunder}</td>
   <td style="padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;">{price}</td>
  
  </tr>
 
</table>	

</body>
</html>
`
const BookTour = (language) => {

    const history = useHistory();
    const dataState = history.location.state;
    const [formBook] = Form.useForm();
    const [openModalBook, setOpenModalBook] = useState(false);
    const [loadingBook, setLoadingBook] = useState(false);
    const [form] = Form.useForm();
    const [dataTour, setDataTour] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [detailCalendar, setDetailCalendar] = useState('');
    const [clickButtonCart, setClickButtonCart] = useState(false);
    const [opendetailCalendar, setOpenDetailCalendar] = useState(false);
    const [dataTourID, setDataTourID] = useState([]);
    const [countAge, setCountAge] = useState(0);
    const [arrayImage, setArrayImage] = useState([]);
    const [priceAge, setPriceAge] = useState(0);
    const [priceParticipant, setPriceParticipant] = useState(1);
    const [timeStart, setTimeStart] = useState('');
    const [dataCompany, setDataCompany] = useState([]);

    const [dataEmail, setDataEmail] = useState([]);
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);

    const LoadDataEmail = () => {
        axios.post('https://dgss.vn:7001/api/Email/GetEmailAll', '').then(res => {
            if (res.status === 200) {
                setDataEmail(res.data);
            }
        })
    }
    const clickPopular = (id, title) => {
        history.push(`/book/${title}`, id);
        //window.location.href = `/book/${title}`;
    }
    const [dataHighlight, setDataHighlight] = useState([]);
    const [datainClude, setDataInclude] = useState([]);
    const [dataBring, setDataBring] = useState([]);
    const [valueNumberGuest, setValueNumberGuest] = useState(1);
    const onChangeNumberGuest = (value) => {
        setValueNumberGuest(value)
    }
    const [valueNumberChildren, setValueNumberChildren] = useState(0);
    const onChangeNumberChildren = (value) => {
        setValueNumberChildren(value)
    }
    const [valueNumberChildrenUnder, setValueNumberChildrenUnder] = useState(0);
    const onChangeNumberChildrenUnder = (value) => {
        setValueNumberChildrenUnder(value)
    }
    const dataTotalPrice = [
        {
            title: 'Type of tour',
            sub: null,
            value: dataTourID.map(r => language === 'vi' ? String(r.Title).split(';')[1] : String(r.Title).split(';')[0]),
            quality: 1
        },
        {
            title: 'Price Tour',
            sub: null,
            value: dataTourID.map(r => r.Price),
            quality: 1
        },
        {
            title: 'Number of Guest',
            sub: null,
            value: valueNumberGuest,
        },
        {
            title: 'Number of Children < 120 cm ',
            sub: '(50% off tour price)',
            value: valueNumberChildren,
        },
        {
            title: `Number of Children < 100 cm`,
            sub: '(Free of charge, 2 adult bears 1 child, the 2nd child will be charged an additional of 10% tour price. Parent pays all expenses for these child on the tour)',
            value: valueNumberChildrenUnder,
        },
        {
            title: 'TOTAL',
            sub: null,
            value: `${Number(dataTourID.map(r => r.Price)[0]) * valueNumberGuest + totalPrice + valueNumberChildren * (Number(dataTourID.map(r => r.Price)[0]) * 0.5) + (valueNumberChildrenUnder > 1 ? (valueNumberChildrenUnder - 1) * (Number(dataTourID.map(r => r.Price)[0]) * 0.1) : 0)} USD`,
            quality: 1
        },
    ];
    const [dataInfo, setDataInfo] = useState([]);
    const [titleBookTour, setTitleBookTour] = useState('');
    const [loadingImage, setLoadingImage] = useState(false);
    const LoadTourbyID = () => {
        setLoadingImage(true)
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
            const IDCategory = Number(res.data.filter(i => i.ID === dataState)[0].IDCategory);
            const serviceTour = res.data.filter(i => i.ID === dataState).map(r => r.Services);
            const arrayServiceTour = String(serviceTour[0]).split(';')
            // debugger;
            // console.log(arrayServiceTour)
            axios.post('https://dgss.vn:7001/api/Services/GetServicesAll', '').then(resS => {
                // setDataService(resS.data.sort((a, b) => b.ID > a.ID ? 1 : -1));
                const dataTemp = resS.data;

                const arrayService = []
                arrayServiceTour.map(i => {
                    dataTemp.filter(j => j.ID === Number(i)).map(r => {
                        arrayService.push(r)

                    });
                });

            })
            setDataTourID(res.data.filter(i => i.ID === dataState));
            const arrayInfo = res.data.filter(i => i.ID === dataState)[0].Peradult;
            const titleBook = res.data.filter(i => i.ID === dataState)[0].DateOfTravel;
            setTitleBookTour(titleBook === '' ? 'MEET ON LOCATION' : titleBook)
            setDataInfo(String(arrayInfo).split(';'))
            setDataHighlight(res.data.filter(i => i.ID === dataState).map(r => String(r.Highlights).split(';'))[0])
            setDataInclude(res.data.filter(i => i.ID === dataState).map(r => String(r.Included).split(';'))[0])
            setDataBring(res.data.filter(i => i.ID === dataState).map(r => String(r.Bring).split(';'))[0])
            setTotalPrice(res.data.filter(i => i.ID === dataState)[0].Price);

            setDataTour(res.data.filter(i => i.Status === true && i.IDCategory === IDCategory));
            const StringImage = String(res.data.filter(i => i.ID === dataState)[0].Image);
            const arrImage = StringImage.split(';');
            // setFirstImage(arrImage[0])
            // arrImage.shift();
            setArrayImage(arrImage);
            setLoadingImage(false)
        })
    }
    const changeExtra = (e, id, price) => {

        const tmpArrService = serviceDetail;
        if (tmpArrService.filter(i => i.IDService === id).length > 0) {
            const removeIndex = tmpArrService.findIndex(item => item.IDService === id);
            tmpArrService.splice(removeIndex, 1);
            tmpArrService.push({
                IDService: id,
                Quality: Number(e.currentTarget.value),
                Price: price * Number(e.currentTarget.value)
            });
        }
        else {
            tmpArrService.push({
                IDService: id,
                Quality: Number(e.currentTarget.value),
                Price: price * Number(e.currentTarget.value)
            });

        }
        setServiceDetail(tmpArrService);
        const tmpArr = arrayPriceTemp
        if (tmpArr.filter(i => i.ID === id).length > 0) {
            const removeIndex = tmpArr.findIndex(item => item.ID === id);
            tmpArr.splice(removeIndex, 1);
            tmpArr.push({
                ID: id,
                price: price * Number(e.currentTarget.value)
            })
        }
        else {
            tmpArr.push({
                ID: id,
                price: price * Number(e.currentTarget.value)
            })

        }
        //console.log(arrayPriceTemp.map(r => r.price))
        setArrayPriceTemp(tmpArr)
        sumArray(tmpArr.map(r => r.price))
        setStatusPrice(true);
    }

    function sumArray(array) {
        let sum = 0;
        for (let i = 0; i < array.length; i++) {
            sum += array[i];
        }
        //console.log(sum);
        setTotalPrice(Number(sum));
        // fillPrice(dataBookByID.TotalPrice + Number(sum), dataBookByID.ID);
        return sum;
    }
    const [statusSticky, setStatusSticky] = useState(true);

    const isSticky = (e) => {
        const header = document.querySelector(".header-section-book");
        const scrollTop = window.scrollY;
        if (scrollTop >= 500) {
            //setToggle(true);
            header.classList.add("is-sticky");
            setStatusSticky(false)
        }
        else {
            //setToggle(false);
            setStatusSticky(true)
            header.classList.remove("is-sticky")
            //header.classList.add("is-sticky");
        }
    };
    const [statusPrice, setStatusPrice] = useState(false);
    const [arrayPriceTemp, setArrayPriceTemp] = useState([])
    const [serviceDetail, setServiceDetail] = useState([]);
    const onFinishBook = (values) => {
        // thêm khách hàng rồi add tour

        if (values.EmailCus === values.confirmEmailCus) {
            setLoadingBook(true);
            const money = dataTotalPrice.filter(i => i.title === 'TOTAL');
            const totalMoney = money.map(r => String(r.value).split(' USD')[0])
            axios.post('https://dgss.vn:7001/api/Customers/AddCustomers', {
                "ID": 1,
                "FullName": values.FullName,
                "Nationality": values.Nationality,
                "Age": values.Age,
                "Gender": values.Gender,
                "Birthday": "",
                "Email": values.EmailCus,
                "Phone": values.PhoneCus,
                "Whatapp": "",
                "Address": ""
            }).then(res => {
                if (res.status === 200) {
                    let idCus = 0;
                    let email = '';
                    let name = '';
                    let phone = '';
                    Array(res.data).forEach(({ ID, Email, Phone, FullName }) => {
                        idCus = ID;
                        email = Email;
                        name = FullName;
                        phone = Phone
                    });
                    axios.post('https://dgss.vn:7001/api/BookTour/AddBookTour', {
                        "ID": 1,
                        "IDTour": values.IDTour,
                        "StartDay": values.Tourdeparture,
                        "TotalPrice": Number(totalMoney[0]),
                        "NumberofParticipants": values.NumberofGuest === undefined ? 1 : values.NumberofGuest,
                        "NumberofChildren": values.NumberofChildren === undefined ? 0 : values.NumberofChildren,
                        "Numberofchildrenunder": values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder,
                        "PickUpAddress": values.PickUpAddress,
                        "IDServices": 0,
                        "IDCustomer": idCus,
                        "MethodOfPayment": values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment,
                        "SpecialRequest": values.SpecialRequest
                    }).then(resTour => {
                        let idBook = 0;
                        let starday = '';
                        let totalPrice = 0;
                        Array(resTour.data).forEach(({ ID, StartDay, TotalPrice }) => {
                            idBook = ID;
                            starday = moment(StartDay).format('YYYY/MM/DD HH:mm');
                            totalPrice = TotalPrice
                        });
                        if (statusPrice === true) {
                            serviceDetail.map(i => (
                                axios.post('https://dgss.vn:7001/api/ServiceDetail/AddServiceDetail', {
                                    "ID": 1,
                                    "IDService": i.IDService,
                                    "IDBookTour": idBook,
                                    "Quality": i.Quality,
                                    "Price": i.Price,
                                    "Note": ""
                                }).then(reservice => {
                                    const nameCustomer = name;
                                    const price = Number(totalMoney[0]);
                                    const dayBookTour = moment(values.Tourdeparture).format('YYYY/MM/DD HH:mm');
                                    const contentReplace = String(dataEmail.filter(i => i.ID === 1).map(r => r.content)[0]).replace('{nameCustomer}', nameCustomer).replace('{price}', price).replace('{phone}', phone).replace('{dayBookTour}', dayBookTour);
                                    const pluginEmail = String(tableTour).replace('{TypeofTour}', dataTourID.map(r => String(r.Title).split(';')[0])).replace('{nameCustomer}', nameCustomer).replace('{NumberofParticipants}', values.NumberofGuest === undefined ? 1 : values.NumberofGuest).replace('{NumberofChildren}', values.NumberofChildren === undefined ? 0 : values.NumberofChildren).replace('{Numberofchildrenunder}', values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder).replace('{StartDay}', moment(values.Tourdeparture).format('YYYY/MM/DD')).replace('{PickUpAddress}', values.PickUpAddress).replace('{TotalPrice}', price).replace('{MethodOfPayment}', values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment).replace('{Code}', `${values.IDTour}${idBook}`).replace('{SpecialRequest}', values.SpecialRequest === undefined ? '' : values.SpecialRequest)
                                    axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                                        "ID": 1,
                                        "toEmail": email,
                                        "subject": dataEmail.filter(i => i.ID === 1).map(r => r.subject)[0],
                                        "content": contentReplace + pluginEmail,
                                        "alert": dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0]
                                    })
                                    swal(dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0], {
                                        icon: 'success',
                                        timer: 10000
                                    });
                                    const emailconfirmcontent = String(emailconfirm).replace('{TypeofTour}', dataTourID.map(r => String(r.Title).split(';')[0])).replace('{TypeTour}', values.TypeTour).replace('{NumberofGuest}', values.NumberofGuest === undefined ? 1 : values.NumberofGuest).replace('{NumberofChildren}', values.NumberofChildren === undefined ? 0 : values.NumberofChildren).replace('{Numberofchildrenunder}', values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder).replace('{Tourdeparture}', moment(values.Tourdeparture).format('YYYY/MM/DD')).replace('{PickUpAddress}', values.PickUpAddress).replace('{price}', price).replace('{MethodPayment}', values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment).replace('{Code}', `${values.IDTour}${idBook}`).replace('{SpecialRequest}', values.SpecialRequest === undefined ? '' : values.SpecialRequest).replace('{Age}', values.Age).replace('{Gender}', values.Gender).replace('{PhoneCus}', values.PhoneCus).replace('{EmailCus}', values.EmailCus).replace('{Nationality}', countryMap.filter(i => i.code === values.Nationality).map(r => r.name)[0]).replace('{FullName}', values.FullName).replace('{Tourdeparture1}', moment(values.Tourdeparture).format('YYYY/MM/DD'))
                                    axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                                        "ID": 1,
                                        "toEmail": 'nomadictravelvn@gmail.com',
                                        "subject": dataEmail.filter(i => i.ID === 1).map(r => r.subject)[0],
                                        "content": emailconfirmcontent,
                                        "alert": 'New Email Book Tour'
                                    })

                                    setStatusPrice(false);
                                    setServiceDetail([])
                                    setTotalPrice(0)
                                    setLoadingBook(false);
                                    setOpenModalBook(false)
                                })
                            ))
                        }
                        else {
                            if (resTour.status === 200) {
                                const nameCustomer = name;
                                const price = Number(totalMoney[0]);
                                const dayBookTour = moment(values.Tourdeparture).format('YYYY/MM/DD HH:mm');
                                const contentReplace = String(dataEmail.filter(i => i.ID === 1).map(r => r.content)[0]).replace('{nameCustomer}', nameCustomer).replace('{price}', price).replace('{phone}', phone).replace('{dayBookTour}', dayBookTour);
                                const pluginEmail = String(tableTour).replace('{TypeofTour}', dataTourID.map(r => String(r.Title).split(';')[0])).replace('{nameCustomer}', nameCustomer).replace('{NumberofParticipants}', values.NumberofGuest === undefined ? 1 : values.NumberofGuest).replace('{NumberofChildren}', values.NumberofChildren === undefined ? 0 : values.NumberofChildren).replace('{Numberofchildrenunder}', values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder).replace('{StartDay}', moment(values.Tourdeparture).format('YYYY/MM/DD')).replace('{PickUpAddress}', values.PickUpAddress).replace('{TotalPrice}', price).replace('{MethodOfPayment}', values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment).replace('{Code}', `${values.IDTour}${idBook}`).replace('{SpecialRequest}', values.SpecialRequest === undefined ? '' : values.SpecialRequest)
                                axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                                    "ID": 1,
                                    "toEmail": email,
                                    "subject": dataEmail.filter(i => i.ID === 1).map(r => r.subject)[0],
                                    "content": contentReplace + pluginEmail,
                                    "alert": dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0]
                                })
                                swal(dataEmail.filter(i => i.ID === 1).map(r => r.alert)[0], {
                                    icon: 'success',
                                    timer: 10000
                                });
                                const emailconfirmcontent = String(emailconfirm).replace('{TypeofTour}', dataTourID.map(r => String(r.Title).split(';')[0])).replace('{TypeTour}', values.TypeTour).replace('{NumberofGuest}', values.NumberofGuest === undefined ? 1 : values.NumberofGuest).replace('{NumberofChildren}', values.NumberofChildren === undefined ? 0 : values.NumberofChildren).replace('{Numberofchildrenunder}', values.Numberofchildrenunder === undefined ? 0 : values.Numberofchildrenunder).replace('{Tourdeparture}', moment(values.Tourdeparture).format('YYYY/MM/DD')).replace('{PickUpAddress}', values.PickUpAddress).replace('{price}', price).replace('{MethodPayment}', values.MethodOfPayment === undefined ? 'Cash' : values.MethodOfPayment).replace('{Code}', `${values.IDTour}${idBook}`).replace('{SpecialRequest}', values.SpecialRequest === undefined ? '' : values.SpecialRequest).replace('{Age}', values.Age).replace('{Gender}', values.Gender).replace('{PhoneCus}', values.PhoneCus).replace('{EmailCus}', values.EmailCus).replace('{Nationality}', countryMap.filter(i => i.code === values.Nationality).map(r => r.name)[0]).replace('{FullName}', values.FullName).replace('{Tourdeparture1}', moment(values.Tourdeparture).format('YYYY/MM/DD'))
                                axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                                    "ID": 1,
                                    "toEmail": 'nomadictravelvn@gmail.com',
                                    "subject": dataEmail.filter(i => i.ID === 1).map(r => r.subject)[0],
                                    "content": emailconfirmcontent,
                                    "alert": 'New Email Book Tour'
                                })
                                formBook.resetFields();
                                setLoadingBook(false);
                                setOpenModalBook(false)
                                setServiceDetail([])
                                setTotalPrice(0)
                            }
                        }
                    })
                }
            })
        }
        else {
            swal('Emails do not match. Please re-enter!', {
                icon: 'warning',
                timer: 5000,

            })
        }
    }
    const handleOkBook = () => {

    }
    const handleCancelBook = () => {
        setOpenModalBook(false)
    }

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);


    const renderBook = () => {
        return (
            <Form
                id="form-Book"
                form={formBook}
                onFinish={onFinishBook}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} size="small" title='Company Information'>
                    <Form.Item hidden name={'ID'} label='Company Name'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name={'Price'} label='Price'>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'CompanyName'} label='Company Name'>
                        <Input style={{ fontWeight: 'bold' }} bordered={false} type="text" readOnly />
                    </Form.Item>
                    <Form.Item name={'Email'} label='Email'>
                        <Input style={{ fontWeight: 'bold' }} bordered={false} type="text" readOnly />
                    </Form.Item>


                    <Form.Item name={'Phone'} label='Phone'>
                        <Input style={{ fontWeight: 'bold' }} bordered={false} type="text" readOnly />
                    </Form.Item>
                    <Form.Item name={'Address'} label='Address'>
                        <Input style={{ fontWeight: 'bold' }} bordered={false} type="text" readOnly />
                    </Form.Item>
                    <p style={{ fontSize: 20, fontWeight: 'inherit', textAlign: 'center', color: '#003D67', fontFamily: "italic" }}>Kindly provide us your information in the form below to complete the reservation</p>
                </Card>
                <Row>
                    <Col xs={24} xl={24} span={12}>
                        <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} size="small" title='Customer Information'>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Input Your Name',
                                },
                            ]} name={'FullName'} label={`Guest's name`}>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Input Your Age',
                                },
                            ]} name={'Age'} label='Age'>
                                <InputNumber style={{ width: '100%' }} min={10} maxLength={2} />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Choose Gender',
                                },
                            ]} name={'Gender'} label='Gender'>
                                <Select>
                                    <Select.Option value='Male'>Male</Select.Option>
                                    <Select.Option value='Female'>Female</Select.Option>
                                    <Select.Option value='Other'>Other</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Input Your Contact',
                                },
                            ]} name={'PhoneCus'} label='Contact'>
                                <Input placeholder="Phone/ Whatsapp/ Zalo..." />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: Translate(language, 'message_input_email'),
                                    type: "email",
                                },
                            ]} name={'EmailCus'} label='Email'>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: Translate(language, 'message_input_email'),
                                    type: "email",
                                },
                            ]} name={'confirmEmailCus'} label='Confirm Email'>
                                <Input />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Choose Nationality',
                                },
                            ]} name={'Nationality'} label='Nationality'>
                                <Select showSearch >
                                    {countryMap.map(r => (
                                        <Select.Option value={r.code}>{r.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={24} xl={24} span={12}>
                        <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} size="small" title='Tour Information'>
                            <Form.Item name={'TypeTour'} label='Type of Tour'>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: false,
                                    message: 'Choose number of guest',
                                },
                            ]} name={'NumberofGuest'} label='Number of Guest'>
                                <InputNumber style={{ width: '100%' }} defaultValue={1} onChange={onChangeNumberGuest} min={1} />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: false,
                                    message: 'Choose number of children ',
                                },
                            ]} name={'NumberofChildren'} label='Children < 120 cm'>
                                <InputNumber onChange={onChangeNumberChildren} style={{ width: '100%' }} placeholder="Number of children (under 120 cm tall)" min={0} />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: false,
                                    message: 'Choose number of children ',
                                },
                            ]} name={'Numberofchildrenunder'} label='Children < 100 cm'>
                                <InputNumber onChange={onChangeNumberChildrenUnder} style={{ width: '100%' }} placeholder="Number of children (under 120 cm tall)" min={0} />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Input pickup address ',
                                },
                            ]} name={'PickUpAddress'} label='Pick Up Address'>
                                <Input placeholder="Pick up address" />
                            </Form.Item>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Choose date',
                                },
                            ]} name={'Tourdeparture'} label='Tour departure'>
                                <DatePicker disabledDate={(current) => {
                                    let customDate = moment().format("DD-MM-YYYY");
                                    return current && current < moment(customDate, "DD-MM-YYYY");
                                }} format={"YYYY/MM/DD HH:mm"} style={{ width: '100%' }} showTime={{ format: "HH:mm" }} />
                            </Form.Item>
                            <Form.Item name={'MethodPayment'} label='Method of Payment'>
                                <Select defaultValue={'Cash'}>
                                    <Select.Option value='Transfer'>Transfer</Select.Option>
                                    <Select.Option value='Cash'>Cash</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name={'SpecialRequest'} label='Special Request'>
                                <Input.TextArea placeholder="Foods, Allergic…" />
                            </Form.Item>
                            <Form.Item hidden name={'IDTour'}>
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} title={<span>
                            <strong>YOUR INVOICE</strong>
                        </span>}>
                            {dataTotalPrice.map(r => (
                                <Row style={{ textAlign: 'left' }}>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={1}>

                                            </Col>
                                            <Col span={23}>
                                                <b style={r.title === 'TOTAL' ? { fontSize: 18, color: '#1890FF' } : { fontSize: 18 }}>{r.title} <p style={{ fontStyle: 'italic', fontSize: 16 }}>{r.sub}</p></b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row>
                                            <Col span={22}>
                                                <p style={r.title === 'TOTAL' ? { fontSize: 18, textAlign: 'right', color: '#1890FF' } : { fontSize: 18, textAlign: 'right' }}>{r.value}</p>
                                            </Col>
                                            <Col span={2}>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Divider type="horizontal" />
                                </Row>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </Form>
        )
    }

    const LoadDataCompany = () => {
        axios.post('https://dgss.vn:7001/api/Company/GetCompanyAll', '').then(res => {
            setDataCompany(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }

    useEffect(() => {
        if (pathnames.includes('book')) {
            LoadDataEmail();
            LoadTourbyID();
            LoadDataCompany();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }
    }, [dataState])
    const onChangeAge = (value) => {
        const coutAge = value.currentTarget.value;
        const price = dataTourID.map(r => r.Price);
        setPriceAge(Number(price[0]) * Number(coutAge))
    }
    const onChangeParticipants = (value) => {
        const coutParticipants = value.currentTarget.value;
        setPriceParticipant(coutParticipants)
    }
    const datapopular = dataTour.filter(i => i.ID !== dataState).map(i => (
        {
            toptitle: i.TopTitle,
            titlesale: i.TitleSale,
            titletour: i.TitleTour,
            title: language === 'vi' ? String(i.Title).split(';')[1] : String(i.Title).split(';')[0],
            availability: i.Availability,
            duration: i.Duration,
            departsfrom: i.DepartsFrom,
            price: `${i.Price} USD`,
            src: String(i.Image).split(';')[0],
            rating: 5,
            reviewer: 20,
            id: i.ID // id tour
        }
    ))
    const datatours = [
        {
            title: 'AVAILABILITY',
            value: dataTourID.map(r => r.Availability)
        },
        {
            title: 'DURATION',
            value: dataTourID.map(r => r.Duration)
        },
        {
            title: 'DEPARTS FROM',
            value: dataTourID.map(r => r.DepartsFrom)
        },
        {
            title: 'DIFFICULTY',
            value: dataTourID.map(r => r.Difficulty)
        },
        {
            title: 'MINIMUM AGE',
            value: dataTourID.map(r => r.Minimumage)
        },
        {
            title: 'END AT',
            value: '1:6'
        },
        {
            title: 'MEET ON LOCATION',
            value: dataTourID.map(r => r.MeetonLocation === false ? 'no' : 'yes')
        },
        {
            title: 'PICK UP',
            value: dataTourID.map(r => r.Pickup === false ? 'no' : 'yes')
        },
    ]
    const datainfobook = dataInfo.map(r => (
        {
            title: r
        }
    ))
    const onPanelChange = (value, mode) => {
        //console.log(value.format('YYYY-MM-DD'), mode);
    };
    const selectCalendar = (datetime) => {
        setOpenDetailCalendar(true);
        setDetailCalendar(datetime.format('YYYY-MM-DD'));
        form.setFieldsValue({
            Price: priceAge + (priceParticipant * totalPrice),
            timestart: moment()
        })
    }

    const showModalBook = () => {
        setTotalPrice(0)
        setOpenModalBook(true);
        formBook.setFieldsValue(
            {
                "CompanyName": dataCompany[0].CompanyName,
                "TaxCode": dataCompany[0].TaxCode,
                "Address": dataCompany[0].Address,
                "Phone": dataCompany[0].Phone,
                "Email": dataCompany[0].Email,
                "LegalRepresentative": dataCompany[0].LegalRepresentative,
                "ID": dataCompany[0].ID,
                "IDTour": dataTourID[0].ID,
                "TypeTour": language === 'vi' ? String(dataTourID[0].Title).split(';')[1] : String(dataTourID[0].Title).split(';')[0],
                "Price": dataTourID[0].Price
            }
        );
    }
    const onFinish = (values) => {

        const Children = values.Age === undefined ? 0 : Number(values.Age);
        const Participants = values.NumberofParticipants === undefined ? 1 : Number(values.NumberofParticipants);
        //2023-07-24T16:10:42.5682853+07:00
        // xử lý múi giờ bị sai
        const timedate = moment(values.timestart).format('HH:mm');
        let time = detailCalendar + 'T' + timedate + '+07:00';
        if (opendetailCalendar === true) {
            axios.post('https://dgss.vn:7001/api/BookTour/AddBookTour', {
                ID: 1,
                IDTour: dataState,
                StartDay: time,
                TotalPrice: values.Price * Participants + values.Price * Children,
                NumberofParticipants: Participants,
                NumberofChildren: Children,
                Numberofchildrenunder: 0,
                MethodOfPayment: "Cash",
                IDServices: 0,
                IDCustomer: 0,
                PickUpAddress: ''

            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem('statusbook', true);
                    let id = res.data.ID;
                    sessionStorage.setItem('idBook', Number(id));
                    if (statusCart === true) {
                        setStatusCart(false);
                        localStorage.setItem('age', countAge);
                        localStorage.setItem('price', totalPrice);
                        sessionStorage.setItem('badge', 1);
                        swal('Tour was added to cart', {
                            buttons: false,
                            timer: 5000,
                            icon: 'success'
                        })
                    }
                    else {
                        history.push(`/cart`, id);
                    }
                }
            })
        }
    }
    const [statusCart, setStatusCart] = useState(false);
    const onChangeButton = (value) => {
        setStatusCart(true)
    }
    return (
        <div style={{ backgroundColor: 'white' }}>
            <Form form={form} onFinish={onFinish} id="form-booktour">
                {loadingImage ? (
                    <>
                        <Skeleton loading={loadingImage} active />
                    </>
                ) : (
                    <Row className="ImageTour">
                        <Col xs={24} xl={0} span={24}>
                            <Image.PreviewGroup>
                                <Layout style={{ backgroundColor: 'white', }}>
                                    <Carousel infinite={false} autoplay>
                                        {arrayImage.map(r => (
                                            <Image style={{ width: '100%' }} src={r} />
                                        ))}
                                    </Carousel>
                                </Layout>
                            </Image.PreviewGroup>
                        </Col>
                        <Col xs={0} xl={24} xxl={24} md={24} span={24}>
                            <Image.PreviewGroup>
                                <Layout style={{ backgroundColor: 'white', }}>
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <Image style={{ minWidth: 650, height: 504 }} src={arrayImage[0]} />
                                        </Col>
                                        <Col span={12}>
                                            <Row gutter={[8, 8]}>
                                                {arrayImage.filter((i, index) => index !== 0).slice(0, 5).map(r => (
                                                    <Col span={12}>
                                                        <Image style={{ minWidth: 323, height: 248 }} src={r} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                        <Col xs={0} xl={24} span={24}>
                                            <Button onClick={(e) => openImageViewer(0)} style={{ borderRadius: 6, backgroundColor: '#F89A15', color: 'white', fontSize: 14, height: 35, top: -50, marginLeft: '91%' }}>SHOW ALL</Button>
                                        </Col>
                                    </Row>
                                </Layout>
                            </Image.PreviewGroup>
                            {isViewerOpen && (
                                <ImageViewer
                                    src={arrayImage}
                                    currentIndex={currentImage}
                                    disableScroll={false}
                                    closeOnClickOutside={true}
                                    onClose={closeImageViewer}
                                />
                            )}
                        </Col>

                    </Row>
                )}
                <Row className="header-section-book">
                    <Col hidden={statusSticky === true ? true : false} span={24}>
                        {MenuFooter(dataState, language)}
                    </Col>
                    <Col xs={24} xl={20} span={20}>
                        <Row>
                            <Col style={{ marginLeft: 20 }} span={24}>
                                <Row>
                                    <Col xl={12} xs={24} span={12}>
                                        {dataTourID.map(r => (
                                            <strong style={{ fontSize: 35 }}>{r.TopTitle}</strong>
                                        ))}
                                    </Col>
                                    <Col style={{ marginTop: 10 }} xs={0} xl={12} span={12}>
                                        <Button onClick={(e) => showModalBook(e)} style={{ borderRadius: 10, backgroundColor: '#F89A15', color: 'white', fontSize: 18, height: 40 }}>BOOK NOW</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ marginLeft: 20 }} span={24}>
                                {dataTourID.map(r => (
                                    <span style={{ fontSize: 16 }}>{language === 'vi' ? <span>
                                        <strong style={{ float: 'left' }}>{String(r.Title).split(';')[1] + '         ' + '5' + '       '}</strong>
                                        <div style={{ float: 'center', marginTop: -7 }}>
                                            <ReactStars
                                                count={5}
                                                edit={false}
                                                value={5}
                                                size={24}
                                                color2={'#ffd700'} />
                                        </div>

                                    </span> : <span>
                                        <strong style={{ float: 'left' }}>{String(r.Title).split(';')[0] + '       ' + '5' + '     '}</strong>
                                        <div style={{ float: 'center', marginTop: -7 }}>
                                            <ReactStars
                                                count={5}
                                                edit={false}
                                                value={5}
                                                size={24}
                                                color2={'#ffd700'} />
                                        </div>

                                    </span>} </span>
                                ))}
                            </Col>
                            <Col style={{ marginLeft: 20 }} span={24}>

                                {dataTourID.map(r => (
                                    <p style={{ color: 'gray', fontSize: 16, fontFamily: 'unset' }}>Product-ID: {language === 'vi' ? String(r.Title).split(';')[1] : String(r.Title).split(';')[0]}-{r.ID}</p>
                                ))}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} xl={0} span={4}>
                        <Row>
                            <Col style={{ marginLeft: 15 }} span={24}>
                                <Button onClick={(e) => showModalBook(e)} style={{ borderRadius: 10, backgroundColor: '#F89A15', color: 'white', fontSize: 18, height: 40 }}>BOOK NOW</Button>
                            </Col>
                            <Col xs={24} xl={0} span={24}>
                                <br />
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row>
                    <Col span={24}>
                        <Divider type="horizontal" />
                    </Col>
                    <Col span={2}>

                    </Col>
                    <Col xs={22} xl={12} span={12}>
                        <Row>
                            <Col span={24}>
                                <strong style={{ fontSize: 25 }}>ABOUT TOUR</strong>
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col style={{ color: 'grey' }} span={24}>
                                {dataTourID.map(r => (
                                    <p style={{ color: 'gray', fontSize: 17, fontFamily: 'unset' }}>{r.AboutTour}</p>
                                ))}
                            </Col>
                            <Divider type="horizontal" />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <b style={{ fontSize: 25 }}>HIGHLIGHTS</b>
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col style={{ color: 'gray' }} span={24}>
                                <List
                                    grid={{ gutter: 16, column: 3, xs: 1 }}
                                    itemLayout="vertical"
                                    dataSource={dataHighlight}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={item !== '' ? '●' : null}
                                                description={<p style={{ color: 'gray', fontSize: 17, fontFamily: 'unset' }}>{item}</p>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                            <Divider type="horizontal" />
                        </Row>
                        <Row>
                            <Col xs={24} xl={24} span={24}>
                                <Row>
                                    <Col xs={24} xl={12} span={12}>
                                        <Row>
                                            <Col xs={24} xl={24} span={24}>
                                                <b style={{ fontSize: 25 }}>INCLUDED</b>
                                            </Col>
                                            <Col span={24}>
                                                <br />
                                            </Col>
                                            <Col xs={24} xl={24} span={24}>
                                                <List
                                                    grid={{ gutter: 16, column: 1, xs: 1 }}
                                                    itemLayout="vertical"
                                                    dataSource={datainClude}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                avatar={item !== '' ? <CheckOutlined style={{ color: '#2ECC71' }} /> : null}
                                                                description={<p style={{ color: 'gray', fontSize: 17, fontFamily: 'unset' }}>{item}</p>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} xl={12} span={12}>

                                        <Row>
                                            <Col xs={24} xl={24} span={24}>
                                                <b style={{ fontSize: 25 }}>WHAT TO BRING</b>
                                            </Col>
                                            <Col span={24}>
                                                <br />
                                            </Col>
                                            <Col xs={24} xl={24} span={24}>
                                                <List
                                                    grid={{ gutter: 16, column: 1, xs: 1 }}
                                                    itemLayout="vertical"
                                                    dataSource={dataBring}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                avatar={item !== '' ? <CheckOutlined style={{ color: '#2ECC71' }} /> : null}
                                                                description={<p style={{ color: 'gray', fontSize: 17, fontFamily: 'unset' }}>{item}</p>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider type="horizontal" />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <b style={{ fontSize: 25 }}>TOUR SNAPSHOT</b>
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={24}>
                                <List
                                    grid={{ gutter: 16, column: 3, xs: 1 }}
                                    itemLayout="vertical"
                                    dataSource={datatours}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={<p style={{ color: 'gray', fontFamily: 'unset', fontSize: 17 }}>{String(item.value).toUpperCase()}</p>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>

                        </Row>
                    </Col>
                    <Col xs={24} xl={8} span={8}>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={22}>
                                <Row>
                                    <Col span={24}>
                                        <h5>FROM</h5>
                                    </Col>
                                    <Col span={24}>
                                        {dataTourID.map(r => (
                                            <h4 style={{ fontWeight: 'bold' }}>{r.Price} USD &ensp; PER ADULT</h4>
                                        ))}
                                    </Col>
                                    <Col span={24}>
                                        <List
                                            grid={{ gutter: 16, column: 2, xs: 1 }}

                                            itemLayout="horizontal"
                                            dataSource={datainfobook}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={<CheckSquareTwoTone />}
                                                        description={item.title}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Divider type="horizontal" />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h5 style={{ color: '#2E78AD', textAlign: 'center' }}>Please Select When Booking:</h5>
                            </Col>
                            <Divider type="horizontal" />
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card >
                                    <Row>
                                        {/* <Col span={24}>
                                            Classic Tour From Thingvellir
                                        </Col> */}
                                        <Col span={24}>
                                            <h5>Date of travel</h5>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={'datestartlocation'}>
                                                <Calendar disabledDate={(current) => {
                                                    let customDate = moment().format("DD-MM-YYYY");
                                                    return current && current < moment(customDate, "DD-MM-YYYY");
                                                }} onSelect={selectCalendar} fullscreen={false} onPanelChange={onPanelChange} />
                                            </Form.Item>

                                        </Col>

                                        <Divider type="horizontal" />
                                    </Row>
                                    <Row hidden={opendetailCalendar === true ? false : true}>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={6}>
                                                    <strong style={{ fontSize: 16 }}>Start time</strong>
                                                </Col>
                                                <Col style={{ marginTop: 0 }} span={6}>
                                                    <strong style={{ color: '#828282', fontSize: 16 }}>{detailCalendar}</strong>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={11}>
                                                    <Form.Item name={'timestart'} >
                                                        <TimePicker defaultValue={moment(moment().format('DD-MM-YYYY HH:mm'), 'HH:mm')} format={'HH:mm'} />
                                                    </Form.Item>

                                                </Col>
                                                <Col span={13}>
                                                    <Form.Item name={'NumberofParticipants'} label='Number of Participants'>
                                                        <Input min={1} onChange={onChangeParticipants} defaultValue={1} type="number" />
                                                    </Form.Item>

                                                </Col>
                                            </Row>

                                        </Col>
                                        <Divider type="horizontal" />
                                    </Row>
                                    <Row hidden={opendetailCalendar === true ? false : true}>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <h4>Guests</h4>
                                                </Col>
                                                <Col span={24}>
                                                    <b>Participants</b>
                                                </Col>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={18}>
                                                            {dataTourID.map(r => (
                                                                <p>Age {r.Age === 0 ? '' : r.Age} +</p>
                                                            ))}
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item name={'Age'}>
                                                                <Input min={0} onChange={onChangeAge} defaultValue={0} type="number" />
                                                            </Form.Item>
                                                            <Form.Item hidden name={'Price'}>
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Divider type="horizontal" />
                                    </Row>
                                    <Row hidden={opendetailCalendar === true ? false : true}>
                                        <Col span={24}>
                                            <Row style={{ marginLeft: 130 }}>
                                                <Col span={4}>
                                                    <p style={{ color: '#A28282', fontSize: 18 }}>Total</p>
                                                </Col>
                                                <Col span={12}>
                                                    <h4 style={{ color: '#27AE60', marginLeft: 10, marginTop: -2 }}>{priceAge + (priceParticipant * Number(dataTourID.map(r => r.Price)[0]))} $</h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{ textAlign: 'center', color: '#27AE60' }} span={24}>
                                            <h5 style={{ color: '#27AE60' }}><TagOutlined style={{ color: '#27AE60' }} />  BEST PRICE GUARANTEE</h5>
                                        </Col>
                                        <Col hidden={clickButtonCart === true ? false : true} span={22}>
                                            <Button size="large" style={{ width: '100%', backgroundColor: '#F89810', borderRadius: 3, height: 50 }}><strong>Proceed to checkout</strong></Button>
                                        </Col>
                                        <Col hidden={clickButtonCart === true ? true : false} span={22}>
                                            <Button form="form-booktour" type="primary" htmlType="submit" onClick={(e) => onChangeButton(e)} style={{ width: '100%', backgroundColor: '#F89810', borderRadius: 3, height: 50 }}><strong>Add to cart</strong></Button>
                                        </Col>
                                        <Col style={{ height: 10 }} span={24}></Col>
                                        <Col hidden={clickButtonCart === true ? true : false} span={22}>
                                            <Button form="form-booktour" type="primary" htmlType="submit" size="large" style={{ width: '100%', border: '1px solid #F89810', borderRadius: 3, height: 50 }}><strong>Book and pay</strong></Button>
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form >
            <Divider type="horizontal" />
            <Row>
                <Col span={2}>

                </Col>
                <Col span={20}>
                    <Row>
                        <Col span={16}>
                            <Row>
                                <Col span={24}>
                                    <b style={{ fontSize: 22 }}>ITINERARY</b>
                                </Col>
                                <Col span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    {dataTourID.map(r => (
                                        <span style={{ color: 'gray', fontSize: 16 }}>{r.Itinerary}</span>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Image src="https://gisgeography.com/wp-content/uploads/2023/01/World-Country-Map.jpg" />
                        </Col>

                    </Row>
                </Col>

                <Divider type="horizontal" />
            </Row>


            <Row style={{ backgroundColor: '#EFF1F4' }}>
                <Col span={24}>
                    <br />
                </Col>
                <Col span={24}>
                    <h3 style={{ textAlign: 'center' }}>SIMILAR TOURS THAT PEOPLE VIEWED ALSO</h3>
                </Col>
                <Col span={24}>
                    <br />
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={1}>

                        </Col>
                        <Col span={22}>
                            <List
                                grid={{ gutter: 16, column: 4, xs: 1 }}
                                dataSource={datapopular}
                                renderItem={(item) => (
                                    <List.Item onClick={(e) => clickPopular(item.id, item.title)}>
                                        <Card bodyStyle={{ minHeight: 300, position: 'relative' }} headStyle={{ backgroundImage: `url(${item.src})`, backgroundSize: "cover", padding: 0 }} title={<div style={{ minWidth: 312, height: 220 }}>
                                            <br />
                                            <div style={{ borderTopRightRadius: 15, height: 36, borderBottomRightRadius: 15, color: 'white', width: 230, backgroundColor: '#367AA7', position: 'relative' }}>
                                                <span style={{ color: 'white', marginLeft: 5, position: 'absolute', top: 5 }}>{String(item.toptitle).toLocaleUpperCase()}</span>
                                            </div>
                                            <div style={{ width: 170, height: 35, borderTopRightRadius: 15, borderBottomRightRadius: 15, color: 'white', backgroundColor: '#F89810', marginTop: 10, position: 'relative' }}>
                                                <span style={{ color: 'white', marginLeft: 5, top: 5, position: 'absolute' }}>{String(item.titlesale).toLocaleUpperCase()}</span>
                                            </div>
                                        </div>} hoverable>
                                            <div style={{ backgroundColor: 'white', borderRadius: 7, marginTop: -42, height: 32, textAlign: 'center', border: '1px solid gray' }}>
                                                <h6 style={{ color: 'rgb(66,130,172)', marginTop: 5, fontWeight: 'bold' }}>{String(item.titletour).toUpperCase()}</h6>
                                            </div>
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <strong style={{ fontWeight: 'bold', fontSize: 16, textShadow: "0px  1px  1px  gray" }}>{String(item.title).toUpperCase()}</strong>
                                                </div>

                                                <Row>
                                                    <Col xl={10} xs={10} span={10}>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            value={item.rating}
                                                            size={24}
                                                            color2={'#ffd700'} />
                                                    </Col>

                                                    <Col style={{ marginTop: 9, color: 'gray' }} xl={14} xs={14} span={8}>
                                                        {item.reviewer} REVIEWS
                                                    </Col>

                                                </Row>

                                                <Row gutter={[8, 8]}>

                                                    <Col span={7}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <span style={{ fontSize: 12, color: '#367AA7', textShadow: "0px  1px  1px  gray" }}> AVAILABILITY</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <b style={{ fontSize: 12, color: '#367AA7', fontWeight: 'bold', textShadow: "0px  1px  1px  gray" }}>{item.availability}</b>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Divider style={{ height: 80, backgroundColor: '#367AA7', textShadow: "0px  1px  1px  gray" }} type="vertical" />
                                                    <Col span={6}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <span style={{ fontSize: 12, color: '#367AA7', textShadow: "0px  1px  1px  gray" }}>DURATION</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <b style={{ fontSize: 12, color: '#367AA7', fontWeight: 'bold', textShadow: "0px  1px  1px  gray" }}>{item.duration}</b>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Divider style={{ height: 80, backgroundColor: '#367AA7' }} type="vertical" />

                                                    <Col span={7}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <span style={{ fontSize: 12, color: '#367AA7', textShadow: "0px  1px  1px  gray" }}>DEPARTS FROM</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <b style={{ fontSize: 12, color: '#367AA7', fontWeight: 'bold', textShadow: "0px  1px  1px  gray" }}>{item.departsfrom}</b>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row gutter={[64]} style={{ position: 'absolute', bottom: 20 }}>
                                                    <Col span={12}>
                                                        <Button style={{ backgroundColor: '#003D67', color: 'white', borderRadius: 7, width: 135, height: 35, textAlign: 'center' }}><strong style={{ color: 'white', fontWeight: 'bold' }}>{language === 'en' ? 'View Tour' : 'Xem Tour'}</strong></Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <strong style={{ fontSize: 24, fontWeight: 'bold' }}>{item.price}</strong>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Card>
                                    </List.Item>
                                )} />
                        </Col>

                    </Row>

                </Col>


            </Row>
            <Modal
                className="modalBook"
                width={900}
                open={openModalBook}
                style={{ textAlign: 'center' }}
                title={
                    <strong style={{ textAlign: 'center' }}>
                        BOOKING INFORMATION
                    </strong>
                }
                onOk={handleOkBook}
                onCancel={handleCancelBook}
                footer={[
                    <Button key="back" onClick={handleCancelBook}>
                        Cancel
                    </Button>,
                    <Button type="primary" form="form-Book" htmlType="submit" loading={loadingBook}>
                        Book Now
                    </Button>,
                ]}
            >
                {loadingBook ? (
                    <>
                        <Skeleton loading={loadingBook} active />
                    </>
                ) : (
                    <>{renderBook()}</>
                )}
            </Modal>

        </div >
    )
}
export default BookTour