import { Button, Card, Col, Image, Row, Steps, Dropdown, Skeleton, Divider, List, Avatar, Radio, InputNumber, Select, Menu, Input, Form, Modal } from "antd";
import Translate from "../../common/Lang/translate";
import instagram from '../Images/instagram.png';
import facebook from '../Images/facebook.svg';
import tripadvitor from '../Images/tripadvisor.png';
import Youtube from '../Images/Youtube.png';
import zaloicon from '../Images/zaloicon.png';
//--------------------------------------------
import hotline from '../Images/hotlineicon.jpg';
import emailicon from '../Images/emailicon.png';
import messengericon from '../Images/messengericon.jpg';
import phoneicon from '../Images/phoneicon.png';
import telegramicon from '../Images/telegramicon.jpg';
import whatappicon from '../Images/whatappicon.png';
import ktalkicon from '../Images/KakaoTalk_logo.svg';
import paypal from '../Images/paypal.png';
import visa from '../Images/visa.png';
import wise from '../Images/wise.png';
import transbank from '../Images/transbank.png';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;
const Footer = () => {
    const language = localStorage.getItem('language')
    const history = useHistory();
    const [dataCompany, setDataCompany] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [dataExtraFooter, setDataExtraFooter] = useState([]);
    const [dataEmail, setDataEmail] = useState([]);
    const [formEmail] = Form.useForm();
   
    const LoadDataEmail = () => {
        axios.post('https://dgss.vn:7001/api/Email/GetEmailAll', '').then(res => {
            setDataEmail(res.data)
        })
    }
    const LoadDataExtraFooter = () => {
        axios.post('https://dgss.vn:7001/api/ExtraFooter/GetExtraFooterAll', '').then(res => {
            setDataExtraFooter(res.data)
        })
    }
    const LoadDataCompany = () => {
        axios.post('https://dgss.vn:7001/api/Company/GetCompanyAll', '').then(res => {
            setDataCompany(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
    const LoadDataFooter = () => {
        axios.post('https://dgss.vn:7001/api/Footer/GetFooterAll', '').then(res => {
            setDataFooter(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
        })
    }
  
    useEffect(() => {
        LoadDataEmail();
        LoadDataCompany();
        LoadDataFooter();
        LoadDataExtraFooter()
    }, [language])
    const datalogo = [
        {
            name: 'Instagram',
            src: instagram,
            width: 30,
            height: 30,
            link: dataFooter.map(r => r.Instagram)
        },
        {
            name: 'Facebook',
            src: facebook,
            width: 30,
            height: 30,
            link: dataFooter.map(r => r.Facebook)
        },
        {
            name: 'Tripadvitor',
            src: tripadvitor,
            width: 50,
            height: 30,
            link: dataFooter.map(r => r.Tripadvisor)
        },
        {
            name: 'Youtube',
            src: Youtube,
            width: 35,
            height: 30,
            link: dataFooter.map(r => r.Youtube)
        }
    ]
    const dataPay = [
        {
            name: 'Visa',
            src: visa,
            width: 50,
            height: 20,
            marginTop: 4
        },

        {
            name: 'paypal',
            src: paypal,
            width: 50,
            height: 15,
            marginTop: 4
        },
        {
            name: 'Transbank',
            src: transbank,
            width: 50,
            height: 20,
            marginTop: 0
        },
        {
            name: 'wise',
            src: wise,
            width: 50,
            height: 50,
            marginTop: -10
        }
    ]
    const datainfo = [
        {
            content: dataExtraFooter.map(r => r.Phone),
            icon: phoneicon,
            link: `tel:${dataExtraFooter.map(r => r.Phone)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Hotline),
            icon: hotline,
            link: `tel:${dataExtraFooter.map(r => r.Hotline)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Email),
            icon: emailicon,
            link: `mailto:${dataExtraFooter.map(r => r.Email)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Messenger),
            icon: messengericon,
            link: `http://m.me${dataExtraFooter.map(r => r.Messenger)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Zalo),
            icon: zaloicon,
            link: `https://chat.zalo.me/?phone=${dataExtraFooter.map(r => r.Zalo)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Telegram),
            icon: telegramicon,
            link: `https://t.me/${dataExtraFooter.map(r => r.Telegram)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Whatapp),
            icon: whatappicon,
            link: `https://api.whatsapp.com/send?phone=${dataExtraFooter.map(r => r.Whatapp)[0]}`
        },
        {
            content: dataExtraFooter.map(r => r.Ktalk),
            icon: ktalkicon,
            link: `tel:${dataExtraFooter.map(r => r.Ktalk)[0]}`
        }
    ]

    const menu = (
        <Menu style={{ backgroundColor: '#367AA7', borderRadius: 10 }}>
            <Menu.Item style={{ color: 'white' }} onClick={(e) => history.push('/policies/Cancelation and Refund', 1)} key="1">Cancelation and Refund</Menu.Item>
            <Menu.Item style={{ color: 'white' }} onClick={(e) => history.push('/policies/Payment', 2)} key="2">Payment</Menu.Item>
            <Menu.Item style={{ color: 'white' }} onClick={(e) => history.push('/policies/Children', 3)} key="3">Children</Menu.Item>
        </Menu>
    );
    const onFinishEmail = (values) => {
        window.open(`/customtour`, "_blank");
        formEmail.resetFields()
    }
    return (
        <div style={{ backgroundColor: "white" }}>
            <div style={{ border: '1px solid #DDDDDD', textAlign: 'center' }}>
                <Card size="small" headStyle={{ textAlign: 'center', backgroundColor: '#EFF1F4' }} bodyStyle={{ backgroundColor: '#EFF1F4', textAlign: 'center' }} >
                    <Row>
                        <Col xs={24} xl={9} span={12}>
                            <Row>
                                <Col span={24}>
                                    <strong style={{ textShadow: "0px  1px  1px  gray", fontSize: 16 }}>Social networks</strong>
                                    <Divider type='horizontal' />
                                </Col>
                                <Col style={{ marginTop: 10 }} span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    <List
                                        grid={{ gutter: 0, column: 4, xs: 4 }}
                                        dataSource={datalogo}
                                        renderItem={(item) => (
                                            <List.Item >
                                                <Button onClick={(e => window.open(item.link, '_blank'))} type='text' icon={<Image preview={false} src={item.src} width={item.width} height={item.height} />}>

                                                </Button>

                                            </List.Item>
                                        )} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} xs={24} xl={15}>
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col span={12}>
                                            <Dropdown overlay={menu}>
                                                <Button type="text">
                                                    <strong style={{ textShadow: "0px  1px  1px  gray", fontSize: 16 }} onClick={e => e.preventDefault()}>
                                                        Policies
                                                    </strong>
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                        <Col span={12}>

                                            <Link to={'/terms&condition'} target="_blank" >
                                                <strong style={{ textShadow: "0px  1px  1px  gray", fontSize: 16,color:'black' }} >
                                                    Terms & Conditions
                                                </strong>
                                            </Link>


                                        </Col>
                                    </Row>


                                    <Divider type='horizontal' />
                                </Col>
                                <Col span={24}>
                                    <Card size="small" bodyStyle={{ backgroundColor: '#003D67', color: 'white', borderRadius: 10 }}>
                                        <Row>
                                            <Col style={{ textAlign: 'center' }} xs={24} xl={24} span={24}>

                                                <strong >Get more information and exclusive deals straight to your inbox</strong>


                                            </Col>
                                            <Col span={24}>
                                                <br />
                                            </Col>
                                            <Col xs={24} xl={24} span={24}>
                                                <Row>
                                                    <Col span={3}>

                                                    </Col>
                                                    <Col span={18}>
                                                        <Form
                                                            id="form-Email"
                                                            form={formEmail}
                                                            onFinish={onFinishEmail}
                                                            name="basic"
                                                            labelCol={{
                                                                span: 0,
                                                            }}
                                                            wrapperCol={{
                                                                span: 24,
                                                            }}
                                                        >
                                                            <Row gutter={[8, 8]}>
                                                                <Col xs={24} xl={10} span={8}>
                                                                    <Form.Item rules={[
                                                                        {
                                                                            required: false,
                                                                            message: Translate(language, 'message_input_email'),
                                                                            type: "email",
                                                                        },
                                                                    ]} name={'Email'}>
                                                                        <Input size="small" placeholder={'Your Email'} style={{ borderRadius: 7, height: 35 }} width={200} />
                                                                    </Form.Item>

                                                                </Col>
                                                                <Col xs={24} xl={10} span={8}>
                                                                    <Form.Item name={'Name'}>
                                                                        <Input size="small" placeholder={'Your Name'} style={{ borderRadius: 7, height: 35 }} width={200} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <Button form="form-Email" htmlType="submit" style={{ backgroundColor: '#F89810', borderRadius: 7, color: 'white', height: 35, fontSize: 16, textShadow: "0px  0px  2px  black" }}><b>SEND</b></Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </div>
            <Row>
                <Col span={24}>
                    <br />
                </Col>
            </Row>
            <Row >
                <Col span={2}>

                </Col>
                <Col xs={22} xl={16} span={16}>
                    <Row>
                        <Col xs={0} xl={8} span={12}>
                            <Button onClick={(e) => window.open("/contact", "_blank")} style={{ color: 'white', backgroundColor: '#F89810', marginTop: 30, borderRadius: 5, minWidth: 150, minHeight: 50 }} size="large"><strong style={{ textShadow: "0px  0px  2px  black" }}>CONTACT US</strong></Button>
                        </Col>
                        <Col style={{ textAlign: 'center' }} xs={24} xl={0} span={12}>
                            <Row>
                                <Col xs={5} span={8}>

                                </Col>
                                <Col xs={12} span={8}>
                                    <Button onClick={(e) => window.open("/contact", "_blank")} style={{ color: 'white', backgroundColor: '#F89810', marginTop: 8, borderRadius: 5, width: 150, height: 50 }} size="large"><strong style={{ textShadow: "0px  0px  2px  black" }}>CONTACT US</strong></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={0} span={24}>
                            <br />
                        </Col>
                        <Col style={{ marginTop: 5 }} xs={24} xl={16} span={18}>
                            <List
                                itemLayout="horizontal"
                                grid={{ gutter: 32, column: 3, xs: 2 }}
                                dataSource={datainfo}
                                renderItem={(item) => (
                                    <List.Item >
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.icon} />}
                                            title={<a href={item.link} target="_blank">{item.content}</a>}
                                        />

                                    </List.Item>
                                )} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} xl={3} span={24}>
                    <Row >
                        <Col span={24}>
                            <br />
                        </Col>
                        <Col span={24}>
                            <br />
                        </Col>
                        <Col span={24}>
                            <br />
                        </Col>
                        <Col span={24}>
                            <br />
                        </Col>
                        <Col span={24}>
                            <br />
                        </Col>
                        <Col xs={0} xl={24} span={24}>

                            <List
                                style={{ left: '40%', top: '60%' }}
                                grid={{ gutter: 64, column: 4, xs: 4, xl: 4 }}
                                dataSource={dataPay}
                                renderItem={(item) => (
                                    <List.Item >
                                        <Image style={{ height: item.height, width: item.width, marginTop: item.marginTop }} preview={false} src={item.src} />
                                    </List.Item>
                                )} />
                        </Col>
                        <Col xs={24} xl={0} span={24}>
                            <List
                                style={{ textAlign: 'center', top: '50%' }}
                                grid={{ gutter: 2, column: 4, xs: 4 }}
                                dataSource={dataPay}
                                renderItem={(item) => (
                                    <List.Item >
                                        <Image style={{ height: item.height, width: item.width, marginTop: item.marginTop }} preview={false} src={item.src} />
                                    </List.Item>
                                )} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#003D67', textAlign: 'center', minHeight: 100 }}>
                <Row>
                    <Col style={{ color: 'white', paddingTop: '3%' }} span={24}>
                        {dataCompany.map(r => r.LegalRepresentative)}
                    </Col>
                </Row>

            </div>
            
        </div>
    )
}
export default Footer;