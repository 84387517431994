import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

export const menuItemsData = (data, language, isLogin, service) => {
  const expanMenu = [
    {
      idMenu: 0,// idMenu,
      idSub: 0,
      idCate: 0,
      title: "Blog",
      url: "blog",
    },
    {
      idMenu: 0,// idMenu,
      idSub: 0,
      idCate: 0,
      title: language === 'en' ? "About Us" : 'Về chúng tôi',
      url: "about",
    },
    {
      title: language === 'en' ? "Feedback" : 'Nhận xét',
      idMenu: 0,// idMenu,
      idSub: 0,
      idCate: 0,
      url: "feedback",
    },
    {
      title: isLogin ? language === 'en' ? "Logout" : "Đăng xuất" : language === 'en' ? "Login" : "Đăng nhập",
      idMenu: 0,// idMenu,
      idSub: 0,
      idCate: 0,
      url: isLogin ? "logout" : 'login',
    },
  ]
  const dataService = service.map(i => (
    {
      idCate: i.id, // idCategory
      title: i.title,
      url: 'service',
      idSub: 0,
      idMenu: 0,
      submenu: i.submenu.map(r => ({
        idMenu: 0,
        idCate: i.id,
        idSub: r.id, //idSubmenu
        title: r.title,
        url: 'service',
        submenu: r.menu.length == 0 ? null : r.menu.map(u => (
          {
            idMenu: u.id,// idMenu,
            idSub: r.id,
            idCate: i.id,
            title: u.title,
            url: 'service',
          }
        ))
      }))
    }
  ))
  return data.map(i => (
    {
      idCate: i.id, // idCategory
      title: i.title,
      url: 'category',
      idSub: 0,
      idMenu: 0,
      submenu: i.submenu.map(r => ({
        idMenu: 0,
        idCate: i.id,
        idSub: r.id, //idSubmenu
        title: r.title,
        url: 'category',
        submenu: r.menu.length == 0 ? null : r.menu.map(u => (
          {
            idMenu: u.id,// idMenu,
            idSub: r.id,
            idCate: i.id,
            title: u.title,
            url: 'tour',
          }
        ))
      }))
    })).concat(dataService).concat(expanMenu)
}
