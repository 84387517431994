import './App.css';
import { Card, Tabs, Layout, Row, Col, Tag, Form, Input, BackTop, Button, Avatar, Menu, Upload, Dropdown, Modal, Skeleton, Select, Image, Space, List } from "antd";

import "antd/dist/antd.css";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MenuFooter from './components/Menu/menu';
import {
  EditOutlined, PlusOutlined, SendOutlined, CheckOutlined, CheckCircleOutlined, SettingOutlined, FormOutlined, LogoutOutlined
} from "@ant-design/icons";
import Cart from './components/Cart/cart';
import Blog from './components/Blog/blog';
import BlogDetail from './components/BlogDetail/blogdetail';
import TourDetail from './components/TourDetail/tourdetail';
import BookTour from './components/Booking/booktour';
import Footer from './components/Footer/footer';
import { useRef } from 'react';
import CategoryTour from './components/CategoryTour/categorytour';
import FeedBack from './components/Feedback/feedback';

import Login from './components/Login/login';
import Manager from './components/Manager/manager';
import CheckOut from './components/Checkout/checkout';
import Policies from './components/Policies/policies';
import Contact from './components/Contact/contact';
import SubMenuTour from './components/SubMenuTour/SubMenuTour';
import ScrollToTop from "react-scroll-to-top";
import AboutUs from './components/AboutUs/aboutus';

import axios from 'axios';
import ChatRoom from './components/ChatRoom/chatroom';
import {

  MessageBox,
  MessageList,
} from 'react-chat-elements'
import AttactionDetail from './components/AttractionsDetail/attractionsDetail';
import { isMobile } from 'react-device-detect';
import socketIOClient from "socket.io-client";
import Home from './components/Home/home';
import Service from './components/Service/service';
import SubMenuService from './components/SubMenuService/SubMenuService';
import ServiceDetail from './components/ServiceDetail/serviceDetail';
import Terms from './components/Terms/terms';
import CustomTour from './components/CustomTour/customtour';
import Spa from './components/Service/SPA&Massage';
import Vehicle from './components/Service/Vehicle';
import ACCOMMODATION from './components/Service/ACCOMMODATION';
import Bus from './components/Service/bus';
import RESTAURANT from './components/Service/RESTAURANT';

const { Option } = Select;
// socket.connect()
const host = "https://dgss.vn:5001";
function App() {
  const [language, setLanguage] = useState('en');

  //chat

  const stateChat = localStorage.getItem('stateChat');
  const [buttonClickChat, setButtonClickChat] = useState(true);
  const [currentMessage, setCurrentMessage] = useState('')
  const [dataMessageList, setDataMessageList] = useState([]);
  const idRoom = localStorage.getItem('idRoom');
  //----------------------------
  const [dataShareAction, setDataShareAction] = useState([]);
  const history = useHistory();
  const stateHistory = history.location.state;
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split("/").filter((item) => item);
  const SelectLanguage = (value) => {
    setLanguage(value)
  }
  const changeContentMessage = (e) => {
    setCurrentMessage(e.target.value)
  }


  const headerUser = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: `Bearer ${localStorage.getItem('tokenUser')}`,
  };
  const LoadDataShareAction = () => {
    axios.post('https://dgss.vn:7001/api/ShareAction/GetShareActionAll', '').then(res => {
      if (res.status === 200) {
        setDataShareAction(res.data);
      }
    })
  }
  const LoginChat = () => {
    axios.post('https://dgss.vn:5001/login/9f343e17474a4d8db259902c2034d7d3', '', {
      headers: {
        'Content-Type': 'text/plain'
      }
    }).then(res => {
      localStorage.setItem('tokenChat', res.data['authorization']);
    })
  }
  const [state, setState] = useState(false);
  const sendMessage = async (e) => {
    if (currentMessage !== "" && idRoom !== 0) {
      axios.post(`https://dgss.vn:5001/room/${idRoom}/message`, {
        "messageText": currentMessage
      }, {
        headers: headerUser
      }).then(res => {
        LoadDataMessage(idRoom);
      })
      setCurrentMessage("");
      setState(!state)
    }
  };
  const LoadDataMessage = () => {
    if (idRoom !== null) {
      axios.get(`https://dgss.vn:5001/room/${idRoom}`, {
        headers: headerUser
      }).then(res => {
        const conversation = res.data.conversation;
        if (conversation.length === 0) {
          const data = [
            {
              position: "left",
              type: "text",
              title: "Warmly welcome!",
              text: `How may I help you ?`,
            },
          ]
          setDataMessageList(data)
        }
        else {
          let customDataMes = conversation.map(function (r, index) {
            return {
              id: r._id,
              position: r.postedByUser.lastName !== 'support' ? "right" : 'left',
              type: "text",
              text: r.message.messageText,
              date: r.createdAt,
              title: r.postedByUser.lastName,
            }
          });
          setDataMessageList(customDataMes)
        }
      })
    }
  }
  const onFinishChat = (values) => {
    if (values.lastName !== "") {
      axios.post(`https://dgss.vn:5001/users`, {
        "firstName": 'Mr',
        "lastName": values.lastName,
        "type": "consumer"
      }).then(res => {
        const dataUser = res.data;
        const user = dataUser.user;
        const idUser = user._id;
        localStorage.setItem('idUser', idUser)
        axios.post(`https://dgss.vn:5001/login/${idUser}`).then(reslogin => {
          const dataLogin = reslogin.data
          const token = dataLogin.authorization;
          localStorage.setItem('tokenUser', token);
          const header = {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: `Bearer ${token}`,
          };
          axios.post('https://dgss.vn:5001/room/initiate', {
            "userIds": [`${idUser}`, "9f343e17474a4d8db259902c2034d7d3"],
            "type": "consumer-to-support"
          }, {
            headers: header
          }).then(resRoom => {
            const dataRoom = resRoom.data;
            const Room = dataRoom.chatRoom;
            const idRoom = Room.chatRoomId;
            localStorage.setItem('idRoom', idRoom);
            localStorage.setItem('valueYourName', values.lastName)
            history.push('/')
          })
        })
      })
    }
  }
  const dataSocial = [
    {
      icon: 'https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png',
      link: dataShareAction.map(r => r.Pinterest),
      width: 16,
      height: 16
    },
    {

      icon: 'https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-instagram-icon-png-image_6315974.png',
      link: dataShareAction.map(r => r.Instagram),
      width: 19,
      height: 19
    },
    {

      icon: 'https://image.similarpng.com/thumbnail/2020/06/Popular-Logo-Twitter-clipart-PNG.png',
      link: dataShareAction.map(r => r.Twitter),
      width: 19,
      height: 19
    },
    {

      icon: 'https://brandlogos.net/wp-content/uploads/2021/04/facebook-icon.png',
      link: dataShareAction.map(r => r.Facebook),
      width: 24,
      height: 24
    },
  ]
  const buttonClickChatSupport = () => {
    setButtonClickChat(true)
  }
  const [clickchatSupport, setClickChatSupport] = useState(false);
  const chatWithSupport = (e) => {
    // đóng cửa sổ chat
    setClickChatSupport(true)
  }

  useEffect(() => {
    LoginChat();
    // hẹn giờ đóng cửa sổ chát
    // setTimeout(() =>{
    //   setButtonClickChat(false)
    // },10000)
    if (isMobile === true) {
      setButtonClickChat(false)
    }
    LoadDataShareAction()
    LoadDataMessage(idRoom)
    if (window.performance) {
      if (performance.navigation.type !== 1) {
        localStorage.removeItem('idUser');
        localStorage.removeItem('idRoom');
        localStorage.removeItem('valueYourName');
        setButtonClickChat(false);
      }
    }
  }, [stateChat]); // pathname
  const closewindowChat = (e) => {
    setButtonClickChat(false);
  }
  return (
    <div className='App' >
      <Layout style={{ position: 'relative' }}>
        <div id='selectLanguage' style={{ height: 24, backgroundColor: '#CCCCCC' }}>
          <b style={{ color: 'black' }}>Languages:</b>
          <Select showArrow={true} style={{ color: 'black', minWidth: 110 }} bordered={false} size='small' onSelect={SelectLanguage} defaultValue={language}>
            <Option value="vi"><b>Vietnamese</b></Option>
            <Option value="en"><b>English</b></Option>
          </Select>
        </div>
        {pathnames[0] === 'contact' ? Contact(language) : <div>
          <Row>
            <Col span={24}>
              {MenuFooter(stateHistory, language)}
            </Col>
          </Row>
          <BackTop style={{ marginRight: isMobile ? '20%' : '40%' }} />
          <div style={{ position: 'fixed', left: 0, bottom: '30%', zIndex: 15 }}>
            <Row hidden={isMobile === true ? true : false}>
              <Col style={{ textAlign: 'left', marginLeft: 3 }} span={24}>
                <b>SHARE</b>
              </Col>
              <Col style={{ marginLeft: 5 }} span={24}>
                <Row gutter={[0, 16]}>
                  {dataSocial.map(r => (
                    <Col span={24}>
                      <Button style={{ border: '1px solid gray', textAlign: 'center' }} size='large' shape='circle' onClick={(e) => window.open(r.link, '_blank')} type='text' >
                        <Avatar size={'small'} style={{ marginTop: -4, width: r.width, height: r.height }} shape='circle' src={r.icon} />
                      </Button>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <div className='chat-support'>
              {idRoom === undefined || idRoom === null || idRoom === '' || buttonClickChat === false ? (
                <Row>
                  <Col xl={24} xs={0} span={24}>
                    <div style={{ position: 'fixed', left: '75%', right: 10, bottom: 0, zIndex: 15 }}>
                      <Button hidden={buttonClickChat} onClick={(e) => buttonClickChatSupport(e)} size='large' style={{ textAlign: 'center', backgroundColor: '#0A9BEA', color: 'white', borderRadius: 5, marginLeft: 180 }}>Chat Support</Button>
                      <Card bodyStyle={{ width: 300 }} hidden={buttonClickChat === true ? false : true} headStyle={{ textAlign: 'center', backgroundColor: '#0A9BEA', color: 'white' }} title={<span>
                        <Row>
                          <Col span={4}></Col>
                          <Col span={16}>
                            Chat Support
                          </Col>
                          <Col span={4}>
                            <Button type='text' onClick={(e) => closewindowChat(e)
                            }><strong style={{ color: 'white' }}>X</strong></Button>
                          </Col>
                        </Row>
                      </span>}>
                        <Form
                          name="basic"
                          labelCol={{ span: 0 }}
                          wrapperCol={{ span: 24 }}
                          style={{ maxWidth: 600 }}
                          onFinish={onFinishChat}
                          autoComplete="off"
                        >
                          <div hidden={clickchatSupport} >
                            <MessageBox
                              position='left'
                              title='Warmly welcome!'
                              type='text'
                              text="How may I help you?"
                              date={new Date()}
                              replyButton={true}
                            />
                          </div>
                          <br />
                          <Form.Item hidden={clickchatSupport} wrapperCol={{ offset: 6, span: 16 }}>
                            <Button onClick={(e) => chatWithSupport(e)} type="primary" >
                              Chat with support
                            </Button>
                          </Form.Item>

                          <Form.Item
                            hidden={clickchatSupport === false ? true : false}
                            name="lastName"
                            rules={[{ required: true, message: 'Please input your your name!' }]}
                          >
                            <Input placeholder='Your name' type='text'
                            />
                          </Form.Item>
                          <Form.Item hidden={clickchatSupport === false ? true : false} wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                              Begin Chat
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    </div>
                  </Col>
                  <Col xs={24} xl={0} span={24}>
                    <div style={{ position: 'fixed', left: '30%', right: 0, bottom: 0, zIndex: 15 }}>
                      <Button hidden={buttonClickChat} onClick={(e) => buttonClickChatSupport(e)} size='small' style={{ textAlign: 'center', backgroundColor: '#0A9BEA', color: 'white', borderRadius: 5, marginLeft: 150 }}>Chat Support</Button>
                      <Card bodyStyle={{ fontSize: 8, maxWidth: 300 }} hidden={buttonClickChat === true ? false : true} headStyle={{ textAlign: 'center', backgroundColor: '#0A9BEA', color: 'white' }} title={<span>
                        <Row>
                          <Col span={2}></Col>
                          <Col xs={16} xl={0} span={16}>
                            Chat Support
                          </Col>
                          <Col span={4}>
                            <Button type='text' onClick={(e) => setButtonClickChat(false)}><strong style={{ color: 'white' }}>X</strong></Button>
                          </Col>
                        </Row>
                      </span>}>
                        <Form
                          name="basic"
                          labelCol={{ span: 0 }}
                          wrapperCol={{ span: 12 }}
                          style={{ maxWidth: 200, fontSize: 8 }}
                          onFinish={onFinishChat}
                          autoComplete="off"

                        >
                          <div hidden={clickchatSupport}>
                            <MessageBox
                              position='left'
                              title='Warmly welcome!'
                              type='text'
                              text="How may I help you?"
                              date={new Date()}
                              replyButton={true}
                            />
                          </div>
                          <br />
                          <Form.Item hidden={clickchatSupport} wrapperCol={{ offset: 6, span: 16 }}>
                            <Button onClick={(e) => chatWithSupport(e)} type="primary" >
                              Chat with support
                            </Button>
                          </Form.Item>

                          <Form.Item
                            hidden={clickchatSupport === false ? true : false}
                            name="lastName"
                            rules={[{ required: true, message: 'Please input your your name!' }]}
                          >
                            <Input placeholder='Your name' type='text'
                            />
                          </Form.Item>
                          <Form.Item hidden={clickchatSupport === false ? true : false} wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                              Begin Chat
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xl={24} lg={24} sm={0} xxl={24} xs={0} span={24}>
                    <div style={{ position: 'fixed', left: '70.5%', right: 0, bottom: 0, zIndex: 10 }}>
                      <Card title={<Row>
                        <Col span={20}>
                          Chat Support
                        </Col>
                        <Col span={4}>
                          <Button style={{ color: ' white' }} type='text' onClick={(e) => closewindowChat(e)}>X</Button>
                        </Col>
                      </Row>} bodyStyle={{ maxWidth: 300 }} headStyle={{ textAlign: 'center', backgroundColor: '#0A9BEA', color: 'white' }}>
                        <div style={{ height: 150, overflowY: 'auto', width: '100%', overflowX: 'revert' }}>
                          <MessageList
                            className="message-list"
                            lockable={true}
                            toBottomHeight={'100%'}
                            dataSource={dataMessageList}
                          />
                        </div>
                        <div style={{ marginTop: "auto" }}>
                          <Input
                            onChange={changeContentMessage}
                            placeholder="Type here..."
                            value={currentMessage}
                            onKeyPress={(event) => {
                              event.key === "Enter" && sendMessage();
                            }}
                          />
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} lg={24} xl={0} xxl={0} span={24}>
                    <div style={{ position: 'fixed', left: '30%', right: 2, bottom: 0, zIndex: 15, maxWidth: 300 }}>
                      <Card title={<Row>
                        <Col span={20}>
                          Chat Support
                        </Col>
                        <Col span={4}>
                          <Button style={{ color: 'white' }} type='text' onClick={(e) => closewindowChat(e)}>X</Button>
                        </Col>
                      </Row>} headStyle={{ textAlign: 'center', backgroundColor: '#0A9BEA', color: 'white' }} bodyStyle={{ maxWidth: 300 }}>
                        <div style={{ height: 150, overflowY: 'auto' }}>
                          <MessageList
                            className="message-list"
                            lockable={true}
                            toBottomHeight={'100%'}
                            dataSource={dataMessageList}
                            sendMessagePreview
                          />

                          {/* <SystemMessage text={"End of conversation"} /> */}
                        </div>
                        <div style={{ marginTop: "auto" }}>
                          <Input
                            onChange={changeContentMessage}
                            placeholder="Type here..."
                            value={currentMessage}
                            onKeyPress={(event) => {
                              event.key === "Enter" && sendMessage();
                            }}
                            addonAfter={<SendOutlined style={{ top: -5 }} onClick={(e) => sendMessage()} />}
                          />
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <Layout.Content>
            <Switch >
              <Route key={0} path={'/login'}>{Login(language)}</Route>
              <Route key={1} path={'/manager'}>{Manager(language)}</Route>
              {/* truyền vào id */}
              <Route key={2} path={'/contact'}>{Contact(language)}</Route>
              <Route key={3} path={'/book/:title'}>{BookTour(language)}</Route>
              <Route key={4} path={'/policies/:title'}>{Policies(language)}</Route>
              <Route key={5} path={'/cart'}>{Cart(language)}</Route>
              {/* truyền vào id và số đơn hàng */}
              <Route key={6} path={'/checkout'}>{CheckOut(language)}</Route>
              <Route key={7} path={'/about'}>{AboutUs(language)}</Route>
              <Route key={8} exact path={'/blog'}> {Blog(language)}</Route>
              <Route key={9} path={'/blog/:titleBlog'}>{BlogDetail(language)}</Route>
              <Route key={10} path={'/attaction'}>{AttactionDetail(language)}</Route>
              <Route key={11} path={'/tour/:title'}>{TourDetail(language)}</Route>
              <Route key={12} exact path={'/category'}>{CategoryTour(language)}</Route>
              <Route key={13} exact path={'/customtour'}>{CustomTour(language)}</Route>
              <Route key={14} path={'/category/:id'}>{SubMenuTour(language)}</Route>
              <Route key={15} exact path={'/service'}>{SubMenuService(language)}</Route>
              <Route key={16} path={'/servicedetail'}>{ServiceDetail(language)}</Route>
              <Route key={17} path={'/service/spa'}>{Spa(language)}</Route>
              <Route key={18} path={'/service/bus'}>{Bus(language)}</Route>
              <Route key={19} path={'/service/vehicle'}>{Vehicle(language)}</Route>
              <Route key={20} path={'/service/restaurant'}>{RESTAURANT(language)}</Route>
              <Route key={21} path={'/service/accommodation'}>{ACCOMMODATION(language)}</Route>
              <Route key={22} path={'/feedback'}>{FeedBack(language)}</Route>
              <Route key={23} path={'/terms&condition'}>{Terms(language)}</Route>
              <Route key={24} path={'/chatroom'}>{ChatRoom(state)}</Route>
              <Route key={25} path={'/'}> {Home(language)}</Route>
            </Switch>
          </Layout.Content>
          <Footer />
        </div>}
      </Layout>
    </div>

  );
}



export default App