import { Button, Card, Col, Image, Row, Steps, Avatar, Carousel, Divider, List } from "antd";
import {
    MoneyCollectOutlined, MonitorOutlined, DribbbleOutlined, CheckCircleOutlined, PhoneOutlined, TagOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import SlideImage from "../SlideImage/slideimage";
import Translate from "../../common/Lang/translate";
import { useEffect, useState } from "react";

import axios from "axios";
import ReactPlayer from 'react-player'
import moment from "moment";
import SearchBar from "../SearchBar/searchbar";
const AboutUs = (language) => {
    const history = useHistory()
    const [dataAbout, setDataAbout] = useState([]);
    const [dataImageList, setDataImageList] = useState([]);
    const LoadDataAbout = () => {
        axios.post('https://dgss.vn:7001/api/AboutUs/GetAboutUsAll', '').then(res => {
            if (res.status === 200) {

                setDataAbout(res.data);
                const imagelink = res.data.map(r => r.Image);
                const arrayImage = String(imagelink[0]).split(';')
                setDataImageList(arrayImage)

            }
        })
    }
    const [dataSaleBanner, setDataSaleBanner] = useState([]);
    const [dataCateInit,setDataCateInit] = useState([]);
    const serviceCategory = [
        {
            title: language === 'vi' ? 'Dịch vụ khác' : 'Other Services',
            src: 'https://topgotourist.vn/img/service/origin/full_topgotourist_dich-vu-tu-van-va-to-chuc-tour-du-lich-tron-goi-2021830141558.jpeg',
            id: 0
        }
    ]
    const Category = dataCateInit.slice(0, 4).map(r => (
        {
            title: language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID
        }
    ))
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '').then(res => {
            setDataCateInit(res.data)

        })
    }
    const LoadDataSaleBanner = () => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/GetSaleBannerAll', '').then(res => {
            if (res.status === 200) {
                setDataSaleBanner(res.data);
            }
        })
    }

    useEffect(() => {
        LoadDataAbout();
        LoadDataSaleBanner()
        LoadDataCategory()
    }, [])
    const clickCategory = (id, title) => {
        
        if (id === 0) {
            history.push('/service', id);
        }
        else {
            history.push(`/category`, id);
        }
    }
   
    return <div style={{ backgroundColor: 'white' }}>
      
        {SearchBar(dataSaleBanner, language)}
        <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
            <Row gutter={[16, 16]}>
                <Col style={{ marginTop: 5 }} span={24}>

                </Col>
                <Col xs={24} xl={24} span={24}>
                    <Row>
                        <Col xs={24} xl={6} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <MonitorOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title1).split(';')[0] : String(r.Title1).split(';')[1]}</h6>
                                    ))}

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} xl={6} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <DribbbleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title2).split(';')[0] : String(r.Title2).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={4} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <CheckCircleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title3).split(';')[0] : String(r.Title3).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} xl={4} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <PhoneOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title4).split(';')[0] : String(r.Title4).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} xl={4} span={4}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <MoneyCollectOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title5).split(';')[0] : String(r.Title5).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col style={{ marginTop: 5 }} span={24}>

                </Col>
            </Row>
        </div>
        <Card bodyStyle={{ backgroundColor: 'white', minHeight: 300, position: 'relative' }}>
            <Row gutter={[32, 8]}>
                {Category.concat(serviceCategory).slice(0, 4).map(item => (
                    //xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2
                    <Col xs={24} xl={6} xxl={6} lg={6} sm={24} md={24} span={6}>
                        <Card onClick={(e) => clickCategory(item.id, item.title)} hoverable bodyStyle={{ backgroundImage: `url(${item.src})`, minWidth: 310, minHeight: 220, textAlign: 'center', backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                            <Row>
                                <Col style={{ height: 120 }} span={24}>
                                    <h4 style={{ marginTop: 60, color: 'white', textShadow: "0px  0px  10px  black" }}>{item.title}</h4>
                                </Col>
                                <Col span={24}>
                                    <Button onClick={(e) => clickCategory(item.id, item.title)} type="text">  <h6 onClick={(e) => clickCategory(item.id, item.title)} style={{ color: 'white', marginBottom: 30, textShadow: "0px  0px  10px  black" }}>See More </h6></Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}

            </Row>
        </Card>
        <Row>
            <Col span={24}>
                <Card>

                </Card>
            </Col>
            <Col span={4}>

            </Col>
            <Col xs={24} xl={16} span={16}>
                <Row>
                    <Col span={24}>
                        <br />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            <Col span={24}>

                                <Row>
                                    <Col span={24}>
                                        <ReactPlayer width={900} height={460} url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
                                    </Col>
                                    <Col span={24}>
                                        <p style={{ textAlign: 'center', fontSize: 14, color: 'gray' }}>Adventures.com is a global travel company that specializes in authentic journeys to the world’s most memorable places. Established in 1983 as a humble river rafting company in Iceland, we grew to become one of the leading guides and operators in the Northern Hemisphere.</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>WHO WE ARE</b>
                            </Col>
                            <Col style={{ fontSize: 16 }} span={24}>
                                <div dangerouslySetInnerHTML={{ __html: dataAbout.map(r => r.WhoWeAre) }}>

                                </div>
                            </Col>

                            <Col span={24}>
                                <Image.PreviewGroup>
                                    <Carousel infinite={false} autoplay>
                                        {
                                            dataImageList.map(r => (
                                                <div>
                                                    <Image style={{ width: 900, height: 460 }} src={r} />
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                </Image.PreviewGroup>
                            </Col>
                            <Col span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>CORE VALUES</b>
                            </Col>

                            <Col style={{ fontSize: 16 }} span={24}>
                                <div dangerouslySetInnerHTML={{ __html: dataAbout.map(r => r.CoreValues) }}>

                                </div>
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}
export default AboutUs;