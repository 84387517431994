import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import { Col, Menu, Row, Badge, Image, Button, Card } from 'antd';
import logonew from '../Images/Logo260x50-04.png';
import {
  MenuOutlined, ShoppingCartOutlined
} from "@ant-design/icons";
import { Link, useHistory } from 'react-router-dom';
const Header = (data, language, isLogin, badge,dataService) => {
  const history = useHistory();
  return (
    <header>
      <div className="nav-area">
        <Link to="/" className="logo">
          <img style={{ width: 235, height: 35 }} src={logonew} alt="Blog Logo" />
        </Link>
        {/* for large screens */}
        {Navbar(data, language, isLogin, badge,dataService)}
        {/* for small screens */}
        {MobileNav(data, language, isLogin, badge,dataService)}
        <div className="badge" style={{ marginTop: -14 }}>
          <Link to={'/cart'}>
            <Badge count={badge}  size='small' >
              <ShoppingCartOutlined style={{ color: 'white', fontSize: 16 }} />
            </Badge>
          </Link>

        </div>
      </div>
    </header>
  );
};

export default Header;
