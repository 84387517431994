import { Card, Col, Divider, Row, List, Image, Button, Checkbox, Carousel, Form, Input } from "antd";
import Translate from "../../common/Lang/translate";
import {
    TagOutlined, ArrowRightOutlined, ArrowLeftOutlined, LeftOutlined, RightOutlined, FontColorsOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import ReactStars from 'react-stars'
import React, { useRef, Fragment, useEffect } from "react";

import { useHistory } from "react-router-dom";
import SlideImage from "../SlideImage/slideimage";
import axios from "axios";
import swal from "sweetalert";
const { Meta } = Card;
const Terms = (language) => {


    const dataImage = [
        {
            slogan: 'TERMS CONDITIONS',
            src: 'https://rafountain.com/wp-content/uploads/2021/03/Terms-and-Conditions.png',

        }
    ]

    return (
        <div style={{backgroundColor:'white'}}>
            {SlideImage(dataImage)}
            <Row>
                <Col span={3}>

                </Col>
                <Col xs={24} xl={18} span={16}>
                    <Card bordered={false}>
                        <strong style={{fontSize:18,fontWeight:'bold'}}>TERMS & CONDITIONS</strong>
                        <p>These terms and conditions may be amended from time to time and apply to all of our services.</p>
                        <p>By accessing, browsing, using and/or completing a reservation through our (mobile) website you acknowledge and agree to have read, understood and agreed to be legally bound to the terms and conditions set out below. No contract between the customer and <strong>Nomadictravelvn.com</strong> shall come into existence until <strong>Nomadictravelvn.com</strong> receives full payment and issues a confirmation invoice. The customer making the booking accepts these conditions on behalf of all members of their travel party and is responsible for the entirety of the payment.</p>
                        <p>Nomadictravelvn.com is a tour operator, offering a wide range of adventure tours in Central Highland, Vietnam.</p>
                        <p>Bookings can be made directly through <strong>Nomadictravelvn.com</strong>, its sub-brands and/or any other website through which <strong>Nomadictravelvn.com</strong> makes their services available. If you are having any problems booking through <strong>Nomadictravelvn.com</strong> then please contact our&nbsp;via <strong><em>hotline/what&rsquo;sapp: +84981 76 76 77</em></strong></p>
                        <p><strong>Nomadictravelvn.com</strong> reserves the right to alter itineraries and/or timetables should it be necessary, due to adverse weather and or other related conditions.&nbsp; <strong>Nomadictravelvn.com</strong> is not responsible for expenses and/or other related costs due to factors outside of its control, such as flight delays, changes and or cancellations, injuries, weather, war, natural disasters etc. and/or other unforeseeable matters. You are responsible for providing <strong>Nomadictravelvn.com</strong> with the correct contact information and related details so that we can keep you informed in the event of changes made to the services booked.</p>
                        <ol>
                            <li><strong> PRICES</strong></li>
                        </ol>
                        <p>1.1. Please note that all prices for the services provided by <strong>Nomadictravelvn.com</strong> and its sub-brands are in USD or Vietnam Dong (VND). We want to inform you that <strong>Nomadictravelvn.com</strong> is not responsible for currency fluctuations and additional fees outside its control that may be applied, such as bank transfer fees and credit card fees.</p>
                        <p>1.2. All prices shown on our website include value-added tax and are based on current prices. <strong>Nomadictravelvn.com</strong> reserves the right to alter or change already published prices on the website without prior notice in case of currency fluctuation, government taxes, or any such cost increases that are beyond <strong>Nomadictravelvn.coms&rsquo; </strong>control.</p>
                        <p>1.3. Prices are guaranteed against any surcharge after an invoice has been issued unless the increase is due to government laws or currency changes and amounts to 5% of the tour price or more. In such cases, amounts exceeding 5% will be charged, and clients will be entitled to cancel their booking with a full refund if the surcharge exceeds 10%. Any increase due to changes in government laws, such as tax changes, will be added to the invoice.</p>
                        <p>&nbsp;</p>
                        <ol start="2">
                            <li><strong> PICK-UP &amp; DROP-OFF SERVICES</strong></li>
                        </ol>
                        <p>2.1. <strong>Nomadictravelvn.com</strong> offers pick up and drop-off services for most of its tours. Please read the tour description very carefully to see if pick-up and drop-off services are included in the price, can be added as extras or the activity in question only offers the option of meeting us on location.</p>
                        <p>2.2. You as the traveler are responsible for your pick-up, this includes providing <strong>Nomadictravelvn.com</strong> with your pick up location in time as well as being ready at the correct time for the pick-up for your tour. <strong>Nomadictravelvn.com</strong> will provide you with information about your pick up time on your invoice which is provided upon booking. If you are unsure when to be ready for your scheduled tour then please contact us.</p>
                        <p>2.3. Please note that missing the pick-up does not entitle you to a refund of the price paid for the services. You are responsible to be ready for pick up at the assigned pick up point from the start of the pick-up time in order to not miss our guides when they arrive to pick you up.</p>
                        <ol start="3">
                            <li><strong> MEETING US ON LOCATION</strong></li>
                        </ol>
                        <p>3.1. If you are planning to meet us on location for your tour then please be sure that this can be arranged. For most of <strong>Nomadictravelvn.com</strong> tours, it is possible to meet us on location. Please read the tour description of your tour very carefully to see if meeting us on location is possible for the activity in question</p>
                        <p>3.2. You as the traveler are responsible for arriving at the meeting point in time for your tour.&nbsp; This includes being ready at the correct time at the correct location in time for your adventure tour. <strong>Nomadictravelvn.com</strong> will provide you with information about the meeting point for your tour on your voucher which is provided upon booking. If you are unsure when to arrive for your scheduled tour then please contact us.</p>
                        <p>3.3. Please note that if you do not arrive in time for your tour and/or are late to the meeting point of the tour, that is to say, you do not arrive to the correct meeting point at the correct time for your adventure tour then you are not entitled to a refund of the price paid for the services.</p>
                        <ol start="4">
                            <li><strong>TRAVEL INSURANCE</strong></li>
                        </ol>
                        <p>4.1. As with any and all world travel, we strongly recommend that you purchase a travel insurance policy in your home country that covers your cost should you have to cancel your travel reservation with short notice. This kind of travel insurance is not expensive and frequently covers other risks. Having travel insurance is always a good idea when traveling around the world.&nbsp;This is more relevant during COVID-19 times like now.</p>
                        <ol start="5">
                            <li><strong> VIETNAM WEATHER AND CONDITIONS</strong></li>
                        </ol>
                        <p>5.1. All adventure trips and outdoor activities are dependent on weather and general conditions. <strong>Nomadictravelvn.com</strong> reserves the right to alter routes, itineraries, departure times and or cancel your trip without prior notice with your safety in mind, should the necessity arise.</p>
                        <p>5.2. Please keep in mind that Vietnam is and will always be Vietnam. The weather changes and different and with it, conditions change. Our guides are specially trained to deal with changing conditions and their number one priority is to make decisions with your safety in mind. Please be aware of this and respect your guide&rsquo;s authority to do so.</p>
                        <p>5.3. <strong>Nomadictravelvn.com</strong> can not be held responsible for any loss, damage, accident, injury, sickness, schedule change or other factors due to weather, strike, natural disasters or any other cause beyond <strong>Nomadictravelvn.com</strong>s&rsquo;s control. <strong>Nomadictravelvn.com</strong> acts in good faith and cannot be held responsible for defaults or delays of organizations as individual agencies, hotels, other tour operators, airlines, guides, restaurants or any other person&lsquo;s or company&lsquo;s act.</p>
                        <p>5.4. In the case of any changes to flights (domestic and international), the passenger is responsible for any additional costs incurred as a result of such changes (i.e. additional hotel nights, meals, transfers, etc.), at the time provided. We, therefore, advise all customers to have comprehensive travel insurance to meet all contingencies.</p>
                        <ol start="6">
                            <li><strong> CLOTHING</strong></li>
                        </ol>
                        <p>6.1. Appropriate footwear and suitable outdoor clothing must be worn. We reserve the right to refuse participation to customers who are dressed inappropriately or with inappropriate footwear since this will put the client&rsquo;s well-being and safety at risk. In such cases, full cancellation charges apply.</p>
                        <ol start="7">
                            <li><strong> LUGGAGE</strong></li>
                        </ol>
                        <p>7.1. Please note that <strong>Nomadictravelvn.com</strong> cannot be held responsible for damaged luggage if it is taken on the tour. Luggage storage can be bought as an optional extra if the customer would like to securely store their luggage while on the multi-day adventure.</p>
                        <ol start="8">
                            <li><strong> ASSUMED RISK &amp; OUTDOOR ACTIVITIES</strong></li>
                        </ol>
                        <p>8.1. Guests/Clients are advised that all activities are undertaken entirely at their own risk and they must behave in a fit and proper manner at all times in accordance with <strong>Nomadictravelvn.com</strong> or its partners&rsquo; guidelines and must take proper responsibility for their own safety. All adventure tours and outdoor activities carry inherent risks and <strong>Nomadictravelvn.com</strong> or its partners do not assume any responsibility for accidents that are caused by its customers or can be traced to their own actions or are caused by factors outside of human control (Force majeure). With the purchase of your trip you agree to these conditions, understand its implications and accept responsibility for your participation in your trip. Depending on the excursion, you may be requested to sign an additional liability release.</p>
                        <p>8.2. Our guides reserve the right to refuse participation to any customer who they believe will not be able to complete the tour because of their physical condition, injury or illness. In such cases, full cancellation charges apply.</p>
                        <p>8.3. We reserve the right to refuse participation to clients that are being physically or verbally abusive, or if they pose a threat to staff members or fellow travelers in any other way. In such cases, full cancellation charges apply.</p>
                        <ol start="9">
                            <li><strong> ALCOHOL AND DRUGS:</strong></li>
                        </ol>
                        <p>9.1. We reserve the right to refuse participation to clients we believe are under the influence of alcohol or drugs, in such circumstances, no refund shall be given. Our guides reserve the right to refuse participation to any customer whose conduct or manner is likely to cause offense, upset or put other passengers in danger. In such cases, full cancellation charges apply.</p>
                        <p>&nbsp;</p>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}
export default Terms