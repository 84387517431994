import { Button, Card, Col, Image, Row, Steps, Avatar, InputNumber, Divider, List } from "antd";

import { useHistory } from "react-router-dom";

import SlideImage from "../SlideImage/slideimage";
import Translate from "../../common/Lang/translate";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import SearchBar from "../SearchBar/searchbar";
import {
    MoneyCollectOutlined, MonitorOutlined, DribbbleOutlined, CheckCircleOutlined, PhoneOutlined, TagOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined,
} from "@ant-design/icons";

const { Step } = Steps;
const { Meta } = Card;
const Blog = (language) => {

    const [dataBlog, setDataBlog] = useState([]);
    const [dataSaleBanner, setDataSaleBanner] = useState([]);

    const [dataCateInit, setDataCateInit] = useState([]);
    const LoadDataSaleBanner = () => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/GetSaleBannerAll', '').then(res => {
            if (res.status === 200) {
                setDataSaleBanner(res.data);
            }
        })
    }
    const serviceCategory = [
        {
            title: language === 'vi' ? 'Dịch vụ khác' : 'Other Services',
            src: 'https://topgotourist.vn/img/service/origin/full_topgotourist_dich-vu-tu-van-va-to-chuc-tour-du-lich-tron-goi-2021830141558.jpeg',
            id: 0
        }
    ]
    const Category = dataCateInit.slice(0, 4).map(r => (
        {
            title: language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID
        }
    ))
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '').then(res => {
            setDataCateInit(res.data)

        })
    }
    const LoadDataBlog = () => {
        axios.post('https://dgss.vn:7001/api/Blog/GetBlogAll', '').then(res => {
            if (res.status === 200) {
                setDataBlog(res.data.sort((a, b) => b.ID > a.ID ? 1 : -1))
            }
        })
    }
    useEffect(() => {
        LoadDataBlog();
        LoadDataSaleBanner();
        LoadDataCategory()
    }, [])
    const history = useHistory();
    const datarticle = dataBlog.map(r => (
        {
            slogan: r.Slogan,
            date: r.DayofWriting,
            title: r.Title,
            description: r.About,
            src: 'https://adventures.com/media/207072/s-iceland-dyrholaey-black-sand-beach-seastack-rock-formation-autumn-sunset-sunrise-south-coast.jpg?anchor=center&mode=crop&width=530&height=330&rnd=132772191600000000&quality=100&format=webp',
            titlesub: r.TitleSale,
            title: language === 'vi' ? String(r.Title).split(';')[0] : String(r.Title).split(';')[1],
            src: r.Image,
            id: r.ID,
        }
    ))

    const selectBlog = (e, title, id) => {
        // điều hướng và truyền giá trị
        history.push(`/blog/${title}`, id);
    }

    
    const clickCategory = (id, title) => {
        if (id === 0) {
            history.push('/service', id);
        }
        else {
            history.push(`/category`, id);
        }
    }
    return <div style={{ backgroundColor: 'white', fontStyle: 'normal' }}>
        {SearchBar(dataSaleBanner, language)}
       
        <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
            <Row gutter={[16, 16]}>
                <Col style={{ marginTop: 5 }} span={24}>

                </Col>
                <Col xs={24} xl={24} span={24}>
                    <Row>
                        <Col xs={24} xl={6} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <MonitorOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title1).split(';')[0] : String(r.Title1).split(';')[1]}</h6>
                                    ))}

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} xl={6} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <DribbbleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title2).split(';')[0] : String(r.Title2).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={4} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <CheckCircleOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title3).split(';')[0] : String(r.Title3).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} xl={4} span={5}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <PhoneOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title4).split(';')[0] : String(r.Title4).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} xl={4} span={4}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} span={24}>
                                    <MoneyCollectOutlined style={{ color: '#367AA7', fontSize: '150%' }} />
                                </Col>
                                <Col span={24}>
                                    {dataSaleBanner.map(r => (
                                        <h6 style={{ textShadow: "0px  0px  1px  black" }}>{language === 'vi' ? String(r.Title5).split(';')[0] : String(r.Title5).split(';')[1]}</h6>
                                    ))}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
                <Col style={{ marginTop: 5 }} span={24}>

                </Col>
            </Row>
        </div>
        <Card bodyStyle={{ backgroundColor: 'white', minHeight: 300, position: 'relative' }}>
            <Row gutter={[32, 8]}>
                {Category.concat(serviceCategory).slice(0, 4).map(item => (
                    //xs: 1, xl: 4, xxl: 2, sm: 1, lg: 2, md: 2
                    <Col xs={24} xl={6} xxl={6} lg={6} sm={24} md={24} span={6}>
                        <Card onClick={(e) => clickCategory(item.id, item.title)} hoverable bodyStyle={{ backgroundImage: `url(${item.src})`, minWidth: 310, minHeight: 220, textAlign: 'center', backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                            <Row>
                                <Col style={{ height: 120 }} span={24}>
                                    <h4 style={{ marginTop: 60, color: 'white', textShadow: "0px  0px  10px  black" }}>{item.title}</h4>
                                </Col>
                                <Col span={24}>
                                    <Button onClick={(e) => clickCategory(item.id, item.title)} type="text">  <h6 onClick={(e) => clickCategory(item.id, item.title)} style={{ color: 'white', marginBottom: 30, textShadow: "0px  0px  10px  black" }}>See More </h6></Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}

            </Row>
        </Card>
        <Row>
            <Col span={2}>

            </Col>
            <Col span={20}>
                <Row>
                    <Col span={24}>
                        <br />
                    </Col>
                    <Col span={24}>
                        <h4 style={{ textAlign: 'center' }}>RECENT ARTICLES</h4>
                    </Col>
                    <Col span={24}>
                        <br />
                    </Col>
                    <Col span={24}>
                        <List grid={{ gutter: 16, column: 2, xs: 1 }}
                            pagination={{
                                position: "bottom",
                                pageSize: 10
                            }}
                            dataSource={datarticle}
                            renderItem={(item) => (
                                <List.Item onClick={(e) => selectBlog(e, item.title, item.id)}>

                                    <Card headStyle={{ backgroundImage: `url(${item.src})`, backgroundSize: "cover", padding: 0 }} title={<div style={{ minWidth: 500, height: 320 }}>
                                        <br />
                                        <div style={{ borderTopRightRadius: 20, height: 35, borderBottomRightRadius: 20, color: 'white', width: 200, backgroundColor: '#BB1213' }}>

                                            <span style={{ color: 'white', marginLeft: 10 }}>{moment(item.date).format('MM/YYYY')}</span>

                                        </div>
                                    </div>} style={{ minWidth: 550 }} hoverable >
                                        <Row>
                                            <Col span={4}>

                                            </Col>
                                            <Col span={16}>
                                                <div style={{ backgroundColor: 'white', borderRadius: 7, marginTop: -45, height: 40, textAlign: 'center', border: '1px solid gray', width: 350 }}>
                                                    <h6 style={{ color: 'rgb(66,130,172)', marginTop: 8 }}>{String(item.titlesub).toUpperCase()}</h6>
                                                </div>
                                            </Col>
                                            <Col span={4}></Col>
                                        </Row>
                                        <Meta
                                            style={{ minHeight: 100 }}
                                            title={item.title}
                                            description={<span style={{ textOverflow: "clip", width: 200 }}>{item.description}</span>}
                                        />
                                        <Button size="large" style={{ marginLeft: -15, color: '#FF9900' }} type="text"><b>See More</b></Button>
                                    </Card>
                                </List.Item>
                            )} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}
export default Blog;