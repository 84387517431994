import { AutoComplete, Card, Col, Input, Row, Select } from "antd"
import {
    MoneyCollectOutlined, SearchOutlined, DribbbleOutlined, CheckCircleOutlined, PhoneOutlined, TagOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import SlideImage from "../SlideImage/slideimage";
const SearchBar = (Language) => {

    const [dataSaleBanner, setDataSaleBanner] = useState([]);
    const [dataTour, setDataTour] = useState([]);
    const history = useHistory();
    const [dataBannerHome, setDataBannerHome] = useState([]);
    const LoadDataTour = () => {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
            setDataTour(res.data);
        })
    }
    const LoadDataSaleBanner = () => {
        axios.post('https://dgss.vn:7001/api/SaleBanner/GetSaleBannerAll', '').then(res => {
            if (res.status === 200) {
                setDataSaleBanner(res.data);
            }
        })
    }
    useEffect(() => {
        LoadDataSaleBanner();
        LoadDataTour();
        LoadDataBannerHome();
    }, [])
    const LoadDataBannerHome = () => {
        axios.post('https://dgss.vn:7001/api/BannerHome/GetBannerHomeAll', '').then(res => {
            if (res.status === 200) {
                let stringImage = '';
                let id = 0;
                let button1 = '';
                let button2 = '';
                let description = '';
                let slogan = '';
                res.data.forEach(({ Image, ID, Description, Slogan, Button1, Button2 }) => {
                    stringImage = Image;
                    id = ID;
                    description = Description;
                    slogan = Slogan;
                    button1 = Button1;
                    button2 = Button2;
                });

                const resultURL = stringImage.split(';');
                const customData = resultURL.map(r => ({

                    Image: r,
                    ID: id,
                    Description: description,
                    Slogan: slogan,
                    Button1: button1,
                    Button2: button2

                }));
                setDataBannerHome(customData);
            }
        })
    }
    const onChangeSelectTour = (value, child) => {
        history.push(`/book/${value}`, Number(child.key));
    }


    const arrayImage = dataBannerHome.map(r => (
        {
            id: r.ID,
            slogan: Language === 'vi' ? String(r.Description).split(';')[0] : String(r.Description).split(';')[1],
            title: Language === 'vi' ? String(r.Slogan).split(';')[0] : String(r.Slogan).split(';')[1],
            src: r.Image,
            button1: String(r.Button1).split(';')[0],
            button2: String(r.Button2).split(';')[0],
            idButton1: Number(String(r.Button1).split(';')[1]),
            idButton2: Number(String(r.Button2).split(';')[1]),
        }
    ))

    return (
        <div>
            {SlideImage(arrayImage)}
            <Card bordered={false} bodyStyle={{ backgroundColor: '#003D67', color: 'white', textAlign: 'center' }} size="small">
                <Row style={{ textAlign: 'center' }}>
                    <Col xs={0} xl={8} span={8}>

                    </Col>
                    <Col style={{ textAlign: 'center' }} xs={24} xl={8} span={8}>
                        {dataSaleBanner.map(r => (
                            <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                <TagOutlined style={{ fontSize: 20 }} /> &nbsp;{Language === 'vi' ? String(r.Slogan).split(';')[0] : String(r.Slogan).split(';')[1]}
                            </span>
                        ))}
                    </Col>
                    <Col xs={24} xl={8} span={8}>
                        <Select onSelect={onChangeSelectTour} showSearch suffixIcon={<SearchOutlined />} style={{ minWidth: '100%' }} placeholder={'Search tour'}>
                            {dataTour.map(r => (
                                <Select.Option key={r.ID} value={Language === 'vi' ? String(r.Title).split(';')[1] : String(r.Title).split(';')[0]}>{Language === 'vi' ? String(r.Title).split(';')[1] : String(r.Title).split(';')[0]}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    
                </Row>

            </Card>
        </div>

    )
}
export default SearchBar