import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import { Row, Col, Button, Carousel, Card } from "antd";
import panelen from '../Images/paner-en.jpg';
import panelvi from '../Images/paner-fan.jpg';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
const SlideImage = (data) => {

    const history = useHistory();
    const [dataImage, setDataImage] = useState([]);
    const LoadDataImage = (arr) => {
        setDataImage(arr);
    }
    useEffect(() => {
        if (data.length !== 0) {
            LoadDataImage(data)
        }
    }, [data])
    return (
        <Row>
            <Col xl={24} xs={0} span={24}>
                <Carousel autoplaySpeed={6000} dots={false} autoplay >
                    {data.map((i) => <Card bordered={false} bodyStyle={{ backgroundImage: `url(${i.src})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', padding: 0, minWidth: '100%', minHeight: 420 }}>
                        <Row style={{ textAlign: 'center' }}>
                            <Col style={{ textAlign: 'center', marginTop: 150 }} span={24}>
                                <strong style={{ color: 'white', fontSize: 45, textShadow: "5px  0px  25px  black" }}>{i.slogan}</strong>
                            </Col>
                            <Col span={24}>
                                <strong style={{ color: 'white', fontSize: 25,textShadow: "5px  0px  25px  black" }}>{i.title}</strong>
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            {i.button1 === undefined || i.button2 === undefined ? null : <Col span={24}>
                                <Row style={{ textAlign: 'center' }}>
                                    <Col span={8}>

                                    </Col>
                                    <Col span={4}>
                                        <Button className='buttonSlide' onClick={(e) => history.push(`/category`, i.idButton1)} style={{ borderRadius: 10, minWidth: 200 }} size="large"><strong className="titleSide" style={{ color: '#367AA7', marginTop: 4 }}>{i.button1}</strong></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button className='buttonSlide' onClick={(e) => history.push(`/category`, i.idButton2)} style={{ borderRadius: 10, minWidth: 200 }} size="large"><strong className="titleSide" style={{ color: '#367AA7', marginTop: 4 }}>{i.button2}</strong></Button>
                                    </Col>
                                </Row>
                            </Col>}

                        </Row>
                    </Card>)}
                </Carousel>
            </Col>
            <Col xs={24} xl={0} span={24}>
                <Carousel autoplaySpeed={6000} dots={false} autoplay >
                    {dataImage.map((i) => <Card bordered={false} bodyStyle={{ backgroundImage: `url(${i.src})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', padding: 0, minWidth: '100%', minHeight: 310 }}>
                        <Row style={{ textAlign: 'center' }}>
                            <Col style={{ textAlign: 'center', marginTop: 90 }} span={24}>
                                <strong style={{ color: 'white', fontSize: 30,textShadow: "0px  0px  15px  black" }}>{i.slogan}</strong>
                            </Col>
                            <Col span={24}>
                                <strong style={{ color: 'white', textShadow: "0px  0px  15px  black", fontSize: 18 }}>{i.title}</strong>
                            </Col>
                         
                            {i.button1 === undefined || i.button2 === undefined ? null : <Col span={24}>
                                <Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
                                    <Col span={8}>

                                    </Col>
                                    <Col xs={24} span={4}>
                                        <Button  className='buttonSlide' style={{ borderRadius: 8, minWidth: 150 }} size="large"><strong className="titleSide" style={{ color: '#367AA7', marginTop: 8, fontSize: 12 }}>{i.button1}</strong></Button>
                                    </Col>
                                    <Col xs={24} span={4}>
                                        <Button  className='buttonSlide' style={{ borderRadius: 8, minWidth: 150 }} size="large"><strong className="titleSide" style={{ color: '#367AA7', marginTop: 8, fontSize: 12 }}>{i.button2}</strong></Button>
                                    </Col>
                                </Row>
                            </Col>}
                        </Row>
                    </Card>)}
                </Carousel>
            </Col>
        </Row>
    )
}

export default SlideImage