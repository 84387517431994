import "antd/dist/antd.css";
import { Form, Button, Input, Checkbox, Card, Row, Col, Divider, Image } from "antd";
import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";
import { login } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Translate from "../../common/Lang/translate";
import logonew from '../Images/Logo260x50-03.png';
import SlideImage from "../SlideImage/slideimage";
const Login = (Language) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useSelector((state) => state.auth);
    //const user = localStorage.getItem("user");
    const dispatch = useDispatch();
    
    
    const onFinish = async (values) => {
        setLoading(true);
        // const response = await loginUser({
        // 	username,
        // 	password
        // });
        // đây là login vào
        //setLoading(true);
       
        const username = values.username;
        const password = values.password;
        try {
            if (username === undefined || username === "") {
                swal(Translate(Language, 'required_username'), {
                    buttons: false,
                    icon: "warning",
                });
            } else if (password === undefined || password === "") {
                swal(Translate(Language, 'required_password'), {
                    buttons: false,
                    icon: "warning",
                });
            } else {
                dispatch(login({ username, password }))
                    .then(() => {

                        //props.history.push("/profile");
                        history.push("/manager");
                        //window.location.href='/manager'
                        //props.history.push("/home");
                        setLoading(false);
                    })
                    .catch(() => {
                        //setLoading(false);
                    });
            }
        } catch (err) {

            setTimeout(() => {
                if (!err.response) {
                    swal("Tên đăng nhập hoặc mật khẩu không chính xác!!!", {
                        icon: "error",
                    });
                    setLoading(false)
                } else if (err.response.status === 405) {
                    swal("Không có phản hồi từ server !!!");
                    setLoading(false)
                } else {
                    swal("Đăng nhập thất bại !!!");
                    setLoading(false)
                }
            }, 10000);
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    // hình random https://source.unsplash.com/random
    //isLoggedIn true chuyển đến profile
    if (isLoggedIn) {
        return <Redirect to={"/manager"} />;
    }
    return (

        <div>
            <Card bodyStyle={{ minHeight: 300, textAlign: 'center' }} headStyle={{ backgroundImage: `url('https://images.adventures.is/assets/uploads/2019/04/12093025/waterfall-winter.jpg')`, backgroundSize: "cover", padding: 0 }} title={<div style={{ minWidth: 312, height: 300 }}>
            </div>} >
                <Row>
                    <Col xs={0} xl={3} span={3}>

                    </Col>
                    <Col xs={24} xl={18} span={18}>
                        <div style={{ backgroundColor: 'white', borderRadius: 4, marginTop: -280, border: '1px solid gray', textAlign: 'center' }}>
                            <Row>
                                <Col span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    <Image preview={false} src={logonew} width={'30%'} />

                                </Col>
                                <Col span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    <h4 style={{ color: 'rgb(66,130,172)' }}>{Translate(Language, 'Login')}</h4>
                                </Col>
                                <Col span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    <h5>Login using the ticket number on the top of your voucher and your email address.</h5>
                                </Col>
                                <Col span={24}>
                                    <br />
                                </Col>
                                <Col span={24}>
                                    <p style={{ fontSize: 16 }}>In My-Adventures you can for example update your pickup place, reschedule your tour and see tour status.
                                        Note that My Adventures is only for tours & activities, not rental cars or accommodation.</p>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={1}>

                                        </Col>
                                        <Col span={22}>
                                            <Form
                                                name="login-form"
                                                initialValues={{ remember: true }}
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                            >

                                                <Form.Item
                                                    name="username"
                                                    rules={[{ required: true, message: Translate(Language, 'required_username') }]}
                                                >
                                                    <Input
                                                        placeholder={Translate(Language, 'UserName')}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="password"
                                                    rules={[{ required: true, message: Translate(Language, 'required_password') }]}
                                                >
                                                    <Input.Password
                                                        placeholder={Translate(Language, 'Password')}
                                                    />
                                                </Form.Item>



                                                <Form.Item>
                                                    <Button loading={loading} type="text" htmlType="submit" className="login-form-button">
                                                        {Translate(Language, 'Login')}
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <div style={{ textAlign: 'center' }}>
                    <br />
                    <Image preview={false} src={logonew} width={'20%'} />
                </div>
            </Card>
        </div>
    );
};
export default Login