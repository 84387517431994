import { Card, Col, Divider, Row, List, Image, Button, Avatar, Carousel, Form, Input, DatePicker, Select, Upload } from "antd";
import Translate from "../../common/Lang/translate";
import React, { useRef, Fragment, useEffect, useState } from "react";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import countryMap from '../../common/countries/countries';
import SlideImage from "../SlideImage/slideimage";
import axios from "axios";
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import SearchBar from "../SearchBar/searchbar";
import moment from "moment";
import swal from "sweetalert";
const { Option } = Select
const bodyEmail = `<html>
<body>
<h2 style="text-align: center">Customize Tour Request</h2>
<p style="text-align: right;font-style: italic">Day:{today}</p>
<p style="font-size: 20px">You’have just recived an email of a customize tour from. Please reply as soon as possible</p>
<font size="3" face="Verdana" >
<h4 style="text-align: center">CUSTOMER'S CONTACT INFOMATION</h4>
<table style="border: 1px solid black; border-collapse: collapse;">
  <tr style="border: 1px solid black; padding: 10px;">
	<th style="border: 1px solid black; padding: 10px;">FULLNAME</th>
    <th style="border: 1px solid black; padding: 10px;">NATIONALITY</th>
    <th style="border: 1px solid black; padding: 10px;">EMAIL</th>
    <th style="border: 1px solid black; padding: 10px;">PHONE/ WHAT’S APP</th>
  </tr>
  <tr style="border: 1px solid black; padding: 10px;">
     <td style="border: 1px solid black; padding: 10px;">{FULLNAME}</td>
    <td style="border: 1px solid black; padding: 10px;">{NATIONALITY}</td>
	 <td style="border: 1px solid black; padding: 10px;">{EMAIL}</td>
     <td style="border: 1px solid black; padding: 10px;">{PHONE}</td>
   
 
  
</table>

<br />
<h4 style="text-align: center">TRIP INFORMATION</h4>
<strong>Where do you want to start your trip?</strong>&ensp;<span style="font-size: 20px;color:#0066ff">{StartTrip}</span>
<hr />
<strong>Pick up time</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{Pickuptime}</span>
<hr />
<strong>Pick up place</strong>&ensp;<span style="font-size: 20px;color:#0066ff"> {Pickupplace}</span>
<hr />
<strong>Where do you want to end your trip?</strong> &ensp;<span style="font-size: 20px;color:#0066ff">{EndTrip} </span>
<hr />
<strong>Drop-off place</strong> &ensp;<span style="font-size: 20px;color:#0066ff">{Drop_off}</span>
<hr />
<strong>Proposed date of your trip?</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{ProposeDate}</span>
<hr />
<strong>How many days do you have?</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{manyDays}</span>
<hr />
<strong>How many people in your group?</strong>&ensp;<span style="font-size: 20px;color:#0066ff"> {manyPeople}</span>
<hr />
<br />
<h4 style="text-align: center">YOUR INTERESTS</h4>
<strong>Prefered Transportation</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{Transportation}</span>
<hr />
<strong>Prefered Destination</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{Destination}</span>
<hr />
<strong>Prefered Activities</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{Activities}</span>
<hr />
<strong>Your special interests/ request?</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{special}</span>
<hr />
<strong>Range of hotel you want?</strong>&ensp; <span style="font-size: 20px;color:#0066ff">{HotelStar}</span>
<hr />
<strong>Would you like us to include any meals in your intinerary?</strong> &ensp;<span style="font-size: 20px;color:#0066ff">{Foods}</span>
<hr />
</font>
</body>
</html>`
const CustomTour = (Language) => {
    const location = useLocation();

    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    const history = useHistory();
    const dataState = history.location.state;
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([]);
    const changeUpload = async ({ fileList }) => {
        setFileList(fileList);
        const dataImage = fileList.map(r => `https://dgss.vn:8081/files/${r.name}`);
        const resultURL = dataImage.join(";");
        form.setFieldsValue({
            fileAttach: resultURL,
        })
    };



    const onFinish = (values) => {
        if (values.confirmEMAIL === values.EMAIL) {
            const pluginEmail = String(bodyEmail).replace('{today}', moment().format('DD/MM/YYYY HH:mm')).replace('{FULLNAME}', values.FULLNAME).replace('{NATIONALITY}', values.NATIONALITY).replace('{EMAIL}', values.EMAIL).replace('{PHONE}', values.PHONE).replace('{StartTrip}', values.StartTrip).replace('{Pickuptime}', values.Pickuptime).replace('{Pickupplace}', values.Pickupplace).replace('{EndTrip}', values.EndTrip).replace('{Drop_off}', values.Drop_off).replace('{ProposeDate}', moment(values.ProposeDate).format('DD/MM/YYYY HH:mm')).replace('{Drop_off}', values.Drop_off).replace('{manyDays}', values.manyDays).replace('{manyPeople}', values.manyPeople).replace('{Transportation}', values.Transportation).replace('{Destination}', values.Destination).replace('{Activities}', values.Activities).replace('{special}', values.special).replace('{HotelStar}', values.HotelStar).replace('{Foods}', values.Foods)
            axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                "ID": 1,
                "toEmail": values.EMAIL,
                "subject": 'From Nomadictravel Vn Team',
                "content": `<div>Thank you for your attention on our services! We’ve just received your email request for a customize trip. Our experts are going to planning up for your trip and will send you the intinerary as soon as  possible. Please keep updating your email!<br /><br />
                Best Regards, <br />
                NomadicTravel VN Team <div>`,
                "alert": ''
            })

            axios.post('https://dgss.vn:7001/api/BookTour/SendTourOrderEmail', {
                "ID": 1,
                "toEmail": 'nomadictravelvn@gmail.com',
                "subject": 'Customize Tour Request',
                "content": pluginEmail,
                "alert": 'New Email Book Tour'
            })
            swal(`Thank you for your attention on our services! We’ve just received your email request for a customize trip. Our experts are going to planning up for your trip and will send you the intinerary as soon as  possible. Please keep updating your email!
            Best Regards`, {
                icon: 'success',
                timer: 10000
            });
            form.resetFields();
            setFileList([])
        }
        else {
            swal('Emails do not match. Please re-enter!', {
                icon: 'warning',
                timer: 3000
            })
        }

    }
    const clickTopActivity = () => {
        localStorage.setItem('topActivity', true);
        window.open('/', "_blank");
    }
    return (
        <div style={{ backgroundColor: 'white' }}>
            <div style={{ textAlign: 'center', backgroundColor: '#EFF1F4' }}>
                {SearchBar(Language)}
            </div>
            <div>
                <br />
                <Row>
                    <Col span={2}>

                    </Col>
                    <Col xs={24} xl={20} span={20}>
                        <Row>
                            <Col style={{ textAlign: 'center' }} span={24}>
                                <strong style={{ fontSize: 18 }}>
                                    CUSTOMIZING YOUR PERFECT TOURS IN VIET NAM BY NOMADICTRAVELVN TEAM
                                </strong>
                            </Col>
                            <Col span={24}>
                                <br />

                            </Col>
                            <Col span={24}>
                                Have been working in tourism for over 10 years. We understand that the demands of tourist while on traveling are different by their hobbies, interest, physical health, culture, time frame…

                                A customized trip for your private group (friends, couples, family…) that perfectly suits your interest, your pace, time frame…will be a great choice.

                                Just tell us about your preferences, interests, amount of time available to you and we will arrange the rest. We will design a trip and work out a comfortable itinerary that best suits your needs.

                                If you are interested in NomadicTravel Vn custom designing your holiday, contact us via the form below:

                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={24}>
                                <Form
                                    style={{ border: '2px solid #003D67' }}
                                    id="form"
                                    form={form}
                                    onFinish={onFinish}
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 16,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    autoComplete="off"
                                >
                                    <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} title={<strong>
                                        CUSTOMER'S CONTACT INFOMATION
                                    </strong>}>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Fullname!",
                                            },
                                        ]} label='FULLNAME' name={'FULLNAME'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Choose NATIONALITY!",
                                            },
                                        ]} label='NATIONALITY' name={'NATIONALITY'}>
                                            <Select showSearch >
                                                {countryMap.map(r => (
                                                    <Select.Option value={r.name}>{r.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Email!",
                                                type: "email",
                                            },
                                        ]} label='EMAIL' name={'EMAIL'}>
                                            <Input onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Email!",
                                                type: "email",
                                            },
                                        ]} label='CONFIRM EMAIL' name={'confirmEMAIL'}>
                                            <Input onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Phone!",
                                                pattern: new RegExp(/^[0-9]+$/),
                                            },
                                        ]} label='PHONE/ WHAT’S APP' name={'PHONE'}>
                                            <Input />
                                        </Form.Item>
                                    </Card>
                                    <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} title={<strong>
                                        TRIP INFORMATION
                                    </strong>}>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter your trip",
                                            },
                                        ]} label='Where do you want to start your trip?' name={'StartTrip'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Enter pickup place!",
                                            },
                                        ]} label='Pick up place' name={'Pickupplace'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please choose pickup time!",
                                            },
                                        ]} label='Pick up time' name={'Pickuptime'}>
                                            <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }} disabledDate={(current) => {
                                                let customDate = moment().format("DD-MM-YYYY");
                                                return current && current < moment(customDate, "DD-MM-YYYY");
                                            }} style={{ width: '100%' }} format={'DD-MM-YYYY HH:mm'} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter your trip",
                                            },
                                        ]} label='Where do you want to end your trip?' name={'EndTrip'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: false,
                                                message: "Please Enter Drop-off place!",
                                            },
                                        ]} label='Drop-off place' name={'Drop_off'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please choose proposed date of your trip!",
                                            },
                                        ]} label='Proposed date of your trip?' name={'ProposeDate'}>
                                            <DatePicker disabledDate={(current) => {
                                                let customDate = moment().format("DD-MM-YYYY");
                                                return current && current < moment(customDate, "DD-MM-YYYY");
                                            }} style={{ width: '100%' }} format={'DD-MM-YYYY'} />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter  many days do you have!",
                                            },
                                        ]} label='How many days do you have?' name={'manyDays'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item rules={[
                                            {
                                                required: true,
                                                message: "Please Enter  many days do you have!",
                                            },
                                        ]} label='How many people in your group?' name={'manyPeople'}>
                                            <Input />
                                        </Form.Item>

                                    </Card>
                                    <Card bodyStyle={{ backgroundColor: '#EFF1F4' }} headStyle={{ textAlign: 'center', border: '2px solid #003D67' }} title={<strong>
                                        YOUR INTERESTS
                                    </strong>}>
                                        <Form.Item label='Prefered Transportation' name={'Transportation'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label='Prefered Destination' name={'Destination'}>
                                            <Input.TextArea style={{ minHeight: 100 }} />
                                        </Form.Item>
                                        <Form.Item label='Prefered Activities' name={'Activities'}>
                                            <Input.TextArea style={{ minHeight: 100 }} />
                                        </Form.Item>
                                        <Form.Item label='Your special interests/ request?' name={'special'}>
                                            <Input.TextArea style={{ minHeight: 100 }} />
                                        </Form.Item>
                                        <Form.Item label='Range of hotel you want?' name={'HotelStar'}>
                                            <Select >
                                                <Option value='1 Star'>
                                                    1 Star
                                                </Option>
                                                <Option value='2 Star'>
                                                    2 Star
                                                </Option>
                                                <Option value='3 Star'>
                                                    3 Star
                                                </Option>
                                                <Option value='4 Star'>
                                                    4 Star
                                                </Option>
                                                <Option value='5 Star'>
                                                    5 Star
                                                </Option>
                                                <Option value='Resort'>
                                                    Resort
                                                </Option>
                                                <Option value='Homestay'>
                                                    Homestay
                                                </Option>
                                                <Option value='Hostel'>
                                                    Hostel
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='Would you like us to include any meals in your intinerary?' name={'Foods'}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={'fileAttach'} label='Browse files' >
                                            <Upload
                                                action="https://dgss.vn:8081/upload"
                                                listType="picture-card"
                                                fileList={fileList}
                                                onChange={changeUpload}
                                            >
                                                <div>Choose file Upload</div>
                                            </Upload>
                                        </Form.Item>
                                        <Col style={{ textAlign: 'center' }} span={24}>
                                            <Button form="form" size="large" style={{ borderRadius: 5, backgroundColor: '#F89810' }} type="primary" htmlType="submit">
                                                <strong>SUBMIT</strong>
                                            </Button>

                                        </Col>
                                    </Card>
                                </Form>
                            </Col>

                            <Col style={{ textAlign: 'center' }} span={24}>
                                <i style={{ fontSize: 18 }}>
                                    You can refer to our list of destinations, activities, transportation and services <a target="_blank" onClick={(e) => clickTopActivity(e)} style={{ color: 'blue' }}>here</a>
                                </i>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default CustomTour