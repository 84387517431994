import { Col, Row } from 'antd';
import './menu.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Header from '../MenuNew/Header';
const MenuFooter = (dataState, language) => {
    const statusBook = sessionStorage.getItem('statusbook');
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [dataService, setDataService] = useState([]);
    //header
    const token = localStorage.getItem('token');
    const header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const LoadDataService = () => {
        axios.post('https://dgss.vn:7001/api/ServiceSubMenu/GetServiceSubMenuAll', '', { headers: header }).then(res => {
            if (res.status === 200) {

                axios.post('https://dgss.vn:7001/api/ServiceMenu/GetServiceMenuAll', '', { headers: header }).then(resMenu => {
                    const customData = [
                        {
                            "id": 0,
                            "title": language === 'en' ? 'Other Services' : 'Các dịch vụ khác',
                            "submenu": res.data.sort((a, b) => a.OrderView > b.OrderView ? 1 : -1).map(i => ({
                                "id": i.ID,
                                "title": language === 'en' ? String(i.Title).split(';')[0] : String(i.Title).split(';')[1],
                                "menu": resMenu.data.sort((a, b) => a.OrderView > b.OrderView ? 1 : -1).filter(o => o.IDServiceSubMenu === i.ID).map(o => ({
                                    "id": o.ID,
                                    "title": language === 'en' ? String(o.Title).split(';')[0] : String(o.Title).split(';')[1],
                                }))
                            }))
                        }
                    ]
                    setDataService(customData);
                })
            }
        })
    }

    const [dataCustomMenu, setDataCustomMenu] = useState([]);
    const LoadDataCategory = () => {
        axios.post('https://dgss.vn:7001/api/CategoryTour/GetCategoryTourAll', '', {
            headers: header
        }).then(res => {
            // -------------------------------------------------------
            axios.post('https://dgss.vn:7001/api/SubMenu/GetSubMenuAll', '', {
                headers: header
            }).then(resub => {
                axios.post('https://dgss.vn:7001/api/Menu/GetMenuAll', '', { headers: header }).then(resTour => {
                    const customData = res.data.sort((a, b) => a.OrderView > b.OrderView ? 1 : -1).map(r => ({
                        "id": r.ID,
                        "title": language === 'en' ? String(r.Title).split(';')[1] : String(r.Title).split(';')[0],
                        "submenu": resub.data.sort((a, b) => a.OrderView > b.OrderView ? 1 : -1).filter(d => d.IDCategory === r.ID).map(i => ({
                            "id": i.ID,
                            "title": language === 'en' ? String(i.Title).split(';')[1] : String(i.Title).split(';')[0],
                            "menu": resTour.data.sort((a, b) => a.OrderView > b.OrderView ? 1 : -1).filter(o => o.IDSubMenu === i.ID).map(o => ({
                                "id": o.ID,
                                "title": language === 'en' ? String(o.Title).split(';')[1] : String(o.Title).split(';')[0],
                            }))
                        }))
                    }))
                    setDataCustomMenu(customData);

                })
            })
        })
    }
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });
    useEffect(() => {
        LoadDataCategory();
        LoadDataService();
    }, [language]);
    const isSticky = (e) => {
        const header = document.querySelector(".header-section");
        const scrollTop = window.scrollY;
        if (scrollTop >= 10) {
            //setToggle(true);
            header.classList.add("is-sticky");
        }
        else {
            //setToggle(false);
            header.classList.remove("is-sticky")
            //header.classList.add("is-sticky");
        }
    };


    return (
        <Row className="header-section ">
            <Col xl={24} xs={0} span={24}>
                <div style={{ backgroundColor: '#003D67' }}>
                    <Row>
                        <Col span={24}>
                            {Header(dataCustomMenu, language, isLoggedIn, statusBook !== null && statusBook !== false ? 1 : 0, dataService)}
                        </Col>
                    </Row>
                </div>
            </Col>

            <Col xs={24} xl={0} span={24}>
                <div style={{ backgroundColor: '#003D67' }}>
                    <Row>
                        <Col span={24}>

                            {Header(dataCustomMenu, language, isLoggedIn, statusBook !== null && statusBook !== false ? 1 : 0, dataService)}
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

    )
}
export default MenuFooter;