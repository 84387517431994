import { Button, Card, Col, Image, Row, Steps, Avatar, Carousel, Divider, List } from "antd";
import {
    MoneyCollectOutlined, MonitorOutlined, DribbbleOutlined, CheckCircleOutlined, PhoneOutlined, TagOutlined, ContactsOutlined, PlusSquareOutlined, UserOutlined, PhoneFilled, MailFilled, FileExcelOutlined, DeleteOutlined, TeamOutlined, FormOutlined, SkinOutlined, CheckOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import SlideImage from "../SlideImage/slideimage";
import Translate from "../../common/Lang/translate";
import { useEffect, useState } from "react";

import axios from "axios";
import ReactPlayer from 'react-player/youtube'
import moment from "moment";
import ReactStars from "react-stars";
const ServiceDetail = (Language) => {
    const history = useHistory();
   
    const dataState = history.location.state;
    const [dataServiceDetail, setDataServiceDetail] = useState([]);
    const [dataTour, setDataTour] = useState([]);
    const LoadDataServiceDetail = () => {
        axios.post('https://dgss.vn:7001/api/ServiceDetail/GetServiceDetailAll', '').then(res => {
            setDataServiceDetail(res.data.filter(i => i.ID === dataState));
        })
    }
    const LoadDataTour = () => {
        axios.post('https://dgss.vn:7001/api/Tour/GetTourAll', '').then(res => {
            setDataTour(res.data.sort((a, b) => a.OrderView > b.OrderView ? 1 : -1))
        })
    }
    useEffect(() => {
        LoadDataServiceDetail();
        LoadDataTour();
    }, [dataState])

    const dataImage = dataServiceDetail.map(r => (
        {
            slogan: r.Slogan,
            title: r.Title,
            src: r.ImageBanner
        }
    ))
    const clickPopular = (id, title) => {
        history.push(`/book/${title}`, id);
    }

    const datapopular = (ID) => {
        return (
            dataTour.slice(0, 4).map(i => (
                {
                    toptitle: i.TopTitle,
                    titlesale: i.TitleSale,
                    titletour: i.TitleTour,
                    title: Language === 'vi' ? String(i.Title).split(';')[1] : String(i.Title).split(';')[0],
                    availability: i.Availability,
                    duration: i.Duration,
                    departsfrom: i.DepartsFrom,
                    price: `${i.Price} USD`,
                    src: String(i.Image).split(';')[0],
                    rating: 5,
                    reviewer: 20,
                    idCategory: i.IDCategory,
                    id: i.ID
                }
            ))
        )
    }

    return <div style={{ backgroundColor: 'white' }}>
        {SlideImage(dataImage)}
        <div>
            <Row>
                <Col span={24}>
                    <br />
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={4}>

                        </Col>
                        <Col xs={10} xl={16} span={16}>
                            {
                                dataServiceDetail.map(r => (
                                    <ReactPlayer width={900} height={460} url={r.VideoSrc} />
                                ))
                            }
                        </Col>

                    </Row>
                </Col>
                <Col span={24}>
                    <Card bodyStyle={{ backgroundColor: '#EFF1F4' }}>
                        <Row>
                            <Col style={{ textAlign: 'center' }} span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>{String('Related sightseeing tours').toUpperCase()}</b>
                            </Col>
                            <Col span={24}>
                                {dataServiceDetail.map(r => (
                                    <List
                                        grid={{ gutter: 16, column: 4, xs: 1 }}
                                        dataSource={datapopular(r.IDCategory)}
                                        renderItem={(item) => (
                                            <List.Item onClick={(e) => clickPopular(item.id, item.title)}>
                                                <Card bodyStyle={{ minHeight: 300, position: 'relative' }} headStyle={{ backgroundImage: `url(${item.src})`, backgroundSize: "cover", padding: 0 }} title={<div style={{ minWidth: 312, height: 220 }}>
                                                    <br />
                                                    <div style={{ borderTopRightRadius: 20, height: 35, borderBottomRightRadius: 20, color: 'white', width: 230, backgroundColor: '#367AA7', position: 'relative' }}>
                                                        <b style={{ color: 'white', marginLeft: 5, position: 'absolute', top: 4 }}>{String(item.toptitle).toLocaleUpperCase()}</b>
                                                    </div>

                                                    <div style={{ width: 150, height: 35, borderTopRightRadius: 20, borderBottomRightRadius: 20, color: 'white', backgroundColor: '#F89810', marginTop: 10, position: 'relative' }}>
                                                        <b style={{ color: 'white', marginLeft: 5, top: 4, position: 'absolute' }}>{String(item.titlesale).toLocaleUpperCase()}</b>
                                                    </div>
                                                </div>} hoverable>
                                                    <div style={{ backgroundColor: 'white', borderRadius: 7, marginTop: -42, height: 32, textAlign: 'center', border: '1px solid gray' }}>
                                                        <h6 style={{ color: 'rgb(66,130,172)', marginTop: 5 }}>{String(item.titletour).toLocaleUpperCase()}</h6>
                                                    </div>
                                                    <div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <b>{item.title}</b>
                                                        </div>
                                                        <Row>
                                                            <Col span={10}>
                                                                <ReactStars
                                                                    count={5}
                                                                    edit={false}
                                                                    value={item.rating}
                                                                    size={24}
                                                                    color2={'#ffd700'} />
                                                            </Col>
                                                            <Col style={{ marginTop: 10, color: 'gray' }} span={8}>
                                                                {item.reviewer} REVIEWS
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[8, 8]}>
                                                            <Col span={7}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <span style={{ fontSize: 12, color: '#367AA7' }}> AVAILABILITY</span>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <b style={{ fontSize: 12, color: '#367AA7' }}>{item.availability}</b>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Divider style={{ height: 70, backgroundColor: '#367AA7' }} type="vertical" />
                                                            <Col span={7}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <span style={{ fontSize: 12, color: '#367AA7' }}>DURATION</span>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <b style={{ fontSize: 12, color: '#367AA7' }}>{item.duration}</b>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Divider style={{ height: 70, backgroundColor: '#367AA7' }} type="vertical" />

                                                            <Col span={6}>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <span style={{ fontSize: 12, color: '#367AA7' }}>DEPARTS FROM</span>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <b style={{ fontSize: 12, color: '#367AA7' }}>{item.departsfrom}</b>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16} style={{ position: 'absolute', bottom: 20 }}>
                                                            <Col span={12}>
                                                                <Button style={{ backgroundColor: '#003D67', color: 'white', borderRadius: 7, width: 135, height: 35 }}><h6 style={{ color: 'white', marginTop: 2 }}>{Translate(Language, 'View_tour')}</h6></Button>
                                                            </Col>
                                                            <Col span={12}>
                                                                <h4> &nbsp; {item.price}</h4>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Card>
                                            </List.Item>
                                        )} />
                                ))}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
        <Row>
            <Col span={24}>

            </Col>
            <Col span={4}>

            </Col>
            <Col xs={24} xl={16} span={16}>
                <Row>
                    <Col span={24}>
                        <br />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 8]}>

                            <Col span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>{String('Recoment Tour').toUpperCase()}</b>
                            </Col>
                            <Col style={{ fontSize: 16 }} span={24}>
                                <div dangerouslySetInnerHTML={{ __html: dataServiceDetail.map(r => r.RecomentTour) }}>

                                </div>
                            </Col>

                            <Col span={24}>
                                <Image.PreviewGroup>
                                    <Carousel infinite={false} autoplay>
                                        {
                                            dataServiceDetail.map(r => (
                                                <div>
                                                    <Image style={{ width: 900, height: 460 }} src={r.ImageRecomentTour} />
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                </Image.PreviewGroup>
                            </Col>

                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>{String('How to get').toUpperCase()}</b>
                            </Col>
                            <Col style={{ fontSize: 16 }} span={24}>
                                <div dangerouslySetInnerHTML={{ __html: dataServiceDetail.map(r => r.HOWTOGET) }}>

                                </div>
                            </Col>
                            <Col style={{ fontSize: 16, textAlign: 'center' }} span={24}>
                                {dataServiceDetail.map(r => (
                                    <Image src={r.ImageHowToGet} />
                                ))}
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>{String('why').toUpperCase()}</b>
                            </Col>
                            <Col style={{ fontSize: 16 }} span={24}>
                                <div dangerouslySetInnerHTML={{ __html: dataServiceDetail.map(r => r.WHY) }}>

                                </div>
                            </Col>
                            <Col style={{ fontSize: 16, textAlign: 'center' }} span={24}>
                                {dataServiceDetail.map(r => (
                                    <Image src={r.ImageWhy} />
                                ))}
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={24}>
                                <b style={{ fontSize: 22, color: '#367AA7' }}>{String('HOW IT FORMED').toUpperCase()}</b>
                            </Col>
                            <Col style={{ fontSize: 16 }} span={24}>
                                <div dangerouslySetInnerHTML={{ __html: dataServiceDetail.map(r => r.HOWITFORMED) }}>

                                </div>
                            </Col>
                            <Col style={{ fontSize: 16, textAlign: 'center' }} span={24}>
                                {dataServiceDetail.map(r => (
                                    <Image src={r.ImageItformed} />
                                ))}
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}
export default ServiceDetail;
// service detail hiển thị theo sub menu service thì thêm IDSubmenu -> service detail