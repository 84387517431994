import { Form, Input, Row, Col, Button, Image, Carousel, Select, Card } from "antd"
import axios from "axios";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import swal from "sweetalert";
import moment from "moment";
import 'moment/locale/vi';
import locale from 'antd/es/date-picker/locale/vi_VN';
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import countryMap from '../../common/countries/countries';
import Translate from "../../common/Lang/translate";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

import {
    ChatItem,
    MessageBox,
    SystemMessage,
    MessageList,
    ChatList,
    Popup,
    SideBar,
    Navbar,
    Dropdown,
    Avatar
} from 'react-chat-elements'
import "./style.css";
import "react-chat-elements/dist/main.css";
const { Option } = Select;
const ChatRoom = (state) => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [roomChatCurrent, setRoomChatCurrent] = useState(0);
    const [currentMessage, setCurrentMessage] = useState('')
    const [dataMessageList, setDataMessageList] = useState([]);
    const [currenRoom, setCurrenRoom] = useState('');
    const [dataRoom, setDataRoom] = useState([]);
    const tokenChat = localStorage.getItem('tokenChat');
    // const [dataUser, setDataUser] = useState([]);
    // console.log('tokenChat:', tokenChat)
    const header = {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${tokenChat}`,
    };
    useEffect(() => {
        if (isLoggedIn) {
            GetAllRoom();
            if (currenRoom !== null || currenRoom !== undefined) {
                LoadDataMessage(currenRoom)
            }
        }

    }, [state])
    const LoadDataMessage = (idRoom) => {
        setCurrenRoom(idRoom)
        axios.get(`https://dgss.vn:5001/room/${idRoom}`, {
            headers: header
        }).then(res => {
            const conversation = res.data.conversation;
            let customDataMes = conversation.map(function (r, index) {
                return {
                    id: r._id,
                    position: r.postedByUser.lastName === 'support' ? "right" : 'left',
                    type: "text",
                    text: r.message.messageText,
                    date: r.createdAt,
                    title: r.postedByUser.lastName,
                    idUser: r.roomInfo
                }
            });
            setDataMessageList(customDataMes)
        })
    }
    const GetAllRoom = () => {
        axios.get('https://dgss.vn:5001/room', {
            headers: header
        }).then(res => {
            const conversation = res.data.conversation;

            setDataRoom(conversation);

        })
    }
    // const GetAllUsers = () => {
    //     axios.get('https://dgss.vn:7001:5001/users', {
    //         headers: header
    //     }).then(res => {
    //         setDataUser(res.data.users)
    //     })
    // }
    // tokenChat support = 9f343e17474a4d8db259902c2034d7d3
    const renameCustomer = (array) => {
        var arr = [];
        array.map(i => {
            i.filter(d => d._id !== '9f343e17474a4d8db259902c2034d7d3').map(j => {
                arr.push(j)
            });
        });
        return arr[0].lastName
    }
    const readMes = (array) => {
        var arrReadUser = [];
        array.map(i => {
            i.readByUser.map(j => {
                arrReadUser.push(j)
            });
        });
        const dateRead = array.map(r => r.readAt);

        if (moment(arrReadUser[0].updatedAt).format('DD/MM/YYYY') >= moment(dateRead[0]).format('DD/MM/YYYY')) {
            return 0
        }
        else {
            return 1
        }

    }
    const dataRoomList = dataRoom.map(function (r, index) {

        return {
            avatar: 'https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg',
            title: renameCustomer(r.roomInfo),
            subtitle: r.message.messageText,
            date: r.createdAt,
            unread: readMes(r.readByRecipients),
            className: readMes(r.readByRecipients) === 0 ? "" : "hasUnread",
            chatRoomId: r.chatRoomId,
            roomInfo: r.roomInfo
        }
    });

    const stateChat = localStorage.setItem('stateChat', false)
    const sendMessage = async () => {
        if (currentMessage !== "" && roomChatCurrent !== 0) {
            axios.post(`https://dgss.vn:5001/room/${roomChatCurrent}/message`, {
                "messageText": currentMessage
            }, {
                headers: header
            }).then(res => {
                LoadDataMessage(roomChatCurrent);
            })
            setCurrentMessage("");
            localStorage.setItem('stateChat', !stateChat);
        }
    };
    const DeleteRoom = (idRoom, idUser) => {
        swal({
            title: "Bạn có chắc muốn xóa không?",
            text: "Sau khi xóa, bạn sẽ không thể khôi phục dữ liệu này!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`https://dgss.vn:5001/delete/room/${idRoom}`, '', {
                    headers: header
                }).then(res => {
                    if (res.status === 200) {
                        swal('Delete success')
                    }
                    GetAllRoom();
                    LoadDataMessage(idRoom);
                    setShowRoomXS(false)
                })
                axios.delete(`https://dgss.vn:5001/users/${idUser}`, '', {
                    headers: header
                })
            } else {
                swal({
                    text: "Cancel success",
                    icon: "success",
                });
            }
        });
    }
    const [idUser, setIDUser] = useState(0)
    const clickCustomer = (val) => {
        setShowRoomXS(true)
        val.roomInfo.map(i => {
            i.filter(d => d._id !== '9f343e17474a4d8db259902c2034d7d3').map(j => {
                setIDUser(j._id)
            });
        });
        LoadDataMessage(val.chatRoomId);
        setRoomChatCurrent(val.chatRoomId);
        axios.put(`https://dgss.vn:5001/room/${val.chatRoomId}/mark-read`, {
            "success": true,
            "data": {
                "n": 0,
                "nModified": 0,
                "ok": 1
            }
        }, { headers: header }).then(res => {
            GetAllRoom()
        })
    }
    const changeContentMessage = (e) => {
        setCurrentMessage(e.target.value)
    }
    const [showRoomXS, setShowRoomXS] = useState(false)
    if (isLoggedIn === false) {
        return <Redirect to={"/"} />;
    }

    return (
        <div>
            <Row>
                <Col span={2}>

                </Col>
                <Col span={20}>
                    <Row>
                        <Col xl={24} xs={0} style={{ textAlign: 'center' }} span={24}>
                            <div>
                                <Carousel autoplay>
                                    <div>
                                        <Image width={'100%'} height={500} preview={false} src={'https://st.depositphotos.com/1368414/3527/i/450/depositphotos_35279173-stock-photo-chat-room.jpg'} />
                                    </div>
                                </Carousel>
                            </div>
                        </Col>
                        <Col xs={24} xl={0} style={{ marginTop: 50 }} span={24}>
                        </Col>
                        <Col xl={24} xs={0} span={24}>
                            <Row>
                                <Col xl={8} xs={24} span={8}>
                                    <Card style={{ minHeight: 509 }} title='Customer'>
                                        <div style={{ overflowY: 'auto' }}>
                                            <ChatList
                                                className="chat-list"
                                                dataSource={dataRoomList}
                                                onClick={(e => clickCustomer(e))}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={16} xs={16} span={16}>
                                    <Card title={
                                        <Row>
                                            <Col xs={10} xl={20} span={20}>
                                                Room
                                            </Col>
                                            <Col xs={14} xl={4} span={4}>
                                                {roomChatCurrent !== 0 ? <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={(e) => DeleteRoom(roomChatCurrent, idUser)}>Delete Room</Button> : null}
                                            </Col>
                                        </Row>
                                    }>
                                        <div style={{ display: "flex", flexDirection: "column", height: 400, overflowY: 'auto' }}>
                                            <MessageList
                                                className="message-list"
                                                lockable={true}
                                                toBottomHeight={"10%"}
                                                dataSource={dataMessageList}
                                            />
                                            <SystemMessage text={"End of conversation"} />
                                            <div style={{ marginTop: "auto" }}>
                                                <Input
                                                    hidden={roomChatCurrent === 0 ? true : false}
                                                    onChange={changeContentMessage}
                                                    placeholder="Type here..."
                                                    value={currentMessage}
                                                    onKeyPress={(event) => {
                                                        event.key === "Enter" && sendMessage();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={0} xs={24} span={24}>
                            <Row>
                                <Col hidden={showRoomXS} xl={8} xs={24} span={8}>
                                    <Card style={{ height: 507 }} title='Customer'>
                                        <div style={{ overflowY: 'auto' }}>
                                            <ChatList
                                                className="chat-list"
                                                dataSource={dataRoomList}
                                                onClick={(e => clickCustomer(e))}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                                <Col hidden={showRoomXS === false ? true : false} xl={0} xs={24} span={16}>
                                    <Card title={
                                        <Row>
                                            <Col xs={16} xl={0} span={20}>
                                                <Button onClick={(e) => setShowRoomXS(false)} type="text">{'< Room'}</Button>
                                            </Col>
                                            <Col style={{ paddingRight: 0 }} xs={8} xl={0} span={4}>
                                                {roomChatCurrent !== 0 ? <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={(e) => DeleteRoom(roomChatCurrent, idUser)}>Delete Room</Button> : null}
                                            </Col>
                                        </Row>
                                    }>
                                        <div style={{ display: "flex", flexDirection: "column", height: 400, overflowY: 'auto' }}>
                                            <MessageList
                                                className="message-list"
                                                lockable={true}
                                                toBottomHeight={"10%"}
                                                dataSource={dataMessageList}
                                            />
                                            <SystemMessage text={"End of conversation"} />
                                            <div style={{ marginTop: "auto" }}>
                                                <Input
                                                    hidden={roomChatCurrent === 0 ? true : false}
                                                    onChange={changeContentMessage}
                                                    placeholder="Type here..."
                                                    value={currentMessage}
                                                    onKeyPress={(event) => {
                                                        event.key === "Enter" && sendMessage();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </div >
    )
}
export default ChatRoom