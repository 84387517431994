import moment from "moment";
import numeral from "numeral";

const formatShortDate = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

const formatLongDate = (value) => {
  return moment(value).format("DD/MM/YYYY HH:mm");
};

const formatChatDate = (value) => {
  return moment(value).format("HH:mm DD-MM");
};

const formatShopeeChatDate = (value) => {
  return moment(value).add(7, "hours").format("HH:mm DD-MM");
};

const formatCurrency = (value) => {
  return numeral(value).format("#,###");
};

const formatDecimal = (value) => {
  return numeral(value).format("#,###.##");
};

const getTextFileType = (link) => {
  var ext = link.substr(1 + link.lastIndexOf("."));
  var vd = ["mp4", "webm", "avi", "flv", "wmv", "mov"];
  var image = ["jpg", "png", "jpeg", "gif", "svg", "tiff", "pjp", "jfif", "bmp", "ico", "xbm", "dib"];
  if (vd.includes(ext)) return "video";
  if (image.includes(ext)) return "image";
};

export { formatShortDate, formatLongDate, formatCurrency, formatDecimal, formatChatDate, getTextFileType, formatShopeeChatDate };
